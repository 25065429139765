import React, { useContext, useEffect, useRef, useState } from 'react';
import './notification-banner.scss';

import { useAnalytics } from '../../../hooks/use-analytics';
import Warning from '../../../assets/warning-icon.svg';
import InfoIcon from '../../../assets/info-icon-filled.svg';
import InfoIconLincoln from '../../../assets/info-icon-filled-lincoln.svg';
import WarningLincoln from '../../../assets/warning-icon-notification-lincoln.svg';
import WarningIconRed from '../../../assets/warning-icon-red.svg';
import { NotificationBannerProps } from './hooks/use-notification-banner';
import ServerContext from '../../../contexts/serverContext';
import { BRAND, COLORS } from '../../../constants';

const NOTIFICATION_TYPE = {
    DEFAULT: 'default',
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
};

function NotificationBanner(props: NotificationBannerProps) {
    const {
        hide,
        type,
        bannerDismissible,
        dismissAriaLabel,
        bannerText,
        showChevron,
        shortCode,
        eventName,
        contentId,
    } = props;
    const [isVisible, setIsVisible] = useState(true);
    const [fireEvents] = useAnalytics();
    const ref = useRef<HTMLDivElement | null>(null);
    const isLincoln =
        useContext(ServerContext).brand === BRAND.lincoln.LONG_NAME;
    const chevron = '<span class="chevron-icon"></span>';
    const chevronLincoln = `<img class="forward-icon" src=${
        type === NOTIFICATION_TYPE.WARNING
            ? 'icons/forward.svg'
            : 'icons/forward-white.svg'
    } alt=""/>`;
    const chevronContent = isLincoln ? chevronLincoln : chevron;
    const bannerContent = bannerText + (showChevron ? chevronContent : '');
    const iconMap = {
        [NOTIFICATION_TYPE.ERROR]: WarningIconRed,
        [NOTIFICATION_TYPE.WARNING]: isLincoln ? WarningLincoln : Warning,
        [NOTIFICATION_TYPE.DEFAULT]: isLincoln ? InfoIconLincoln : InfoIcon,
    };
    const colorMap = {
        [NOTIFICATION_TYPE.WARNING]: COLORS.WARNING,
        [NOTIFICATION_TYPE.ERROR]: COLORS.ERROR,
    };
    const imageSource = 'icons/f-close.svg';
    const lincolnImageSource =
        type === NOTIFICATION_TYPE.WARNING
            ? 'icons/close-notification-banner.svg'
            : 'icons/close-white.svg';

    const handleClick = () => {
        setIsVisible(false);
    };

    const fireAnalytics = () => {
        if (eventName && shortCode) {
            fireEvents(eventName, undefined, shortCode, false);
        }
    };

    useEffect(() => {
        const anchor = ref.current?.getElementsByTagName('a')[0];
        anchor?.addEventListener('click', fireAnalytics);

        return () => {
            anchor?.removeEventListener('click', fireAnalytics);
        };
    }, []);

    return !hide ? (
        <div className="parent">
            {isVisible && (
                <div className="notification-banner-container">
                    <div
                        className={`notification-banner ${type}`}
                        role="alert"
                        aria-live="polite"
                    >
                        <div className="notification-wrapper">
                            <img
                                className={`${type}`}
                                src={iconMap[type] || ''}
                                alt=""
                            />
                            <div
                                className="notification-content"
                                ref={ref}
                                id={contentId || type}
                                dangerouslySetInnerHTML={{
                                    __html: String(bannerContent),
                                }}
                            />
                        </div>
                        {bannerDismissible && (
                            <button
                                className="closeButton"
                                onClick={handleClick}
                                aria-label={dismissAriaLabel}
                            >
                                <img
                                    className="close-icon"
                                    src={
                                        isLincoln
                                            ? lincolnImageSource
                                            : imageSource
                                    }
                                    alt=""
                                />
                            </button>
                        )}
                    </div>
                    <div
                        className="color-bar"
                        style={{
                            backgroundColor: `${colorMap[type] ||
                                COLORS.DEFAULT}`,
                        }}
                    ></div>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
}

export default NotificationBanner;
