import ServiceHandler from '../../../services/service-handler';

export interface ParsedDateTimeFormat {
    date: string;
    time: string;
}

export interface FormattedServiceDate {
    currentRegionDate: string;
}

export class DateTimeUtil {
    public static dateOptions: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    };

    public static timeOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    };

    public static intlDateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    public static intlFullYearDateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    public static timeAndMinuteOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
    };

    public static standardDateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    public static fullDateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    public parseDateTime(
        timeStamp: string,
        isFullYear?: boolean
    ): ParsedDateTimeFormat {
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;

        if (!timeStamp) return { date: '', time: '' };
        const parsedDateTime = /^\d+$/.test(timeStamp) ? +timeStamp : timeStamp;
        const date = new Date(parsedDateTime).toLocaleDateString(
            currentLanguageRegionCode,
            isFullYear
                ? DateTimeUtil.intlFullYearDateOptions
                : DateTimeUtil.dateOptions
        );

        const time = new Date(parsedDateTime).toLocaleTimeString(
            currentLanguageRegionCode,
            DateTimeUtil.timeOptions
        );
        return {
            date: date,
            time: time,
        };
    }

    public formatDate(
        serviceDate: string,
        dateOptions = DateTimeUtil.intlDateOptions
    ): FormattedServiceDate | null {
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        if (!serviceDate) return null;
        const currentRegionDate = new Date(serviceDate).toLocaleDateString(
            currentLanguageRegionCode,
            dateOptions
        );
        return {
            currentRegionDate,
        };
    }

    public convertISOToDate(date: string): string {
        let dateArr: any = date?.split('T')[0];
        dateArr = dateArr?.split('-');
        if (dateArr) {
            const convertedDate =
                dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
            return convertedDate;
        }
        return date;
    }

    public getCurrentDateInISO(): string {
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    public formatDateByRegion(serviceDate: string, withDay = true): string {
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        let dateOptions = {};
        if (withDay === false) {
            dateOptions = { year: 'numeric', month: 'short' };
        } else {
            dateOptions = { year: 'numeric', month: 'short', day: '2-digit' };
        }
        return new Date(serviceDate).toLocaleDateString(
            currentLanguageRegionCode,
            dateOptions
        );
    }

    public getLatestDate = (...dateStrArray: string[]): string => {
        if (!dateStrArray || dateStrArray?.length == 0) {
            return '';
        }
        if (dateStrArray?.length == 1) {
            return dateStrArray[0];
        }
        let latestDate = '';
        dateStrArray.forEach(thisDate => {
            if (thisDate && thisDate?.trim() != '') {
                if (latestDate) {
                    if (
                        new Date(thisDate).getTime() >
                        new Date(latestDate).getTime()
                    ) {
                        latestDate = thisDate;
                    }
                } else {
                    latestDate = thisDate;
                }
            }
        });
        return latestDate;
    };

    public formatUTCDateByRegion(date: string): string {
        if (!date || date?.trim() == '') {
            return '';
        }
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        const dateObj = new Date(date);
        let dateOptions = {};
        if (dateObj.getHours() === 0 && dateObj.getMinutes() === 0) {
            dateOptions = DateTimeUtil.standardDateOptions;
        } else {
            dateOptions = DateTimeUtil.fullDateOptions;
        }
        return dateObj.toLocaleDateString(
            currentLanguageRegionCode,
            dateOptions
        );
    }

    public convertMilitaryToMeridiemTime(time: string) {
        let hours = Number(time.split(':')[0]);
        let minutes: string | number = Number(time.split(':')[1]);
        const meridiem = hours >= 12 ? 'PM' : 'AM';
        if (isNaN(hours) || isNaN(minutes) || hours > 23 || minutes > 59) {
            return 'Time Error!';
        }
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + meridiem;
    }

    public parsePickupDeliveryDateTime(timeStamp: string) {
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        const date: Date = new Date(timeStamp);
        const month = date.toLocaleDateString(currentLanguageRegionCode, {
            month: 'long',
        });
        const day = date.toLocaleDateString(currentLanguageRegionCode, {
            day: '2-digit',
        });
        const time = date.toLocaleTimeString('en-us', {
            hour: '2-digit',
            minute: '2-digit',
        });
        return {
            year: '' + date.getFullYear(),
            time: time,
            month: month,
            day: day,
        };
    }

    public static toLocaleDateString(
        dateOptions: Intl.DateTimeFormatOptions,
        date?: Date
    ): string {
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        if (date) {
            return date.toLocaleDateString(
                currentLanguageRegionCode,
                dateOptions
            );
        } else {
            return '';
        }
    }

    public static toLocaleTimeString(
        dateOptions: Intl.DateTimeFormatOptions,
        date?: Date
    ): string {
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        if (date) {
            return date.toLocaleTimeString(
                currentLanguageRegionCode,
                dateOptions
            );
        } else {
            return '';
        }
    }

    public static formatPickupTime = (date?: Date, time?: Date) => {
        if (date && time) {
            const timeDateSplitIndex = date.toISOString().indexOf('T');
            const extractedDate = date
                .toISOString()
                .substring(0, timeDateSplitIndex);
            time?.setMilliseconds(0); // must for pickup time, otherwise validation fails with millisecond difference
            const extractedTime = time
                ?.toISOString()
                .substring(timeDateSplitIndex);
            return `${extractedDate}${extractedTime}`;
        }
        return '';
    };
}

export default DateTimeUtil;
