import React, { useState, useEffect } from 'react';
import {
    useHttp,
    useStepProgressBar,
    useVehicleStep,
    useOSBStep,
    useDealerStep,
    useServiceStep,
    useViewport,
} from '../../../../../../hooks/owners-osb';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import ServiceHandler from '../../../../../../services/service-handler';
import OsbUtilService from '../../../../../../services/osb-service/osb-util-service/osb-util-service';
import './light-specific-service-lincoln.scss';
import { OsbComments } from '../../../comments/comments';
import { ServiceStep } from '../../../../../../models/osb-model/osb-service-step';
import {
    DealerServices,
    ServiceInfo,
    ServiceData,
} from '../../../../../../models/osb-model/osb-dealerservice-info';
import {
    SERVICE_FLOW,
    STEP_PROGRESS_BAR_KEYS,
    SERVICES_STEP_KEYS,
    OSB_SERVICE_TYPE,
    DATE_TIME_STEP,
    LIGHT_JOURNEY_ANALYTICS,
} from '../../../osb-constant';

import {
    getObjectFromAEMJson,
    isListSelectionChanged,
    isServiceFoundInSelectedList,
    buildNavigationUrl,
    setServiceUniqueId,
    sortOrderComparer,
    formatRegVinNumber,
    isValidVin,
    getRecallServiceDescription,
    getGoMainHeaderHeight,
} from '../../../osb-utils';
import { PrimaryButton } from '../../../../../common';
import { VehicleFinder } from '../../../vehicle-step/light-journey/light-vehicle-finder';
import { VehicleRecallInfo } from '../../../../../../models/osb-model/vehicle-recall-info';
import { OSBSecondaryButton } from '../../../common/osb-secondary-button/osb-secondary-button';
import { OsbServiceRenderer } from '../../../common/osb-service-renderer/osb-service-renderer';
import {
    triggerBookServiceGlobalCTAOnClickAnalytics,
    triggerSpecificServiceLoadAnalytics,
} from '../../../analytics/osb-analytics';
import {
    OsbPathBookingRetrieveSummaryStep,
    OsbPathCalenderStep,
    OsbPathDeliveryStep,
    OsbPathServiceLocationStep,
} from '../../../osb-controller';
import {
    AcceptedFileInfo,
    OSBFileUploader,
} from '../../../common/osb-file-uploader/osb-file-uploader';
import { OsbVhaAlertResponse } from '../../../../../../services/vha-service/vha-service.interface';
import { PrognosticsDataV2 } from '../../../../../../models/vehicle-prognostics';
import { OsbImageService } from '../../../../../../models/osb-model/osb-image-upload';
import { useHistory } from 'react-router-dom';
import { VehicleData } from '../../../../../../models/osb-model/osb-vehicle-step';
import { OsbContentResponse } from '../../../../../../models/osb-model/osb-content-details';
import serverSideService from '../../../../../../services/server-side-service/server-side-service';
import { useAllOSBState } from '../../../../../../hooks/owners-osb/use-all-osb-state';

interface Props {
    handleSpecificServiceBackClick: () => void;
    addDefaultService: (dealerServices: DealerServices) => boolean;
    isRetrieveFlow: boolean;
    isMobileServiceCardAuthored: boolean;
    osbServiceStep: ServiceStep;
    setOSBServiceStepPayload: (payload: {
        [key: string]:
            | string
            | ServiceData[]
            | DealerServices
            | AcceptedFileInfo[]
            | boolean
            | string[];
    }) => void;
    osbImageUploadStep: OsbImageService;
    setOSBImageServiceStepPayload: (payload: {
        [key: string]: string | AcceptedFileInfo[];
    }) => void;

    refreshBookingSummary: () => void;
    isServiceRefreshed: boolean;
    removeGAServiceIfSelected: (selectedServices: ServiceData[]) => void;
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}

export const LightSpecificService = (props: Props) => {
    const { osbServiceStep } = useServiceStep();
    const { osbVehicleStep } = useVehicleStep();
    const bookable = useAllOSBState();
    const {
        osbStep,
        setOSBStepPayload,
        invalidateAuthentication,
    } = useOSBStep();
    const [fireEvents] = useAnalytics();
    const { updateProgressBarStatus } = useStepProgressBar();
    const { dispatch } = useHttp();
    const history = useHistory();
    const [maxServiceRetryCount, setMaxServiceRetryCount] = useState<number>(0);
    const [
        servicesRetryIntervalInSeconds,
        setServicesRetryIntervalInSeconds,
    ] = useState<number>(0);
    const [initialSelectedServices, setInitialSelectedServices] = useState<
        ServiceData[]
    >();
    const [lightSpecificServiceJson, setLightSpecificServiceJson] = useState<
        any
    >();
    const [fordServiceDetails, setFordServiceDetails] = useState<
        ServiceInfo[]
    >();
    const [extraServiceDetails, setExtraServiceDetails] = useState<
        ServiceInfo[]
    >();
    const { osbDealerStep } = useDealerStep();
    const [recallDescription, setRecallDescription] = useState<string[]>([]);
    const [vhaDescription, setVhaDescription] = useState<string[]>([]);
    const [oilLifeDescription, setOilLifeDescription] = useState<string>('');
    const { isMobileView } = useViewport();

    const [isMandatoryVehicleSection, setIsMandatoryVehicleSection] = useState(
        false
    );
    const [shouldValidateVehicleData, setShouldValidateVehicleData] = useState(
        false
    );
    const [isRequiredComments, setIsRequiredComments] = useState(false);
    const [reviewBookingContent, setReviewBookingContent] = useState<
        OsbContentResponse
    >();
    useEffect(() => {
        // MSV flow - If no services selected , make comments section mandatory
        if (osbServiceStep.isMobileServiceSelected) {
            if (
                osbServiceStep.selectedServices.length > 0 ||
                osbServiceStep.comment.length > 0
            ) {
                setIsRequiredComments(false);
            } else {
                setIsRequiredComments(true);
            }
        }
    }, [osbServiceStep.selectedServices, osbServiceStep.comment]);

    const preSelectedExtraServices: ServiceData[] = [];

    const updateVehicleSectionMandatoryState = () => {
        setIsMandatoryVehicleSection(true);
    };

    const hasOutstandingRecallActions = () => {
        const vehicleRecallInfo: VehicleRecallInfo =
            osbVehicleStep.vehicleRecallService;
        if (
            !OsbUtilService.isEmptyObject(vehicleRecallInfo) &&
            vehicleRecallInfo.recalls &&
            vehicleRecallInfo.recalls.length > 0 &&
            (vehicleRecallInfo.recalls[0]?.description?.trim() ||
                vehicleRecallInfo.recalls[0]?.fsaNumber?.trim())
        ) {
            return true;
        }
        return false;
    };

    const hasOutstandingvhaActions = () => {
        const vehicleVHAInfo: OsbVhaAlertResponse | undefined =
            osbVehicleStep.VhaResponse;
        if (
            !OsbUtilService.isEmptyObject(vehicleVHAInfo) &&
            vehicleVHAInfo?.activeAlerts != null &&
            vehicleVHAInfo?.activeAlerts.length > 0 &&
            vehicleVHAInfo?.activeAlerts[0]?.headline !== ''
        ) {
            return true;
        }
        return false;
    };

    const VHAServiceDescription = () => {
        const vehicleVHAInfo: OsbVhaAlertResponse | undefined =
            osbVehicleStep.VhaResponse;

        const VHAData: string[] = [];
        let i = 0;
        if (vehicleVHAInfo?.activeAlerts !== undefined) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const vha of vehicleVHAInfo?.activeAlerts || []) {
                VHAData[i] = vha.headline;
                i++;
            }
            setVhaDescription(VHAData);
            props.setOSBServiceStepPayload({
                vhaServiceDescription: VHAData,
            });
        }
    };
    const recallServiceDescription = () => {
        const recallDescription = getRecallServiceDescription(
            osbVehicleStep.vehicleRecallService
        );
        setRecallDescription(recallDescription);
        props.setOSBServiceStepPayload({
            recallServiceDescription: recallDescription,
        });
    };

    const shouldShowOilLifeService = (
        vehicleOilLifeInfo: PrognosticsDataV2 | undefined
    ) => {
        return (
            vehicleOilLifeInfo?.featureType ===
                SERVICES_STEP_KEYS.OL_FEATURE_TYPE &&
            vehicleOilLifeInfo?.shouldShow &&
            typeof vehicleOilLifeInfo?.featureData?.iolm === 'number' &&
            vehicleOilLifeInfo?.featureData?.iolm < 10
        );
    };

    const showOilLifeServiceReminder = () => {
        const vehicleOilLifeInfo: PrognosticsDataV2 | undefined =
            osbVehicleStep.oilLifeData;

        return (
            !OsbUtilService.isEmptyObject(vehicleOilLifeInfo) &&
            shouldShowOilLifeService(vehicleOilLifeInfo)
        );
    };

    const oilLifeServiceDescription = () => {
        const vehicleOilLifeInfo: PrognosticsDataV2 | undefined =
            osbVehicleStep.oilLifeData;
        setOilLifeDescription(vehicleOilLifeInfo?.dtsMessage?.para1 ?? '');
        props.setOSBServiceStepPayload({
            oilLifeServiceReminderDescription:
                vehicleOilLifeInfo?.dtsMessage?.para1 ?? '',
        });
    };

    function getLightSpecificServiceContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-services-section/osb-services-section'
        )
            .then(results => {
                setLightSpecificServiceJson(results.elements);
                setMaxServiceRetryCount(
                    parseInt(
                        getObjectFromAEMJson(
                            SERVICES_STEP_KEYS.GET_SERVICES_MAX_RETRY_LABEL,
                            results.elements
                        )
                    ) || 0
                );

                setServicesRetryIntervalInSeconds(
                    parseInt(
                        getObjectFromAEMJson(
                            SERVICES_STEP_KEYS.GET_SERVICES_RETRY_DELAY_IN_SECONDS_LABEL,
                            results.elements
                        )
                    ) || 0
                );
            })
            .catch((error: any) => {
                setMaxServiceRetryCount(0);
                if (error?.response) {
                    const errorMessage = error.response.data.error.message;
                    dispatch({ type: 'ERROR', error: errorMessage });
                }
            });
    }

    const removeService = (service: any) => {
        let servicesListAfterRemoval: ServiceData[] = [];
        servicesListAfterRemoval = props.osbServiceStep.selectedServices.filter(
            item => item.serviceUniqueId !== service.serviceUniqueId
        );
        props.setOSBServiceStepPayload({
            selectedServices: servicesListAfterRemoval,
        });

        if (
            osbStep.preSelectedServices &&
            osbStep.preSelectedServices?.length > 0
        ) {
            const preSelectedServices = osbStep.preSelectedServices.split(',');
            setOSBStepPayload({
                preSelectedServices: preSelectedServices
                    .filter(item => item !== service.serviceId.toString())
                    .join(','),
            });
        }
    };

    const addExtraService = (service: any) => {
        const serviceData: ServiceData = OsbUtilService.frameServiceData(
            OSB_SERVICE_TYPE.EXTRA_SERVICES,
            SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
            service
        );
        props.setOSBServiceStepPayload({
            selectedServices: [
                ...props.osbServiceStep.selectedServices,
                serviceData,
            ],
        });
    };

    /*
    This method will preselect the deeplinked services.
    This method handles the main services automatic selection
    */
    const handleServicesPreSelection = (
        serviceList: ServiceInfo[],
        serviceType: string,
        selectedServices: ServiceData[]
    ) => {
        let preSelectedServices: string[] = [];
        if (osbStep.preSelectedServices?.trim()) {
            preSelectedServices = osbStep.preSelectedServices.split(',');
        }

        // Auto select the main services only if none of the main services are selected by the user
        const hasMainServiceSelected = props.osbServiceStep?.selectedServices?.find(
            serviceItem =>
                serviceItem.serviceType?.toUpperCase() === serviceType
        );

        serviceList.forEach(service => {
            if (
                (preSelectedServices.includes(service.serviceId.toString()) ||
                    (service.additionalInfo?.preSelect &&
                        !hasMainServiceSelected)) &&
                !isServiceFoundInSelectedList(service, selectedServices)
            ) {
                let localServiceType = OSB_SERVICE_TYPE.EXTRA_SERVICES;
                if (
                    serviceType === OSB_SERVICE_TYPE.SERVICES.toUpperCase() &&
                    selectedServices.filter(
                        serviceItem =>
                            serviceItem.serviceType ===
                            OSB_SERVICE_TYPE.SERVICES
                    ).length === 0
                ) {
                    localServiceType = OSB_SERVICE_TYPE.SERVICES;
                }
                const serviceData = OsbUtilService.frameServiceData(
                    localServiceType,
                    SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
                    service
                );
                preSelectedExtraServices.push(serviceData);
            }
        });
        if (preSelectedExtraServices.length > 0) {
            props.setOSBServiceStepPayload({
                selectedServices: [
                    ...selectedServices,
                    ...preSelectedExtraServices,
                ],
            });
        }
    };

    function alwaysDisplayOtherServiceLast(sortedServicesList: ServiceInfo[]) {
        let servicesList = sortedServicesList;
        const index = servicesList.findIndex(
            service => service.subType === SERVICE_FLOW.OTHER_CUSTOMSERVICE
        );
        if (index > -1) {
            const dropOffService = servicesList.splice(index, 1);
            servicesList = servicesList.concat(dropOffService);
        }

        return servicesList;
    }

    const getServiceStepServices = (
        serviceInfoList: ServiceInfo[],
        isMainServiceRetrieval = false
    ) => {
        if (serviceInfoList) {
            let selectedServices: ServiceData[] =
                props.osbServiceStep.selectedServices;
            if (isMainServiceRetrieval) {
                if (
                    osbStep.isPcFlow &&
                    osbStep.preSelectedServices &&
                    osbStep.preSelectedServices.trim().length > 0
                ) {
                    const mainServices = serviceInfoList.filter(
                        service =>
                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                OSB_SERVICE_TYPE.SERVICES.toUpperCase() &&
                            service.serviceId.toString() !==
                                SERVICE_FLOW.GA_SERVICE_ID &&
                            service.serviceId ===
                                Number(osbStep.preSelectedServices)
                    );
                    // deeplinked preselected services
                    handleServicesPreSelection(
                        mainServices,
                        OSB_SERVICE_TYPE.SERVICES.toUpperCase(),
                        selectedServices
                    );

                    return mainServices?.sort(sortOrderComparer) || [];
                } else {
                    const mainServices = serviceInfoList.filter(
                        service =>
                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                OSB_SERVICE_TYPE.SERVICES.toUpperCase() &&
                            service.serviceId.toString() !==
                                SERVICE_FLOW.GA_SERVICE_ID
                    );
                    // deeplinked preselected services
                    handleServicesPreSelection(
                        mainServices,
                        OSB_SERVICE_TYPE.SERVICES.toUpperCase(),
                        selectedServices
                    );

                    return mainServices?.sort(sortOrderComparer) || [];
                }
            } else {
                let isRecallService = false;
                let isVhaService = false;
                let isOilLifeService = false;
                let extraServices: ServiceInfo[] = [];

                const recallService = serviceInfoList.find(
                    service =>
                        service.additionalInfo?.subType &&
                        service.additionalInfo?.subType?.toUpperCase() ===
                            SERVICE_FLOW.SERVICE_SUBTYPE_RECALL
                );
                const vhaService = serviceInfoList.find(
                    service =>
                        service.additionalInfo?.subType &&
                        service.additionalInfo?.subType?.toUpperCase() ===
                            SERVICE_FLOW.SERVICE_SUBTYPE_VHA
                );
                const oilLifeService = serviceInfoList.find(
                    service =>
                        service.additionalInfo?.subType &&
                        service.additionalInfo?.subType?.toUpperCase() ===
                            SERVICE_FLOW.SERVICE_SUBTYPE_OIL
                );
                if (vhaService) {
                    if (hasOutstandingvhaActions()) {
                        if (osbVehicleStep.vehicleDetails?.vin) {
                            let vhaServiceData: ServiceData = {} as ServiceData;
                            isVhaService = true;
                            const existingService = selectedServices?.find(
                                serviceItem =>
                                    serviceItem.serviceUniqueId ===
                                    vhaService.serviceUniqueId
                            );
                            if (!existingService) {
                                vhaServiceData = OsbUtilService.frameServiceData(
                                    OSB_SERVICE_TYPE.EXTRA_SERVICES,
                                    SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
                                    vhaService
                                );
                                selectedServices = [
                                    ...selectedServices,
                                    vhaServiceData,
                                ];
                            }

                            extraServices = serviceInfoList.filter(
                                service =>
                                    service.additionalInfo?.serviceType?.toUpperCase() ===
                                        OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase() &&
                                    service.additionalInfo?.subType?.toUpperCase() !==
                                        SERVICE_FLOW.SERVICE_SUBTYPE_RECALL &&
                                    service.additionalInfo?.subType?.toUpperCase() !==
                                        SERVICE_FLOW.SERVICE_SUBTYPE_OIL
                            );
                        }
                    }
                }
                if (recallService) {
                    if (hasOutstandingRecallActions()) {
                        if (osbVehicleStep.vehicleDetails?.vin) {
                            let recallServiceData: ServiceData = {} as ServiceData;
                            isRecallService = true;
                            const existingService = selectedServices?.find(
                                serviceItem =>
                                    serviceItem.serviceUniqueId ===
                                    recallService.serviceUniqueId
                            );
                            if (!existingService) {
                                recallServiceData = OsbUtilService.frameServiceData(
                                    OSB_SERVICE_TYPE.EXTRA_SERVICES,
                                    SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
                                    recallService
                                );

                                selectedServices = [
                                    ...selectedServices,
                                    recallServiceData,
                                ];
                            }

                            if (vhaService && hasOutstandingvhaActions()) {
                                if (
                                    oilLifeService &&
                                    showOilLifeServiceReminder()
                                ) {
                                    extraServices = serviceInfoList.filter(
                                        service =>
                                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                            OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase()
                                    );
                                } else {
                                    extraServices = serviceInfoList.filter(
                                        service =>
                                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                                OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase() &&
                                            service.additionalInfo?.subType?.toUpperCase() !==
                                                SERVICE_FLOW.SERVICE_SUBTYPE_OIL
                                    );
                                }
                            } else {
                                extraServices = serviceInfoList.filter(
                                    service =>
                                        service.additionalInfo?.serviceType?.toUpperCase() ===
                                            OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase() &&
                                        service.additionalInfo?.subType?.toUpperCase() !==
                                            SERVICE_FLOW.SERVICE_SUBTYPE_OIL &&
                                        service.additionalInfo?.subType?.toUpperCase() !==
                                            SERVICE_FLOW.SERVICE_SUBTYPE_VHA
                                );
                            }
                        }
                    }
                }
                if (oilLifeService) {
                    if (showOilLifeServiceReminder()) {
                        if (osbVehicleStep.vehicleDetails?.vin) {
                            let oilServiceData: ServiceData = {} as ServiceData;
                            isOilLifeService = true;
                            const existingService = selectedServices?.find(
                                serviceItem =>
                                    serviceItem.serviceUniqueId ===
                                    oilLifeService.serviceUniqueId
                            );
                            if (!existingService) {
                                oilServiceData = OsbUtilService.frameServiceData(
                                    OSB_SERVICE_TYPE.EXTRA_SERVICES,
                                    SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
                                    oilLifeService
                                );
                                selectedServices = [
                                    ...selectedServices,
                                    oilServiceData,
                                ];
                            }

                            if (vhaService && hasOutstandingvhaActions()) {
                                if (
                                    recallService &&
                                    hasOutstandingRecallActions()
                                ) {
                                    extraServices = serviceInfoList.filter(
                                        service =>
                                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                            OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase()
                                    );
                                } else {
                                    extraServices = serviceInfoList.filter(
                                        service =>
                                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                                OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase() &&
                                            service.additionalInfo?.subType?.toUpperCase() !==
                                                SERVICE_FLOW.SERVICE_SUBTYPE_RECALL
                                    );
                                }
                            } else {
                                extraServices = serviceInfoList.filter(
                                    service =>
                                        service.additionalInfo?.serviceType?.toUpperCase() ===
                                            OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase() &&
                                        service.additionalInfo?.subType?.toUpperCase() !==
                                            SERVICE_FLOW.SERVICE_SUBTYPE_RECALL &&
                                        service.additionalInfo?.subType?.toUpperCase() !==
                                            SERVICE_FLOW.SERVICE_SUBTYPE_VHA
                                );
                            }
                        }
                    }
                }

                if (!isRecallService && !isVhaService && !isOilLifeService) {
                    extraServices = serviceInfoList.filter(
                        service =>
                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase() &&
                            ((service.additionalInfo?.subType &&
                                service.additionalInfo?.subType?.toUpperCase() !==
                                    SERVICE_FLOW.SERVICE_SUBTYPE_RECALL) ||
                                !service.additionalInfo?.subType) &&
                            ((service.additionalInfo?.subType &&
                                service.additionalInfo?.subType?.toUpperCase() !==
                                    SERVICE_FLOW.SERVICE_SUBTYPE_VHA) ||
                                !service.additionalInfo?.subType) &&
                            ((service.additionalInfo?.subType &&
                                service.additionalInfo?.subType?.toUpperCase() !==
                                    SERVICE_FLOW.SERVICE_SUBTYPE_OIL) ||
                                !service.additionalInfo?.subType)
                    );
                }

                if (selectedServices.length > 0) {
                    props.removeGAServiceIfSelected(selectedServices);
                }
                // deeplinked preselected services
                handleServicesPreSelection(
                    extraServices,
                    OSB_SERVICE_TYPE.EXTRA_SERVICES.toUpperCase(),
                    selectedServices
                );
                return alwaysDisplayOtherServiceLast(
                    extraServices?.sort(sortOrderComparer) || []
                );
            }
        }
        return [];
    };

    const getMainServiceAlongWithValueService = (
        serviceInfoList: ServiceInfo[]
    ) => {
        const mainServices = getServiceStepServices(serviceInfoList, true);

        if (mainServices) {
            setFordServiceDetails(mainServices);
        }
    };

    const getExtraServices = (serviceInfoList: ServiceInfo[]) => {
        const extraServices = getServiceStepServices(serviceInfoList, false);
        if (extraServices) {
            setExtraServiceDetails(extraServices);
        }
    };

    function getReviewBookingContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-review-booking'
        )
            .then(results => {
                setReviewBookingContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    }

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getReviewBookingContent();
        setInitialSelectedServices(props.osbServiceStep.selectedServices);
        triggerSpecificServiceLoadAnalytics(
            osbDealerStep,
            osbVehicleStep,
            osbStep,
            fireEvents
        );
        props.removeGAServiceIfSelected(props.osbServiceStep.selectedServices);
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, []);

    useEffect(() => {
        if (hasOutstandingRecallActions()) {
            recallServiceDescription();
        }
        if (hasOutstandingvhaActions()) {
            VHAServiceDescription();
        }
        if (showOilLifeServiceReminder()) {
            oilLifeServiceDescription();
        }
        getLightSpecificServiceContent();
        if (props.osbServiceStep?.dealerServiceInfo) {
            const updatedServiceList = setServiceUniqueId(
                props.osbServiceStep.dealerServiceInfo.dealerServices
            );

            getMainServiceAlongWithValueService(updatedServiceList);
            getExtraServices(updatedServiceList);
        }
    }, [props.isServiceRefreshed]); //updatedVIN

    const updateVINDealerServices = () => {
        props.refreshBookingSummary();
    };
    // Specific Service component wait till All content load from Vehicle Finder
    const loadSpecificServiceComponent = (isVehicleLoaded: boolean) => {
        if (isVehicleLoaded) {
            dispatch({ type: 'RESPONSE' });
        }
    };

    const buildPriceDisclaimers = () => {
        const priceDisclaimer: string[] = [];
        if (props.osbServiceStep.dealerServiceInfo.dealerServices) {
            props.osbServiceStep.dealerServiceInfo.dealerServices.additionalServices?.forEach(
                (item: any) => {
                    if (
                        item?.additionalInfo?.priceDisclaimerText?.trim() &&
                        item?.additionalInfo?.serviceType?.trim() !==
                            OSB_SERVICE_TYPE.DELIVERY &&
                        item?.additionalInfo?.serviceType?.trim() !==
                            OSB_SERVICE_TYPE.DROPOFF
                    ) {
                        priceDisclaimer.push(
                            '[' +
                                item.additionalInfo.priceDisclaimerSymbol +
                                '] ' +
                                item.additionalInfo.priceDisclaimerText
                        );
                    }
                }
            );
            props.osbServiceStep.dealerServiceInfo.dealerServices.oldServices?.forEach(
                (item: any) => {
                    if (
                        item?.additionalInfo?.priceDisclaimerText?.trim() &&
                        item?.additionalInfo?.serviceType?.trim() !==
                            OSB_SERVICE_TYPE.DELIVERY &&
                        item?.additionalInfo?.serviceType?.trim() !==
                            OSB_SERVICE_TYPE.DROPOFF
                    ) {
                        priceDisclaimer.push(
                            '[' +
                                item.additionalInfo.priceDisclaimerSymbol +
                                '] ' +
                                item.additionalInfo.priceDisclaimerText
                        );
                    }
                }
            );
            props.osbServiceStep.dealerServiceInfo.dealerServices.mainServices?.forEach(
                (item: any) => {
                    if (
                        item?.additionalInfo?.priceDisclaimerText?.trim() &&
                        item?.additionalInfo?.serviceType?.trim() !==
                            OSB_SERVICE_TYPE.DELIVERY &&
                        item?.additionalInfo?.serviceType?.trim() !==
                            OSB_SERVICE_TYPE.DROPOFF
                    ) {
                        priceDisclaimer.push(
                            '[' +
                                item.additionalInfo.priceDisclaimerSymbol +
                                '] ' +
                                item.additionalInfo.priceDisclaimerText
                        );
                    }
                }
            );
        }
        return priceDisclaimer;
    };

    const addFordService = (service: any) => {
        if (
            osbStep.preSelectedServices &&
            osbStep.preSelectedServices?.length > 0
        ) {
            const existingMainService: ServiceData[] = props.osbServiceStep.selectedServices.filter(
                serviceItem =>
                    serviceItem.serviceType === OSB_SERVICE_TYPE.SERVICES
            );
            const preSelectedServices = osbStep.preSelectedServices.split(',');
            if (existingMainService.length > 0) {
                setOSBStepPayload({
                    preSelectedServices: preSelectedServices
                        .filter(
                            item => item !== existingMainService[0].serviceCode
                        )
                        .toString(),
                });
            }
        }

        const servicesWithoutFord: ServiceData[] = props.osbServiceStep.selectedServices.filter(
            serviceItem => serviceItem.serviceType !== OSB_SERVICE_TYPE.SERVICES
        );
        const serviceData: ServiceData = OsbUtilService.frameServiceData(
            OSB_SERVICE_TYPE.SERVICES,
            SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
            service
        );
        servicesWithoutFord.push(serviceData);
        props.setOSBServiceStepPayload({
            selectedServices: servicesWithoutFord,
        });
    };

    const handleFordServiceSelection = (action: boolean, service: any) => {
        if (action) {
            const existingService = props.osbServiceStep?.selectedServices?.find(
                serviceItem =>
                    serviceItem.serviceUniqueId === service.serviceUniqueId
            );
            if (!existingService) {
                addFordService(service);
            }
        } else {
            removeService(service);
        }
    };

    const getRefreshedServicesData = (serviceDetails: any): ServiceData[] => {
        const tempServicesArray = props.osbServiceStep.selectedServices;
        serviceDetails?.additionalServices.filter((item: any) => {
            for (let i = 0; i < tempServicesArray.length; i++) {
                if (item.serviceId === tempServicesArray[i].serviceCode) {
                    tempServicesArray[i].priceAfterDiscount =
                        item.priceAfterDiscount;
                    tempServicesArray[i].price = item.price;
                }
            }
        });
        serviceDetails?.oldServices.filter((item: any) => {
            for (let i = 0; i < tempServicesArray.length; i++) {
                if (item.serviceId === tempServicesArray[i].serviceCode) {
                    tempServicesArray[i].priceAfterDiscount =
                        item.priceAfterDiscount;
                    tempServicesArray[i].price = item.price;
                }
            }
        });
        serviceDetails?.mainServices.filter((item: any) => {
            for (let i = 0; i < tempServicesArray.length; i++) {
                if (item.serviceId === tempServicesArray[i].serviceCode) {
                    tempServicesArray[i].priceAfterDiscount =
                        item.priceAfterDiscount;
                    tempServicesArray[i].price = item.price;
                }
            }
        });

        return OsbUtilService.sortServicesByRank(tempServicesArray);
    };

    const handleSpecificServiceContinueClick = async () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.CONTINUE_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        invalidateAuthentication();
        if (
            (isMandatoryVehicleSection &&
                (osbVehicleStep.vehicleDetails.vin ||
                    osbVehicleStep.vehicleDetails.registrationNumber)) ||
            !isMandatoryVehicleSection
        ) {
            if (
                !osbVehicleStep.vehicleDetails.vin &&
                !osbVehicleStep.vehicleDetails.registrationNumber &&
                osbVehicleStep.vinRegNo &&
                isValidVin(
                    osbVehicleStep.vinRegNo,
                    osbVehicleStep.vinRegDataPattern
                )
            ) {
                /*calls a vehicle lookup api call to validate vin or reg, if the user proceed by simply entering
                vin or regno without confirming the vehicle */
                let vehicleData: VehicleData;
                if (osbVehicleStep.vinRegNo.length <= 10) {
                    vehicleData = {
                        registrationNumber: formatRegVinNumber(
                            osbVehicleStep.vinRegNo,
                            osbVehicleStep.allowSpecialCharacters
                        ),
                        mileage: osbVehicleStep.vinMileage,
                    };
                } else {
                    vehicleData = {
                        vin: formatRegVinNumber(
                            osbVehicleStep.vinRegNo,
                            osbVehicleStep.allowSpecialCharacters
                        ),
                        mileage: osbVehicleStep.vinMileage
                            ? osbVehicleStep.vinMileage
                            : osbVehicleStep.defaultMileage,
                    };
                }
                await ServiceHandler.OsbVehicleLookUp.postVehicleInfo({
                    vehicleData: vehicleData,
                    prognosticsData: osbVehicleStep.vehiclePrognosticData,
                    dealerCode: osbDealerStep.selectedDealerProfile.dealerCode,
                    bookable: bookable,
                })
                    .then()
                    .catch((error: any) => {
                        dispatch({
                            type: 'ERROR',
                            error: error.errorMessage,
                        });
                    });
            }

            let selectedServiceList: ServiceData[] = [];
            let isServiceSelectionChanged = false;
            if (
                props.osbServiceStep.voucherDesc.length > 0 ||
                props.osbServiceStep.voucherCode.length > 0
            ) {
                selectedServiceList = getRefreshedServicesData(
                    props.osbServiceStep.dealerServiceInfo.dealerServices
                );
            } else {
                selectedServiceList = props.osbServiceStep.selectedServices;
            }

            if (
                selectedServiceList.length === 0 &&
                !osbServiceStep.isMobileServiceSelected
            ) {
                isServiceSelectionChanged = props.addDefaultService(
                    props.osbServiceStep.dealerServiceInfo.dealerServices
                );
            } else {
                isServiceSelectionChanged = props.isRetrieveFlow
                    ? isListSelectionChanged(
                          initialSelectedServices ||
                              props.osbServiceStep.selectedServices,
                          selectedServiceList
                      )
                    : false;
            }
            if (props.isRetrieveFlow) {
                if (props.osbServiceStep.isMobileServiceSelected) {
                    updateProgressBarStatus(
                        STEP_PROGRESS_BAR_KEYS.LOCATION,
                        false,
                        true
                    );
                    history.push(
                        buildNavigationUrl(
                            OsbPathServiceLocationStep(),
                            osbStep.UrlQueryParams
                        )
                    );
                } else {
                    if (isServiceSelectionChanged) {
                        updateProgressBarStatus(
                            STEP_PROGRESS_BAR_KEYS.SERVICE,
                            false
                        );
                        updateProgressBarStatus(
                            STEP_PROGRESS_BAR_KEYS.DATETIME,
                            false,
                            true
                        );
                        history.push(
                            buildNavigationUrl(
                                OsbPathCalenderStep(),
                                osbStep.UrlQueryParams
                            )
                        );
                    } else {
                        history.push(
                            buildNavigationUrl(
                                OsbPathBookingRetrieveSummaryStep(),
                                osbStep.UrlQueryParams
                            )
                        );
                    }
                }
                triggerBookServiceGlobalCTAOnClickAnalytics(
                    LIGHT_JOURNEY_ANALYTICS.CONTINUE_BTN_CTA_NAME,
                    fireEvents,
                    osbVehicleStep
                );
            } else {
                updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, true);
                if (props.osbServiceStep.isMobileServiceSelected) {
                    history.push(
                        buildNavigationUrl(
                            OsbPathServiceLocationStep(),
                            osbStep.UrlQueryParams
                        )
                    );
                } else {
                    if (!osbDealerStep.isDealerHasDeliveryServices) {
                        history.push(
                            buildNavigationUrl(
                                OsbPathCalenderStep(),
                                osbStep.UrlQueryParams
                            )
                        );
                    } else {
                        history.push(
                            buildNavigationUrl(
                                OsbPathDeliveryStep(),
                                osbStep.UrlQueryParams
                            )
                        );
                    }
                }
            }
        } else {
            setShouldValidateVehicleData(!shouldValidateVehicleData);
            if (serverSideService.isClientSide()) {
                window.scrollTo({
                    top: getGoMainHeaderHeight() - 2,
                    behavior: 'smooth',
                });
            }
        }
    };

    function enabledNewServiceDesign() {
        return getObjectFromAEMJson(
            SERVICE_FLOW.ENABLE_NEW_SERVICE_DESIGN,
            lightSpecificServiceJson
        );
    }

    const handleExtraServiceSelection = (action: boolean, service: any) => {
        if (action) {
            const existingService = props.osbServiceStep?.selectedServices?.find(
                serviceItem =>
                    serviceItem.serviceUniqueId === service.serviceUniqueId
            );
            if (
                service.additionalInfo.subType ===
                SERVICE_FLOW.OTHER_CUSTOMSERVICE
            ) {
                props.setOSBServiceStepPayload({
                    isOtherServiceSelected: true,
                });
            }

            if (!existingService) {
                addExtraService(service);
            }
        } else {
            if (
                service.additionalInfo.subType ===
                SERVICE_FLOW.OTHER_CUSTOMSERVICE
            ) {
                props.setOSBServiceStepPayload({
                    isOtherServiceSelected: false,
                });
            }
            removeService(service);
        }
    };

    const hasServiceSelected = (serviceId: number) => {
        if (!props.osbServiceStep?.selectedServices) {
            return false;
        }
        const selectedService = props.osbServiceStep?.selectedServices?.find(
            item => item.serviceCode === serviceId.toString()
        );
        return !!selectedService;
    };

    const showFordServicesCheckboxGroup = (serviceDetails: ServiceInfo[]) => {
        return (
            <div className="services-list">
                <OsbServiceRenderer
                    servicesList={serviceDetails}
                    handleServiceSelection={handleFordServiceSelection}
                    hasServiceSelected={hasServiceSelected}
                    newServiceStyle={enabledNewServiceDesign()}
                    accordionName={'Services'}
                    accordionTitle={getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.SERVICES_HEADER,
                        lightSpecificServiceJson
                    ).toLowerCase()}
                    showDisclaimer={true}
                    serviceAemContent={lightSpecificServiceJson}
                />
            </div>
        );
    };

    const showExtraServicesCheckboxGroup = (serviceDetails: ServiceInfo[]) => {
        return (
            <div className="services-list">
                <OsbServiceRenderer
                    servicesList={serviceDetails}
                    fsaDescription={recallDescription}
                    vhaDescription={vhaDescription}
                    oilLifeDescription={oilLifeDescription}
                    handleServiceSelection={handleExtraServiceSelection}
                    hasServiceSelected={hasServiceSelected}
                    otherServiceDescInStore={
                        props.osbServiceStep.otherServiceDesc &&
                        props.osbServiceStep.otherServiceDesc.length > 0
                            ? props.osbServiceStep.otherServiceDesc
                            : ''
                    }
                    setOtherServiceDescInStore={props.setOSBServiceStepPayload}
                    serviceAemContent={lightSpecificServiceJson}
                    newServiceStyle={enabledNewServiceDesign()}
                    accordionName={'Extra Services'}
                    accordionTitle={getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.EXTRA_SERVICES_ACCORDION_LABEL,
                        lightSpecificServiceJson
                    ).toLowerCase()}
                />
            </div>
        );
    };

    const isBackButtonHidden = () => {
        return (
            osbStep.isDealerDeepLinked &&
            (!props.isMobileServiceCardAuthored ||
                !osbDealerStep.hasMobileService)
        );
    };

    return (
        <div className="light-specific-service-wrapper">
            <div className="light-journey-specific-page">
                <div className="light-specific-service">
                    {(osbStep.isWebViewJourney || !isMobileView) &&
                        !isBackButtonHidden() && (
                            <OSBSecondaryButton
                                dataTestId="BackToServiceCardPage"
                                type="svg"
                                direction="left"
                                text={
                                    lightSpecificServiceJson &&
                                    getObjectFromAEMJson(
                                        SERVICES_STEP_KEYS.SPECIFIC_SERVICE_BACK_LABEL,
                                        lightSpecificServiceJson
                                    )
                                }
                                onClickHandler={() =>
                                    props.handleSpecificServiceBackClick()
                                }
                            />
                        )}

                    <div className="specific-service-header">
                        {lightSpecificServiceJson &&
                            (props.osbServiceStep.isMobileServiceSelected
                                ? getObjectFromAEMJson(
                                      SERVICES_STEP_KEYS.MOBILE_SERVICE_HEADER,
                                      lightSpecificServiceJson
                                  )
                                : getObjectFromAEMJson(
                                      SERVICES_STEP_KEYS.SPECIFIC_SERVICE_HEADER,
                                      lightSpecificServiceJson
                                  ))}
                    </div>

                    {osbStep.isRetrieveFlow ? (
                        <></>
                    ) : (
                        <VehicleFinder
                            updateVINDealerServices={updateVINDealerServices}
                            voucherCode={
                                props.osbServiceStep.voucherCode
                                    ? props.osbServiceStep.voucherCode
                                    : ''
                            }
                            maxServiceRetryCount={maxServiceRetryCount}
                            shouldValidateVehicleData={
                                shouldValidateVehicleData
                            }
                            updateVehicleSectionMandatoryState={
                                updateVehicleSectionMandatoryState
                            }
                            servicesRetryIntervalInSeconds={
                                servicesRetryIntervalInSeconds
                            }
                            loadSpecificServiceComponent={
                                loadSpecificServiceComponent
                            }
                            osbAlertMessagePopup={props.osbAlertMessagePopup}
                        />
                    )}
                    {!enabledNewServiceDesign() && (
                        <div className="services-header">
                            {lightSpecificServiceJson &&
                                getObjectFromAEMJson(
                                    SERVICES_STEP_KEYS.SERVICES_HEADER,
                                    lightSpecificServiceJson
                                ).toLowerCase()}
                        </div>
                    )}
                    <div className={`brand-${osbStep.brandName}`}>
                        {fordServiceDetails &&
                            showFordServicesCheckboxGroup(fordServiceDetails)}
                    </div>
                    {!enabledNewServiceDesign() && (
                        <>
                            <div className="hr-line-style"></div>
                            <div className="services-header">
                                {lightSpecificServiceJson &&
                                    getObjectFromAEMJson(
                                        SERVICES_STEP_KEYS.EXTRA_SERVICES_ACCORDION_LABEL,
                                        lightSpecificServiceJson
                                    ).toLowerCase()}
                            </div>
                        </>
                    )}
                    <div className={`brand-${osbStep.brandName}`}>
                        {extraServiceDetails &&
                            showExtraServicesCheckboxGroup(extraServiceDetails)}
                    </div>
                    {!enabledNewServiceDesign() && (
                        <div className="hr-line-style"></div>
                    )}

                    {osbStep.osbEnableImage ? (
                        <OSBFileUploader
                            uploadImageInStore={
                                props.osbImageUploadStep.uploadedImage &&
                                props.osbImageUploadStep.uploadedImage?.length >
                                    0
                                    ? props.osbImageUploadStep.uploadedImage
                                    : []
                            }
                            setUploadImageInStore={
                                props.setOSBImageServiceStepPayload
                            }
                            serviceAemContent={
                                props.osbImageUploadStep.osbImageContent
                            }
                        />
                    ) : (
                        <></>
                    )}

                    <div className="ss-comments">
                        <OsbComments
                            commentInStore={props.osbServiceStep.comment || ''}
                            setCommentInStore={props.setOSBServiceStepPayload}
                            serviceAemContent={lightSpecificServiceJson}
                            isRequiredComments={isRequiredComments}
                            emptyCommentErrorMsg={getObjectFromAEMJson(
                                DATE_TIME_STEP.PLACEHOLDER_LABEL,
                                reviewBookingContent?.elements || []
                            )}
                        />
                    </div>
                    <div className="price-disclaimer">
                        {buildPriceDisclaimers().map(disclaimer => (
                            <div key={disclaimer}>{disclaimer}</div>
                        ))}
                    </div>
                    <div className="continue-button">
                        <PrimaryButton
                            dataTestId="SpecificServiceContinueClick"
                            color="dark"
                            fill="fill"
                            aria-label="select-service-continue-cta"
                            aria-labelledby="select service continue cta"
                            chevron={false}
                            disabled={
                                (props.osbServiceStep.isOtherServiceSelected &&
                                    props.osbServiceStep.otherServiceDesc ===
                                        '') ||
                                !!isRequiredComments
                            }
                            onClick={handleSpecificServiceContinueClick}
                        >
                            {lightSpecificServiceJson
                                ? getObjectFromAEMJson(
                                      SERVICES_STEP_KEYS.CONTINUE_BUTTON_LABEL,
                                      lightSpecificServiceJson
                                  )
                                : 'Continue'}
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
