import { useMemo } from 'react';
import {
    VehicleData,
    VehicleStep,
} from '../../models/osb-model/osb-vehicle-step';
import {
    useServiceStep,
    useOSBStep,
    useDealerStep,
    useVehicleStep,
    useLightDeliveryOptionServiceStep,
    usePersonalDetailStep,
    useServiceLocationStep,
    useCalendarStep,
    useOsbImageUpload,
    useReviewBookingStep,
} from './';
import {
    getLightVehicleData,
    getLightVehicleDataForBookingPayload,
    isConvenienceOptionTypePresent,
} from '../../components/sections/owners-osb/osb-utils';
import {
    SERVICES_STEP_KEYS,
    SERVICE_DELIVERY_TYPE,
    SERVICE_FLOW,
} from '../../components/sections/owners-osb/osb-constant';
import { OSBStep } from '../../models/osb-model/osb-step';
import { DealerProfileInfo } from '../../models/osb-model/osb-dealer-info';
import { ServiceLocationDetailsForBookingPayload } from '../../models/osb-model/osb-service-location';
import {
    Customer,
    ExtractValueType,
    Service,
    getCustomerDetails,
    getDateTime,
    getIntents,
    getLocationDetails,
    getSelectedServices,
} from '../../components/sections/owners-osb/osb-bookable-utils';
import { CalendarStep } from '../../models/osb-model/osb-calendar-step';
import { BookingsInfo } from '../../models/osb-model/osb-retrieve-booking-step';
import { ReviewBooking } from '../../models/osb-model/osb-review-booking';
import { PrognosticsDataV2 } from '../../models/vehicle-prognostics';

type CampaignVoucher = {
    voucherCode: string;
};

export type Bookable = {
    appointmentDateTimeLocal?: string;
    servicesBooked: Service[];
    dealerCode: ExtractValueType<DealerProfileInfo, 'dealerCode'>;
    delivery: typeof SERVICE_DELIVERY_TYPE[keyof typeof SERVICE_DELIVERY_TYPE];
    source?: ExtractValueType<OSBStep, 'source'>;
    isMock?: boolean;
    campaignSelections: CampaignVoucher[];
    bookingReferenceNumber: string;
    bookingAuthorization?: {
        accessCode: ExtractValueType<ReviewBooking, 'accessCode'>;
        identifier: ExtractValueType<ReviewBooking, 'identifier'>;
    };
    vehicle: VehicleData;
    customers?: Customer[];
    vehicleLocations?: ServiceLocationDetailsForBookingPayload[];
    intents?: string[];
    prognosticsData?: PrognosticsDataV2;
};

const getAppointmentDateTime = (
    osbCalendarStep: CalendarStep,
    isMobileServiceSelected: boolean
) => {
    if (isMobileServiceSelected) {
        return getDateTime(
            osbCalendarStep.selectedDate,
            osbCalendarStep.selectedTimeRange.split('-')[0].trim()
        );
    }

    return getDateTime(
        osbCalendarStep.selectedDate,
        osbCalendarStep.selectedTime
    );
};

export const useAllOSBState = (): Bookable => {
    const { osbStep } = useOSBStep();
    const { osbDealerStep } = useDealerStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbServiceStep } = useServiceStep();
    const { osbCalendarStep } = useCalendarStep();
    const { osbLightDeliveryServiceStep } = useLightDeliveryOptionServiceStep();
    const { osbPersonalDetail } = usePersonalDetailStep();
    const { osbServiceLocationDetail } = useServiceLocationStep();
    const { osbImageUploadStep } = useOsbImageUpload();
    const { osbReviewBooking } = useReviewBookingStep();
    const isPickUpAndDeliverySelected =
        (isConvenienceOptionTypePresent(
            SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE,
            osbLightDeliveryServiceStep.selectedServices
        ) &&
            osbStep.enablePickupNDeliveryLocationDateAndTime) ||
        false;

    const appointmentDateTimeLocal =
        osbCalendarStep.selectedDate &&
        (osbCalendarStep.selectedTime || osbCalendarStep.selectedTimeRange)
            ? getAppointmentDateTime(
                  osbCalendarStep,
                  osbServiceStep.isMobileServiceSelected
              )
            : '';
    const campaignSelections = osbServiceStep.voucherCode
        ? [
              {
                  voucherCode: osbServiceStep.voucherCode,
              },
          ]
        : [];
    const customers = getCustomerDetails(osbPersonalDetail);
    const vehicleLocations = getLocationDetails(
        osbServiceLocationDetail,
        osbCalendarStep,
        isPickUpAndDeliverySelected
    );
    const customerAnnotation = osbServiceStep.comment;
    const intents = getIntents(osbImageUploadStep);
    const bookingAuthorization =
        osbReviewBooking.accessCode && osbReviewBooking.identifier
            ? {
                  identifier: osbReviewBooking.identifier,
                  accessCode: osbReviewBooking.accessCode,
              }
            : null;
    const bookable = useMemo((): Bookable => {
        return {
            ...(bookingAuthorization && { bookingAuthorization }),
            ...(customerAnnotation && { customerAnnotation }),
            ...(appointmentDateTimeLocal && { appointmentDateTimeLocal }),
            campaignSelections,
            // check when to use getLightVehicleDataForBookingPayload | getVehicleData
            vehicle: getLightVehicleDataForBookingPayload(
                osbVehicleStep,
                osbPersonalDetail
            ),
            dealerCode: osbDealerStep.selectedDealerProfile.dealerCode,
            delivery: osbServiceStep.isMobileServiceSelected
                ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                : SERVICE_DELIVERY_TYPE.WORKSHOP,
            bookingReferenceNumber:
                osbReviewBooking.bookingReferenceNumber || '',
            ...(osbStep.source && { source: osbStep.source }),
            servicesBooked: getSelectedServices(
                osbServiceStep,
                osbLightDeliveryServiceStep,
                osbServiceLocationDetail
            ),
            ...(customers && customers.length > 0 && { customers }),
            ...(((!osbServiceStep.isMobileServiceSelected &&
                isPickUpAndDeliverySelected) ||
                (osbServiceStep.isMobileServiceSelected &&
                    !isPickUpAndDeliverySelected)) &&
                vehicleLocations &&
                vehicleLocations.length > 0 && { vehicleLocations }),
            ...(intents.length > 0 && { intents }),
            prognosticsData:
                osbVehicleStep.vehiclePrognosticData?.featureType ===
                SERVICES_STEP_KEYS.SM_FEATURE_TYPE
                    ? osbVehicleStep?.vehiclePrognosticData
                    : undefined,
        };
    }, [
        osbStep,
        osbDealerStep,
        osbVehicleStep,
        osbServiceStep,
        osbCalendarStep,
    ]);
    return bookable;
};

export const getBookableFromBookingApiResponse = (
    bookingResponse: BookingsInfo,
    vehicleStepInfo: VehicleStep
): Bookable => {
    const {
        mainService,
        additionalServices,
        oldServices,
        dealer,
        bookingReferenceNumber,
        appointmentDateTimeLocal,
    } = bookingResponse;
    const additionalServicesBooked = additionalServices.map(service => {
        return {
            id: service.type + ':' + service.serviceId,
            name: service.name,
        };
    });
    const oldServicesBooked = oldServices.map(service => {
        return {
            id: service.type + ':' + service.serviceId,
            name: service.name,
        };
    });
    const servicesBooked =
        mainService.serviceId !== 0
            ? [
                  {
                      id: mainService.type + ':' + mainService.serviceId,
                      name: mainService.name,
                  },
                  ...additionalServicesBooked,
                  ...oldServicesBooked,
              ]
            : [...additionalServicesBooked, ...oldServicesBooked];
    const isMobileServiceSelected =
        bookingResponse.vehicleLocation?.locationType ===
        SERVICE_DELIVERY_TYPE.MOBILESERVICE;

    const campaignSelections = [];
    if (
        Array.isArray(bookingResponse.campaignStates) &&
        bookingResponse.campaignStates.length > 0
    ) {
        const voucherCode = bookingResponse.campaignStates[0].key;
        campaignSelections.push({
            voucherCode,
        });
    }
    return {
        dealerCode: dealer.dealerCode,
        delivery: isMobileServiceSelected
            ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
            : SERVICE_DELIVERY_TYPE.WORKSHOP,
        campaignSelections,
        servicesBooked,
        vehicle: getLightVehicleData(vehicleStepInfo),
        bookingReferenceNumber,
        appointmentDateTimeLocal: appointmentDateTimeLocal,
        source: bookingResponse.source as ExtractValueType<OSBStep, 'source'>,
    };
};
