import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../../common';
import InputWithPlaceholder from '../input-with-placeholder/input-with-placeholder';
import { VehicleDetail } from '../../../../models/profile-with-vehicles';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import {
    PRICE_CALCULATOR_ANALYTICS,
    PRICE_CALCULATOR_CONSTANTS,
} from '../price-calculator-constant';
import {
    getClientStorage,
    getObjectFromAEMJson,
    setClientStorage,
    isAuthenticatedFlow,
} from '../../owners-osb/osb-utils';
import './vehicle-dropdown.scss';
import FindMyVinPopup from '../find-my-vin-popup/find-my-vin-popup';
import { ViewportUtil } from '../../../utils/viewport-util/viewport-util';
import { useVehicleStep } from '../../../../hooks/owners-osb';
import { triggerLocationSignInAnalytics } from '../analytics/price-calculator-analytics';
import { useAnalytics } from '../../../../hooks/use-analytics';

interface Option {
    label: string;
    value: string;
}
interface Props {
    priceCalculatorContent: ContentElements[];
    vehicles: VehicleDetail[];
    vinRegError?: string;
    onVehicleSelect: (selectedVehicle: string) => void;
    onVinSelectionChange: (selection: string) => void;
    findMyVinContent: ContentElements[];
}
const VehicleDropdown = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const [showVinInput, setShowVinInput] = useState(
        getClientStorage('pc-manual-vin') === 'true'
    );
    const { isMobileView } = ViewportUtil();
    const [showFindMyVinPopup, setShowFindMyVinPopup] = useState<boolean>(
        false
    );
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
        isAuthenticatedFlow()
    );
    const { osbVehicleStep } = useVehicleStep();
    const [inputObj, setInputObj] = useState<Option>({
        label: '',
        value: '',
    });

    const vinORRegNoPlaceHolder = getObjectFromAEMJson(
        PRICE_CALCULATOR_CONSTANTS.Vin_RegNo_PlaceHolder,
        props.priceCalculatorContent
    );

    const handleVehicleSelect = (
        label: string,
        index: number,
        value: string | undefined
    ) => {
        setClientStorage(
            'pc-manual-vin',
            String(value === 'enter_vin_manually')
        );
        setShowVinInput(value === 'enter_vin_manually');
        setInputObj({
            label: value === 'enter_vin_manually' ? '' : label,
            value: value || '',
        });
        props.onVehicleSelect(value ?? '');
    };

    const handleVinInputChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        props.onVehicleSelect((event.target.value as string) ?? '');
    };

    const handleGoBackClick = () => {
        setClientStorage('pc-manual-vin', 'false');
        setShowVinInput(false);
    };
    const createVehicleOptionsArray = ({ vehicles }: Props): Option[] => {
        if (props.vehicles.length <= 0) return [];
        const options = vehicles.map(vehicle => {
            const label =
                vehicle?.nickName && vehicle?.nickName.trim() !== ''
                    ? vehicle?.nickName
                    : `${vehicle?.modelName} ${vehicle?.modelYear}`;
            return {
                label,
                value: vehicle?.vin,
            };
        });
        options.push({
            label: getObjectFromAEMJson(
                PRICE_CALCULATOR_CONSTANTS.Enter_NewVin_Label,
                props.priceCalculatorContent
            ),
            value: 'enter_vin_manually',
        });
        return options;
    };
    const FindMyVin = () => {
        return (
            <>
                {isMobileView ? (
                    <div
                        className="vin-reg-info-icon"
                        onClick={renderFindMyVinPopup}
                    >
                        <span className="find-vin-icon">&#9432;</span>
                    </div>
                ) : (
                    <div className="spc-desktop-search-where-to-find-vin-link">
                        <div
                            className="spc-desktop-search-input-link-text"
                            onClick={renderFindMyVinPopup}
                        >
                            <span>
                                {getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Where_To_Find_My_Vin,
                                    props.priceCalculatorContent
                                )}
                            </span>
                            <span className="spc-desktop-search-input-link-icon">
                                &#9432;
                            </span>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const onModalClose = () => {
        setShowFindMyVinPopup(false);
    };

    const renderFindMyVinPopup = () => {
        setShowFindMyVinPopup(true);
        triggerLocationSignInAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.FIND_MY_VIN
        );
    };

    function getVehicleByVin(vin: string): VehicleDetail | undefined {
        return props.vehicles.find(vehicle => vehicle.vin === vin);
    }
    useEffect(() => {
        setIsAuthenticated(isAuthenticatedFlow());
        function initializeDropdown() {
            if (props.vehicles.length > 0) {
                const selectedVehicle =
                    getVehicleByVin(osbVehicleStep.vinRegNo) ||
                    props.vehicles[0];
                const nickname =
                    selectedVehicle.nickName?.trim() ||
                    `${selectedVehicle.modelName} ${selectedVehicle.modelYear}`;

                setInputObj({
                    value: selectedVehicle.vin || '',
                    label: nickname,
                });
            }
        }
        if (isAuthenticated) {
            const manualVin = getClientStorage('pc-manual-vin') === 'true';
            if (props.vehicles.length <= 0) {
                props.onVinSelectionChange('INPUT_VIN');
            } else if (showVinInput || manualVin) {
                props.onVinSelectionChange('INPUT_VIN_AUTH');
            } else {
                props.onVinSelectionChange('DROPDOWN_VIN_AUTH');
                initializeDropdown();
            }
        } else {
            props.onVinSelectionChange('INPUT_VIN');
        }
    }, [showVinInput, props.vehicles, isAuthenticated]);

    return (
        <>
            {isAuthenticated ? (
                <>
                    {props.vehicles.length <= 0 ? (
                        <div
                            className={` ${
                                isMobileView ? 'vehicle-vin-input' : ''
                            }`}
                        >
                            <InputWithPlaceholder
                                placeholder={vinORRegNoPlaceHolder}
                                onChange={handleVinInputChange}
                                error={props.vinRegError}
                                formattedValue={osbVehicleStep.vinRegNo ?? ''}
                            />
                            {FindMyVin()}
                        </div>
                    ) : showVinInput ? (
                        <>
                            <div
                                className={` ${
                                    isMobileView ? 'vehicle-vin-input' : ''
                                }`}
                            >
                                <InputWithPlaceholder
                                    placeholder={vinORRegNoPlaceHolder}
                                    onChange={handleVinInputChange}
                                    error={props.vinRegError}
                                    formattedValue={
                                        osbVehicleStep.vinRegNo ?? ''
                                    }
                                />
                                {isMobileView && FindMyVin()}
                            </div>
                            <div
                                onClick={handleGoBackClick}
                                className="spc-vehicle-link"
                            >
                                {getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Or_Choose_Your_VehicleLink,
                                    props.priceCalculatorContent
                                )}
                            </div>
                            {!isMobileView && FindMyVin()}
                        </>
                    ) : (
                        <Dropdown
                            showLabel={true}
                            label={getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.Please_Select_PlaceHolder,
                                props.priceCalculatorContent
                            )}
                            options={createVehicleOptionsArray(props)}
                            value={inputObj.label}
                            onChange={handleVehicleSelect}
                        />
                    )}
                </>
            ) : (
                <div className={` ${isMobileView ? 'vehicle-vin-input' : ''}`}>
                    <InputWithPlaceholder
                        placeholder={vinORRegNoPlaceHolder}
                        onChange={handleVinInputChange}
                        error={props.vinRegError}
                        formattedValue={osbVehicleStep.vinRegNo ?? ''}
                    />
                    {FindMyVin()}
                </div>
            )}
            <div className="find-my-vin-popup-container">
                <FindMyVinPopup
                    showFindMyVinPopup={showFindMyVinPopup}
                    onModalClose={onModalClose}
                    aemContent={props.findMyVinContent}
                />
            </div>
        </>
    );
};

export default VehicleDropdown;
