import { useMmotaStatusModalContent } from './hook/use-mmota-status-modal';
import DateTimeUtil from '../../utils/date-time-util/date-time-util';
import { TappsData } from '../../../services/mmota-status-service/mmota-status.interface';

export const DSL_MMOTA_STATUS = {
    upToDate: 'No Qualified Alerts Found',
    updateSuccessful: 'UPDATE SUCCESSFUL', //MMOTA_UPDATE_SUCCESSFUL //
    updateReminder: 'UPDATE SCHEDULED', //MMOTA_UPDATE_SCHEDULED previously UPDATE REMINDER
    updatePending: 'UPDATE PENDING - ACTION REQUIRED', //MMOTA_UPDATE_PENDING

    updateWillRetry: 'UPDATE WILL RETRY - PRECONDITIONS NOT MET', //MMOTA_UPDATE_WILL_RETRY || RESCHEDULED

    updateInProgress: 'UPDATE IN PROGRESS - VEHICLE NOT DRIVABLE', //MMOTA_UPDATE_IN_PROGRESS
    vehicleInoperable: 'UPDATE NOT SUCCESSFUL - VEHICLE NOT DRIVABLE', //MMOTA_NOT_SUCCESSFUL
    updateUnsuccessfullServiceRecommended:
        'UPDATE NOT SUCCESSFUL - SERVICE RECOMMENDED', //MMOTA_NOT_SUCCESSFUL_SERVICE_RECOMMENDED,
    updateUnsuccessfullWillTryAgain: 'UPDATE NOT SUCCESSFUL - WILL TRY AGAIN',
};
export const USER_DISALLOWED = 'UserDisallowed';
export const SETTINGS_DISABLED = 'settingsDisabled';

export class MMOTAUtil {
    public mmotaStatusModalContent = useMmotaStatusModalContent();
    dateTimeUtil = new DateTimeUtil();

    getWeekTranslated(day: string): string {
        const dayOfWeekMap = {
            MONDAY: this.mmotaStatusModalContent?.mondayText,
            TUESDAY: this.mmotaStatusModalContent?.tuesdayText,
            WEDNESDAY: this.mmotaStatusModalContent?.wednesdayText,
            THURSDAY: this.mmotaStatusModalContent?.thursdayText,
            FRIDAY: this.mmotaStatusModalContent?.fridayText,
            SATURDAY: this.mmotaStatusModalContent?.saturdayText,
            SUNDAY: this.mmotaStatusModalContent?.sundayText,
        } as any;
        return dayOfWeekMap[day];
    }

    getTimeTranslated(time: string, showInMeridiem?: boolean): string {
        const timeOfTheDay = showInMeridiem
            ? this.dateTimeUtil.convertMilitaryToMeridiemTime(time)
            : this.mmotaStatusModalContent?.twentyFourHourText;
        return timeOfTheDay || '';
    }

    getParsedRecurringDateTime(
        tappsData: TappsData | null,
        showInMeridiem?: boolean
    ) {
        const parsedDayTime: string[] = [''];
        if (tappsData && tappsData.dayOfWeekAndTime) {
            parsedDayTime.pop();
            tappsData.dayOfWeekAndTime.forEach(dayTime => {
                const day = this.getWeekTranslated(dayTime.daysOfWeek);
                const at = this.mmotaStatusModalContent?.atTranslationText
                    ? this.mmotaStatusModalContent?.atTranslationText
                    : 'at';
                const time = this.getTimeTranslated(
                    dayTime.timeOfDay,
                    showInMeridiem
                );

                const formattedDayTime = `${day} ${at} ${time}`;
                parsedDayTime.push(formattedDayTime);
            });
        }
        return parsedDayTime.join(' ');
    }
}
