import React, { useEffect, useState } from 'react';
import './light-services-flow-lincoln.scss';
import {
    useHttp,
    useServiceStep,
    useOSBStep,
    useVehicleStep,
    useDealerStep,
    useStepProgressBar,
    useViewport,
    useServiceLocationStep,
    useLightDeliveryOptionServiceStep,
    useOsbImageUpload,
    usePageRefresh,
    useRetrieveBookingStep,
} from '../../../../../../hooks/owners-osb';
import BookingSummaryAccordion from '../../../light-booking-summary/booking-summary-accordion/booking-summary-accordion';
import {
    getObjectFromAEMJson,
    getVehicleData,
    isListSelectionChanged,
    setRouteToPersist,
    buildNavigationUrl,
    processDeliveryStepProgressbarDisplay,
    hasServicesPresentInDeeplinkedServices,
    updateDeeplinkedPreSelectedServices,
    ErrorResponse,
    getDefaultDeliveryOption,
    hasMobileService,
    getGoMainHeaderHeight,
} from '../../../osb-utils';
import { OsbLoader } from '../../../../../common/osb-loader/osb-loader';
import OSBCard from '../../../osb-card/osb-card';
import {
    DEALER_STEP_KEYS,
    SERVICE_FLOW,
    STEP_PROGRESS_BAR_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_SERVICE_TYPE,
    OSB_CLIENT_STORAGE_KEYS,
    SERVICE_DELIVERY_TYPE,
} from '../../../osb-constant';
import { LightSpecificService } from '../light-specific-service/light-specific-service';
import { VehicleData } from '../../../../../../models/osb-model/osb-vehicle-step';
import {
    DealerServices,
    ServiceData,
    ServiceInfo,
} from '../../../../../../models/osb-model/osb-dealerservice-info';
import { LightBookingSummary } from '../../../light-booking-summary/light-booking-summary';
import { OSBSecondaryButton } from '../../../common/osb-secondary-button/osb-secondary-button';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import {
    triggerSelectServiceLoadAnalytics,
    triggerSelectServiceCardAnalytics,
    triggerBookServiceGlobalCTAOnClickAnalytics,
} from '../../../analytics/osb-analytics';
import {
    OsbPathBookingRetrieveSummaryStep,
    OsbPathCalenderStep,
    OsbPathDealerStep,
    OsbPathDeliveryStep,
    OsbPathServicesStep,
} from '../../../osb-controller';
import { useHistory } from 'react-router-dom';
import ServiceHandler from '../../../../../../services/service-handler';
import { ServiceCard } from '../../../../../../models/osb-model/osb-service-step';
import serverSideService from '../../../../../../services/server-side-service/server-side-service';
import { useCampaignStatesStep } from '../../../../../../hooks/owners-osb/use-campaign-states-step';
import { useAllOSBState } from '../../../../../../hooks/owners-osb/use-all-osb-state';
import OsbUtilService from '../../../../../../services/osb-service/osb-util-service/osb-util-service';

const serviceCardSection = 'osb-service-card-section';
const serviceCardOrder = 'serviceCardOrderKey';
const GENERAL_APPOINTMENT = 'general_appointment';
const serviceLandingPageCardKey = 'serviceLandingPageCardOrderKey';

interface Props {
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}
export default function LightServicesFlow(props: Readonly<Props>) {
    const [
        isMobileServiceCardAuthored,
        setIsMobileServiceCardAuthored,
    ] = useState(false);
    const [isServiceRefreshed, setIsServiceRefreshed] = useState(false);
    const [fireEvents] = useAnalytics();
    const [error, setError] = useState('');
    const errorDiv = error ? <div className="error">{error}</div> : '';
    const {
        osbStepProgressBar,
        updateProgressBarStatus,
        manageOsbStepVisibility,
    } = useStepProgressBar();
    const [serviceCardSectionJson, setServiceCardSectionJson] = useState<any>();
    const [serviceCardJson, setServiceCardJson] = useState<ServiceCard[]>();
    const [
        landingPageServiceCardJson,
        setLandingPageServiceCardJson,
    ] = useState<ServiceCard[]>();
    const [serviceOrderArray, setServiceOrderArray] = useState<ServiceCard[]>(
        []
    );
    const [showLandingPageCards, setShowLandingPageCards] = useState(true);
    const { httpState, dispatch } = useHttp();
    const {
        osbStep,
        setOSBStepPayload,
        invalidateAuthentication,
    } = useOSBStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbRetrieveBookingStep } = useRetrieveBookingStep();
    const {
        osbDealerStep,
        callContentService,
        setOSBDealerStepPayload,
    } = useDealerStep();
    const { resetServiceLocationStep } = useServiceLocationStep();
    const {
        osbImageUploadStep,
        setOSBImageServiceStepPayload,
    } = useOsbImageUpload();
    const {
        osbLightDeliveryServiceStep,
        resetLightDeliveryServiceStep,
        setOSBLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const history = useHistory();
    const getServiceCardContent = async (modelName: string) => {
        return callContentService(modelName);
    };
    const { setOSBCampaignStates } = useCampaignStatesStep();
    const [
        showSpecificServiceComponent,
        setShowSpecificServiceComponent,
    ] = useState<boolean>(false);

    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const { isMobileView } = useViewport();
    const bookable = useAllOSBState();
    const isRetrieveStep = osbStep.isRetrieveFlow;
    let isSkipServiceSelectionStep =
        osbStep.isAuthenticatedFlow ||
        osbStep.isVINDeepLinked ||
        osbStep.isPcFlow ||
        osbStep.isGASkipped;
    if (!osbStep.isPcFlow) {
        usePageRefresh();
    }
    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.osbAlertMessagePopup(message, success);
    };

    const frameServiceData = (
        serviceType: string,
        serviceOption: string,
        service: any
    ) => {
        const serviceData: ServiceData = {
            id: service.id,
            serviceType: serviceType,
            serviceName: service.name,
            serviceCode: service.serviceId.toString(),
            priceAfterDiscount: service.priceAfterDiscount,
            price: service.price,
            type: service.type,
            subType: service.subType
                ? service.subType
                : service.additionalInfo.subType,
            serviceOption: serviceOption,
            serviceUniqueId: service.serviceUniqueId,
            brightCoveAccountId:
                service?.additionalInfo?.brightCoveAccountId || '',
            brightCoveVideoId: service?.additionalInfo?.brightCoveVideoId || '',
            brightCovePlayerId:
                service?.additionalInfo?.brightCovePlayerId || '',
            brightCoveVideoName:
                service?.additionalInfo?.brightCoveVideoName || '',
            convenienceOptionTypes: service?.convenienceOptionTypes || '',
        };
        return serviceData;
    };

    const addGAService = (dealerServices: DealerServices): boolean => {
        let isServiceSelectionChanged = false;
        if (dealerServices) {
            const generalAppointmentService =
                dealerServices.mainServices?.filter(item => {
                    if (
                        item.serviceId.toString() === SERVICE_FLOW.GA_SERVICE_ID
                    ) {
                        item.serviceUniqueId = item.serviceId + item.subType;
                        return item;
                    }
                }) || [];

            if (generalAppointmentService) {
                const selectedServiceList: ServiceData[] = [];
                generalAppointmentService.forEach((service: ServiceInfo) => {
                    const serviceData = frameServiceData(
                        OSB_SERVICE_TYPE.SERVICES,
                        SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE,
                        service
                    );
                    selectedServiceList.push(serviceData);
                });

                isServiceSelectionChanged = osbStep.isRetrieveFlow
                    ? isListSelectionChanged(
                          osbServiceStep.selectedServices,
                          selectedServiceList
                      )
                    : false;
                setOSBServiceStepPayload({
                    selectedServices: selectedServiceList,
                });
            }
        }

        return isServiceSelectionChanged;
    };
    const isServicesListChanged = (isServiceListChanged: boolean) => {
        if (isServiceListChanged) {
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, false);
            updateProgressBarStatus(
                STEP_PROGRESS_BAR_KEYS.DATETIME,
                false,
                true
            );
            history.push(
                buildNavigationUrl(
                    OsbPathCalenderStep(),
                    osbStep.UrlQueryParams
                )
            );
        } else {
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, false);
            history.push(
                buildNavigationUrl(
                    OsbPathBookingRetrieveSummaryStep(),
                    osbStep.UrlQueryParams
                )
            );
        }
    };

    const isDealerHasDeliveryServicesData = (
        results: any,
        selectedCardOrFlag: string
    ) => {
        let isDealerHasDeliveryServices = true;

        setOSBServiceStepPayload({
            dealerServiceInfo: results.value,
            voucherDesc:
                results?.value?.dealerServices?.serviceVouchers?.length > 0
                    ? results.value.dealerServices.serviceVouchers[0]
                          ?.description
                    : '',
        });
        if (Array.isArray(results.value.dealerServices?.campaignStates)) {
            setOSBCampaignStates({
                campaignStates: [
                    ...results.value.dealerServices.campaignStates,
                ],
            });
        }
        if (
            !osbStep.isRetrieveFlow &&
            selectedCardOrFlag !== SERVICE_FLOW.MOBILE_SERVICE_KEY
        ) {
            isDealerHasDeliveryServices = processDeliveryStepProgressbarDisplay(
                results.value,
                manageOsbStepVisibility
            );
            setOSBDealerStepPayload({
                isDealerHasDeliveryServices: isDealerHasDeliveryServices,
            });
        }

        if (
            selectedCardOrFlag &&
            selectedCardOrFlag === SERVICE_FLOW.GENERAL_APPOINTMENT_SERVICE_KEY
        ) {
            const isServiceListChanged = addGAService(
                results.value.dealerServices
            );
            if (osbStep.isRetrieveFlow) {
                isServicesListChanged(isServiceListChanged);
            } else {
                updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, true);
                if (!isDealerHasDeliveryServices) {
                    const service = getDefaultDeliveryOption(
                        results?.value?.dealerServices?.additionalServices
                    );
                    const defaultDeliveryService: ServiceData[] = [];
                    if (service != null) {
                        defaultDeliveryService.push(service);
                    }
                    setOSBLightDeliveryServiceStep({
                        selectedServices: defaultDeliveryService,
                    });

                    history.push(
                        buildNavigationUrl(
                            OsbPathCalenderStep(),
                            osbStep.UrlQueryParams
                        )
                    );
                } else {
                    history.push(
                        buildNavigationUrl(
                            OsbPathDeliveryStep(),
                            osbStep.UrlQueryParams
                        )
                    );
                }
            }
        } else if (
            typeof selectedCardOrFlag !== 'undefined' &&
            selectedCardOrFlag === SERVICE_FLOW.PRESELECTED_SERVICES
        ) {
            isSkipServiceSelectionStep = hasServicesPresentInDeeplinkedServices(
                SERVICE_FLOW.SPECIFIC_SERVICE_SCREEN,
                osbStep.preSelectedServices ?? '',
                results.value
            );
        } else {
            // reloading the route to reflect progressbar changes,
            history.push(
                buildNavigationUrl(
                    OsbPathServicesStep(),
                    osbStep.UrlQueryParams
                )
            );
            setShowSpecificServiceComponent(true);
        }
    };

    const getServicesForDealer = async (selectedCardOrFlag?: string) => {
        dispatch({ type: 'REQUEST' });
        let vehicleData: VehicleData;
        if (osbVehicleStep.vehicleDetails?.vin?.length > 0) {
            vehicleData = getVehicleData(osbVehicleStep);
        } else {
            vehicleData = {
                modelName: osbVehicleStep.defaultModelName,
                buildYear: osbVehicleStep.defaultBuildYear,
                mileage: osbVehicleStep.defaultMileage,
            };
        }
        if (vehicleData) {
            await ServiceHandler.OsbDealerServices.getServicesList({
                dealerCode: osbDealerStep.selectedDealerProfile.dealerCode,
                vehicleData: vehicleData,
                vouchers: osbServiceStep.voucherCode
                    ? [osbServiceStep.voucherCode]
                    : [],
                serviceDeliveryType:
                    selectedCardOrFlag == SERVICE_FLOW.MOBILE_SERVICE_KEY
                        ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                        : SERVICE_DELIVERY_TYPE.WORKSHOP,
                requestSourceType: osbStep.source,
                bookable: bookable,
                DSLAPIVersion: osbStep.DSLAPIVersion,
            })
                .then(async results => {
                    setError('');
                    dispatch({ type: 'RESPONSE' });
                    isDealerHasDeliveryServicesData(
                        results,
                        selectedCardOrFlag ?? ''
                    );
                })
                .catch((errorResponse: ErrorResponse) => {
                    if (errorResponse?.errorMessage) {
                        const errorMessage = errorResponse.errorMessage;
                        dispatch({ type: 'ERROR', error: errorMessage });
                        setError(errorMessage);
                    } else {
                        const errorMessage = osbStep.internalErrorMessage;
                        dispatch({ type: 'ERROR', error: errorMessage });
                        setError(errorMessage);
                    }
                });
        }
    };
    //filter Service Card out if it is not enabled - Using it for Mobile Service Card
    const filterServiceCards = (cardList: ServiceCard[], cardKey: string) => {
        const filteredCards: ServiceCard[] = [];
        cardList.forEach(card => {
            if (card.cardKey != cardKey) {
                filteredCards.push(card);
            }
        });
        return filteredCards;
    };

    const isCardPresent = (
        cardListCollection: ServiceCard[],
        cardKey: string
    ) => {
        for (const cardList of cardListCollection) {
            if (cardList.cardKey === cardKey) {
                return true;
            }
        }
        return false;
    };

    function applyCardFilters(
        cardList: ServiceCard[],
        enableMobileServiceForMarket: boolean
    ): ServiceCard[] {
        if (isSkipServiceSelectionStep) {
            // removing GA card, if it is an authenticated journey
            //(or) VIN deeplinked (or) preselected services deeplinked.
            cardList = filterServiceCards(
                cardList,
                SERVICE_FLOW.GENERAL_APPOINTMENT_SERVICE_KEY
            );
        }

        if (
            osbStep.isPcFlow ||
            !enableMobileServiceForMarket ||
            (!osbRetrieveBookingStep.isRetrieveBookingStep &&
                !osbDealerStep.hasMobileService) ||
            (osbRetrieveBookingStep.isRetrieveBookingStep &&
                !hasMobileService(
                    osbRetrieveBookingStep.retrieveBookingDetails.bookings[0]
                        .dealer?.vehicleDeliveryTypes
                ))
        ) {
            cardList = filterServiceCards(
                cardList,
                SERVICE_FLOW.MOBILE_SERVICE_KEY
            );
        }
        return cardList;
    }
    useEffect(() => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, false, true);
        const stepDetails = osbStepProgressBar.progressBarDetails.find(
            step =>
                step.key === STEP_PROGRESS_BAR_KEYS.SERVICE && step.isEditable
        );
        if (stepDetails || osbStep.isRetrieveFlow) {
            setRouteToPersist(
                OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
                OsbPathServicesStep(),
                osbStep.localStorageExpiry
            );
            if (osbStep.isGASkipped && !osbDealerStep.hasMobileService) {
                // will land directly to specific service page, if GA skipped (or) authoenticated flow (or) vin deeplinked
                // AND the dealer not supporting mobile service.
                getServicesForDealer();
            } else {
                dispatch({ type: 'REQUEST' });
                getServiceCardContent(serviceCardSection)
                    .then(async results => {
                        if (results) {
                            try {
                                setServiceCardSectionJson(results.elements);
                                // service order authored like - general-appointment,specific
                                //order array is used when click back button on specific service page
                                const landingPageCardsOrder = getObjectFromAEMJson(
                                    serviceLandingPageCardKey,
                                    results.elements
                                );
                                const serviceOrder = getObjectFromAEMJson(
                                    serviceCardOrder,
                                    results.elements
                                );
                                if (landingPageCardsOrder || serviceOrder) {
                                    const landindPageCardsOrderArr: string[] = landingPageCardsOrder?.split(
                                        ','
                                    );
                                    //order array is used when click back button on specific service page
                                    let landingPageCardsList = OsbUtilService.frameCardsList(
                                        landindPageCardsOrderArr,
                                        results.elements
                                    );
                                    if (!landingPageCardsList) {
                                        setShowLandingPageCards(false);
                                    }
                                    let cardList: ServiceCard[] = [];
                                    if (serviceOrder) {
                                        const serviceOrderArr: string[] = serviceOrder?.split(
                                            ','
                                        );

                                        //order array is used when click back button on specific service page

                                        cardList = OsbUtilService.frameCardsList(
                                            serviceOrderArr,
                                            results.elements
                                        );
                                        setServiceOrderArray(cardList);
                                        cardList = applyCardFilters(
                                            cardList,
                                            osbStep.enableMobileService
                                        );
                                        if (cardList) {
                                            setServiceCardJson(cardList);
                                        }
                                    }

                                    let isResponseFormed = true;
                                    if (landingPageCardsList || cardList) {
                                        if (
                                            !isSkipServiceSelectionStep &&
                                            osbStep.preSelectedServices &&
                                            osbStep.preSelectedServices
                                                ?.length > 0
                                        ) {
                                            await getServicesForDealer(
                                                SERVICE_FLOW.PRESELECTED_SERVICES
                                            );
                                        }
                                        landingPageCardsList = applyCardFilters(
                                            landingPageCardsList,
                                            osbStep.enableMobileService
                                        );
                                        setLandingPageServiceCardJson(
                                            landingPageCardsList
                                        );
                                        setIsMobileServiceCardAuthored(
                                            isCardPresent(
                                                landingPageCardsList,
                                                SERVICE_FLOW.MOBILE_SERVICE_KEY
                                            ) ||
                                                (!landingPageCardsList &&
                                                    !isCardPresent(
                                                        cardList,
                                                        SERVICE_FLOW.MOBILE_SERVICE_KEY
                                                    ))
                                        );
                                        if (
                                            isSkipServiceSelectionStep &&
                                            (!isCardPresent(
                                                landingPageCardsList,
                                                SERVICE_FLOW.MOBILE_SERVICE_KEY
                                            ) ||
                                                (!landingPageCardsList &&
                                                    !isCardPresent(
                                                        cardList,
                                                        SERVICE_FLOW.MOBILE_SERVICE_KEY
                                                    )))
                                        ) {
                                            if (
                                                (osbStep.isAuthenticatedFlow &&
                                                    osbStep.isVINDeepLinked &&
                                                    osbStep.isMileageDeepLinked) ||
                                                (osbStep.isVINDeepLinked &&
                                                    osbStep.isMileageDeepLinked)
                                            ) {
                                                setShowSpecificServiceComponent(
                                                    true
                                                );
                                            } else {
                                                isResponseFormed = false;
                                                getServicesForDealer();
                                            }
                                        }
                                        if (landingPageCardsList.length <= 1) {
                                            setLandingPageServiceCardJson(
                                                landingPageCardsList
                                            );
                                            setShowLandingPageCards(false);
                                        }
                                    }
                                    if (isResponseFormed) {
                                        dispatch({ type: 'RESPONSE' });
                                    }
                                }
                            } catch (statementError) {
                                console.log(statementError);
                            }
                        }
                    })
                    .catch((aemCfError: any) => {
                        dispatch({ type: 'ERROR', error: aemCfError?.message });
                    });
            }
            if (serverSideService.isClientSide()) {
                window.scrollTo({
                    top: getGoMainHeaderHeight() - 2,
                    behavior: 'smooth',
                });
            }
        }
    }, []);

    useEffect(() => {
        if (isRetrieveStep) {
            triggerSelectServiceLoadAnalytics(
                osbDealerStep,
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
            );
        } else {
            triggerSelectServiceLoadAnalytics(
                osbDealerStep,
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT
            );
        }
    }, []);

    const handleSpecificServiceBackClick = () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.BACK_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        invalidateAuthentication();
        if (osbStep.isGASkipped) {
            if (
                !isMobileServiceCardAuthored &&
                landingPageServiceCardJson &&
                landingPageServiceCardJson.length <= 1
            ) {
                updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, false);
                history.push(
                    buildNavigationUrl(
                        OsbPathDealerStep(),
                        osbStep.UrlQueryParams
                    )
                );
            } else {
                setShowLandingPageCards(true);
                setShowSpecificServiceComponent(false);
            }
        } else {
            const generalApptIndex = serviceOrderArray?.findIndex(
                service => service?.cardKey === GENERAL_APPOINTMENT
            );
            const generalAppointmentCard: ServiceCard =
                serviceOrderArray?.[generalApptIndex || 0];

            // Add GA card back if its not present and configured in the aem serviceOrderArray field
            if (
                !isCardPresent(
                    serviceCardJson || [],
                    SERVICE_FLOW.GENERAL_APPOINTMENT_SERVICE_KEY
                ) &&
                generalApptIndex != -1
            ) {
                serviceCardJson?.splice(
                    generalApptIndex || 0,
                    0,
                    generalAppointmentCard
                    //gaCard
                );
            }
            setShowSpecificServiceComponent(false);
        }
    };

    const removeGAServiceIfSelected = (selectedServices: ServiceData[]) => {
        if (selectedServices?.length > 0) {
            const gaServiceAvailable = selectedServices?.filter(
                item => item.serviceCode === SERVICE_FLOW.GA_SERVICE_ID
            );
            if (gaServiceAvailable) {
                const selectedServicesWithoutGA = selectedServices?.filter(
                    item => item.serviceCode !== SERVICE_FLOW.GA_SERVICE_ID
                );
                setOSBServiceStepPayload({
                    selectedServices: selectedServicesWithoutGA,
                });
            }
        }
    };

    const handleDealerServiceKey = async () => {
        if (isSkipServiceSelectionStep) {
            getServicesForDealer();
        }
        setOSBServiceStepPayload({
            isMobileServiceSelected: false,
        });
        setShowLandingPageCards(false);
    };

    const handleSpecificServiceKey = async () => {
        getServicesForDealer(SERVICE_FLOW.SPECIFIC_SERVICE_KEY);
        removeGAServiceIfSelected(osbServiceStep.selectedServices);
        if (osbServiceStep.isMobileServiceSelected) {
            setOSBServiceStepPayload({
                isMobileServiceSelected: false,
            });
        }
        manageOsbStepVisibility(
            STEP_PROGRESS_BAR_KEYS.DELIVERY,
            true,
            STEP_PROGRESS_BAR_KEYS.SERVICE,
            STEP_PROGRESS_BAR_KEYS.LOCATION
        );
    };
    const handleMobileServiceKey = async () => {
        getServicesForDealer(SERVICE_FLOW.MOBILE_SERVICE_KEY);
        setOSBServiceStepPayload({
            isMobileServiceSelected: true,
        });

        manageOsbStepVisibility(
            STEP_PROGRESS_BAR_KEYS.LOCATION,
            true,
            STEP_PROGRESS_BAR_KEYS.SERVICE,
            STEP_PROGRESS_BAR_KEYS.DELIVERY
        );
        if (osbLightDeliveryServiceStep.selectedServices?.length > 0) {
            resetLightDeliveryServiceStep();
        }
        removeGAServiceIfSelected(osbServiceStep.selectedServices);
    };
    const getServiceCardSelection = async (cardType: string) => {
        getServicesForDealer(cardType);
        if (osbStep.preSelectedServices) {
            //On selecteing GA card, remove the preselected services from deeplink string and update the same in osbStep.preSelectedServices
            const updatedPreSelectedServices = updateDeeplinkedPreSelectedServices(
                osbStep.preSelectedServices,
                osbServiceStep.selectedServices
            );
            setOSBStepPayload({
                preSelectedServices: updatedPreSelectedServices,
            });
        }
        if (osbServiceStep.isMobileServiceSelected) {
            setOSBServiceStepPayload({
                isMobileServiceSelected: false,
            });
            resetServiceLocationStep();
        }
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
        manageOsbStepVisibility(
            STEP_PROGRESS_BAR_KEYS.DELIVERY,
            true,
            STEP_PROGRESS_BAR_KEYS.SERVICE,
            STEP_PROGRESS_BAR_KEYS.LOCATION
        );
    };

    const osbCardClickHandler = async (service: ServiceCard) => {
        const cardType = service.cardKey;
        triggerSelectServiceCardAnalytics(
            osbDealerStep,
            osbVehicleStep,
            osbStep,
            fireEvents,
            cardType
        );
        switch (cardType) {
            case SERVICE_FLOW.DEALER_SERVICE_KEY:
                await handleDealerServiceKey();
                break;
            case SERVICE_FLOW.SPECIFIC_SERVICE_KEY:
                await handleSpecificServiceKey();
                break;
            case SERVICE_FLOW.MOBILE_SERVICE_KEY:
                await handleMobileServiceKey();
                break;
            default:
                getServiceCardSelection(cardType);
                break;
        }
    };

    const goToPreviousStep = () => {
        if (
            (!showLandingPageCards &&
                landingPageServiceCardJson &&
                landingPageServiceCardJson.length <= 1) ||
            (showLandingPageCards &&
                landingPageServiceCardJson &&
                landingPageServiceCardJson.length >= 1)
        ) {
            triggerBookServiceGlobalCTAOnClickAnalytics(
                LIGHT_JOURNEY_ANALYTICS.BACK_BTN_CTA_NAME,
                fireEvents,
                osbVehicleStep
            );
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, false);
            invalidateAuthentication();
            history.push(
                buildNavigationUrl(OsbPathDealerStep(), osbStep.UrlQueryParams)
            );
        } else {
            setShowLandingPageCards(true);
        }
    };
    const handleSummaryRefresh = () => {
        setIsServiceRefreshed(!isServiceRefreshed);
    };

    return (
        <>
            {httpState.isLoading ? (
                <OsbLoader osbEmptyContentHeight={true} />
            ) : (
                <>
                    <div className="light-journey-servicesflow-page">
                        <div className="service-card-list-container">
                            {errorDiv}
                            {!showSpecificServiceComponent ? (
                                <>
                                    {(osbStep.isWebViewJourney ||
                                        !isMobileView) &&
                                        !osbStep.isDealerDeepLinked &&
                                        !osbStep.isRetrieveFlow && (
                                            <OSBSecondaryButton
                                                dataTestId="BackToDealerDetailPage"
                                                type="svg"
                                                direction="left"
                                                text={getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.BACK_BUTTON_LABEL,
                                                    serviceCardSectionJson
                                                )}
                                                onClickHandler={
                                                    goToPreviousStep
                                                }
                                            />
                                        )}
                                    {showLandingPageCards ? (
                                        <div className="service-cards">
                                            {landingPageServiceCardJson?.map(
                                                service => {
                                                    return (
                                                        <OSBCard
                                                            key={
                                                                service.cardKey
                                                            }
                                                            title={
                                                                service.cardTitle
                                                            }
                                                            description={
                                                                service.cardDesc
                                                            }
                                                            ctaLabel={
                                                                service.ctaLabel
                                                            }
                                                            onClick={() =>
                                                                osbCardClickHandler(
                                                                    service
                                                                )
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    ) : (
                                        <div className="service-cards">
                                            {serviceCardJson?.map(service => {
                                                return (
                                                    <OSBCard
                                                        key={service.cardKey}
                                                        title={
                                                            service.cardTitle
                                                        }
                                                        description={
                                                            service.cardDesc
                                                        }
                                                        ctaLabel={
                                                            service.ctaLabel
                                                        }
                                                        onClick={() =>
                                                            osbCardClickHandler(
                                                                service
                                                            )
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <LightSpecificService
                                    handleSpecificServiceBackClick={
                                        handleSpecificServiceBackClick
                                    }
                                    isMobileServiceCardAuthored={
                                        isMobileServiceCardAuthored
                                    }
                                    isRetrieveFlow={osbStep.isRetrieveFlow}
                                    refreshBookingSummary={handleSummaryRefresh}
                                    addDefaultService={addGAService}
                                    osbServiceStep={osbServiceStep}
                                    setOSBServiceStepPayload={
                                        setOSBServiceStepPayload
                                    }
                                    isServiceRefreshed={isServiceRefreshed}
                                    removeGAServiceIfSelected={
                                        removeGAServiceIfSelected
                                    }
                                    osbImageUploadStep={osbImageUploadStep}
                                    setOSBImageServiceStepPayload={
                                        setOSBImageServiceStepPayload
                                    }
                                    osbAlertMessagePopup={
                                        props.osbAlertMessagePopup
                                    }
                                />
                            )}
                        </div>
                        {!isMobileView && (
                            <div className="osb-light-journey-booking-summary">
                                <LightBookingSummary
                                    stepName={STEP_PROGRESS_BAR_KEYS.SERVICE}
                                    refreshBookingSummary={handleSummaryRefresh}
                                    showVoucherLink={
                                        showSpecificServiceComponent
                                    }
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {isMobileView && (
                        <div className="osb-mobile-booking-summary">
                            <div className="view-summary-accordion">
                                <BookingSummaryAccordion
                                    index="5"
                                    className=""
                                    expandMultiplePanels={true}
                                    refreshBookingSummary={handleSummaryRefresh}
                                    stepName={STEP_PROGRESS_BAR_KEYS.SERVICE}
                                    showVoucherLink={
                                        showSpecificServiceComponent
                                    }
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
