import React, { useEffect, useState, useContext } from 'react';
import { TireFinder } from '../../components/sections/tirefinder/tirefinder';
import './tirefinder-view.scss';
import { TireFinderDetails } from '../../components/sections/tirefinder/components/tirefinder-details/tirefinder-banner-details';
import { FeatureTrio } from '../../components/sections/vehicle-portal/components/feature-trio/feature-trio';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useLiveChatHelpCardsContent } from '../../components/sections/tirefinder/hooks/use-livechat-helpcard';
import { LiveChatSearchBar } from '../../components/sections/search-bar/live-chat-search-bar';
import { TireFinderBanner } from '../../components/sections/tirefinder/components/tirefinder-banner/tirefinder-banner';
import { useExperienceContent } from '../../hooks/use-server-content';
import useSeoConfig from '../../hooks/use-seo-config';
import { SearchBar } from '../../components/sections/search-bar/search-bar';
import ServerContext from '../../contexts/serverContext';
import { useHelpCardsContent } from '../../views/page-not-found-view/hooks/use-help-cards-content';
import {
    FeatureCardsFragment,
    FeatureCardsService,
} from '../../components/sections/vehicle-portal/services/feature-cards/feature-card-article-service';
import { useTireFinderContent } from '../../components/sections/tirefinder/hooks/use-tirefinder-banner-details';
import {
    SearchResults,
    ViewTemplate,
    Ecommerce,
} from '../../components/sections';
import { Route, Switch } from 'react-router-dom';
import { useTireFinderCaption } from '../../components/sections/tirefinder/hooks/use-tire-caption';
import AppConfigurationService from '../../services/app-configuration-service/app-configuration-service';
import { BreadcrumbWrapper } from '../../components/common/breadcrumb-wrapper/breadcrumb-wrapper';
import { TireFinderBreadCrumb } from './tirefinder-breadcrumb';
import AuthenticationService from '../../services/authentication-service/authentication-service';

export const TireFinderView = () => {
    const TireFinderContent = useTireFinderContent();
    const searchContent = useSearchContent();
    const liveHelpCardsContent = useLiveChatHelpCardsContent();
    const helpCardsContent = useHelpCardsContent();
    const tireFinderCaption = useTireFinderCaption();
    const [content, setContent] = useState<FeatureCardsFragment | null>(null);
    const { brand } = new AppConfigurationService();
    const [brands, setBrands] = useState(true);
    const [authenticatedState, setAuthenticatedUser] = useState('');
    const authenticationService = new AuthenticationService();
    const [experienceContent] = useExperienceContent<FeatureCardsFragment>(
        'tirefinder',
        `Tire-Finder`,
        'featuretrio'
    );
    const page = 'Tire Finder';
    const serverContext = useContext(ServerContext);
    const isCanada = serverContext?.currentRegionCode === 'ca';
    useSeoConfig('tire-finder', 'tire-finder');
    useEffect(() => {
        if (experienceContent) {
            const contents = new FeatureCardsService().getFeatureCards(
                experienceContent
            );
            contents?.cards.forEach(result => {
                result.cardImageAriaHidden = true;
            });
            setContent(contents);
        }
        if (brand === 'lincoln') {
            setBrands(false);
        }
    }, [experienceContent, brand]);

    const scrollPageToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    useEffect(() => {
        scrollPageToTop();
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setAuthenticatedUser('loggedIn');
                } else {
                    setAuthenticatedUser('notLoggedIn');
                }
            });
    }, []);

    return (
        <ViewTemplate page={page} hideSearchBar={true} hideLinksList={true}>
            {TireFinderContent && (
                <BreadcrumbWrapper
                    currentPage={TireFinderContent.bannerTitle}
                    hashId="support"
                    title={
                        <TireFinderBreadCrumb
                            breadcrumbUrl={TireFinderContent.breadcrumbUrl}
                            breadcrumbTitle={TireFinderContent.breadcrumbTitle}
                        />
                    }
                />
            )}
            <div className="tirefinder">
                {TireFinderContent && (
                    <div>
                        <TireFinderBanner
                            topComponent={<TireFinderDetails />}
                            backgroundImageDesktop={
                                process.env.REACT_APP_AEM_BASE_URL +
                                TireFinderContent.caoBackgroundImageDesktop
                            }
                            backgroundImageMobile={
                                process.env.REACT_APP_AEM_BASE_URL +
                                TireFinderContent.caoBackgroundImageMobile
                            }
                        />
                    </div>
                )}
                <Switch>
                    <Route
                        path="/service-maintenance/tire-finder/"
                        exact
                        component={() => (
                            <TireFinder
                                authenticatedState={authenticatedState}
                            />
                        )}
                    />
                    <Route
                        path="/service-maintenance/tire-finder/search-results.html"
                        component={SearchResults}
                    />
                    <Route
                        path="/service-maintenance/tire-finder/ecommerce.html"
                        component={Ecommerce}
                    />
                </Switch>
                <div className="feature-trios">
                    {content && <FeatureTrio {...content} />}
                </div>
                {tireFinderCaption && content && (
                    <p className="dealer-caption">
                        {tireFinderCaption.tireFinderCaption}
                    </p>
                )}

                {!isCanada && brands && searchContent && helpCardsContent && (
                    <LiveChatSearchBar
                        searchContent={searchContent}
                        helpCardsContent={liveHelpCardsContent}
                    />
                )}

                {isCanada && searchContent && helpCardsContent && (
                    <SearchBar
                        searchContent={searchContent}
                        helpCardsContent={helpCardsContent}
                    />
                )}
            </div>
        </ViewTemplate>
    );
};
export default TireFinderView;
