import React, { ReactNode } from 'react';
import { DisclaimerTooltip } from '../../components/common/disclaimer-tooltip/disclaimer-tooltip';
import {
    AemArticle,
    Article,
} from '../../models/experiencefragments/category-view';
import parse from 'html-react-parser';
import {
    StringReplacer,
    StringReplacerRule,
} from '../../components/utils/string-replacer/string-replacer';
import { FMBannersProps } from '../../components/sections/fm-vehicle-portal/hook/use-fm-banners';
import { FMFiftyFiftyProps } from '../../components/sections/fm-vehicle-portal/hook/use-fm-fifty-fifty';

export interface DisclaimerContent {
    symbol: string;
    content: string;
}

export interface ProcessedField {
    plain: string;
    node: ReactNode;
}

export class DisclaimersService {
    public static processDisclaimerShortcodes(
        content: string,
        disclaimer: string,
        symbol: string,
        additionalReplacementRules?: StringReplacerRule[]
    ) {
        return (
            <StringReplacer
                rules={[
                    {
                        replace: '[disclaimer]',
                        replaceWith: disclaimer ? (
                            <DisclaimerTooltip
                                symbol={symbol}
                                disclaimer={disclaimer}
                            ></DisclaimerTooltip>
                        ) : (
                            ''
                        ),
                    },
                    ...(additionalReplacementRules || []),
                ]}
            >
                {parse(content)}
            </StringReplacer>
        );
    }

    public static processFieldsWithDisclaimers<T, K extends keyof T>(
        fieldsToProcess: K[],
        disclaimerField: K,
        existingDisclaimers: DisclaimerContent[],
        item: T,
        additionalReplacementRules?: StringReplacerRule[]
    ): [(ProcessedField | null)[], DisclaimerContent[]] {
        const disclaimer = (item as any)[disclaimerField];
        const processedFields: (ProcessedField | null)[] = [];
        const newDisclaimers: DisclaimerContent[] = [];

        fieldsToProcess.forEach(fieldName => {
            const field = (item as any)[fieldName] as string;
            if (field) {
                const plain = ['[disclaimer]']
                    .concat(
                        additionalReplacementRules?.map(rule => rule.replace) ||
                            []
                    )
                    .reduce((result, current) => {
                        return result.split(current).join('');
                    }, field);
                if (disclaimer) {
                    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
                    let symbol;

                    const matchingDisclaimer = existingDisclaimers
                        .concat(newDisclaimers)
                        .filter(
                            existingDisclaimer =>
                                existingDisclaimer.content === disclaimer
                        )[0];

                    if (matchingDisclaimer) {
                        symbol = matchingDisclaimer.symbol;
                    } else {
                        symbol = alphabet[existingDisclaimers.length];
                        newDisclaimers.push({
                            symbol: symbol,
                            content: disclaimer,
                        });
                    }
                    processedFields.push({
                        plain: plain,
                        node: DisclaimersService.processDisclaimerShortcodes(
                            field,
                            disclaimer,
                            symbol,
                            additionalReplacementRules
                        ),
                    });
                } else {
                    processedFields.push({
                        plain: plain,
                        node: (
                            <StringReplacer
                                rules={[...(additionalReplacementRules || [])]}
                            >
                                {parse(field)}
                            </StringReplacer>
                        ),
                    });
                }
            } else {
                processedFields.push(null);
            }
        });
        return [processedFields, newDisclaimers];
    }

    public static processItemWithDisclaimers<T, R, K extends keyof T>(
        fieldsToProcess: K[],
        disclaimerField: K,
        existingDisclaimers: DisclaimerContent[],
        item: T,
        transform: (item: T, processedFields: (ProcessedField | null)[]) => R
    ): [R, DisclaimerContent[]] {
        const [
            processedFields,
            newDisclaimers,
        ] = this.processFieldsWithDisclaimers(
            fieldsToProcess,
            disclaimerField,
            existingDisclaimers,
            item
        );
        return [transform(item, processedFields), newDisclaimers];
    }

    public static processItemsWithDisclaimers<T, R, K extends keyof T>(
        fieldsToProcess: K[],
        disclaimerField: K,
        existingDisclaimers: DisclaimerContent[],
        items: T[],
        transform: (item: T, processedFields: (ProcessedField | null)[]) => R
    ): [R[], DisclaimerContent[]] {
        const processedItems: R[] = [];
        let newDisclaimers: DisclaimerContent[] = [];
        items &&
            items.forEach(item => {
                const [
                    processedItem,
                    processedDisclaimers,
                ] = DisclaimersService.processItemWithDisclaimers(
                    fieldsToProcess,
                    disclaimerField,
                    existingDisclaimers.concat(newDisclaimers),
                    item,
                    transform
                );
                processedItems.push(processedItem);
                newDisclaimers = newDisclaimers.concat(processedDisclaimers);
            });
        return [processedItems, newDisclaimers];
    }

    public static processArticle(
        aemArticle: AemArticle,
        exisingDisclaimers: DisclaimerContent[]
    ): [Article, DisclaimerContent[]] {
        const [
            processedArticle,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['title', 'headline', 'description'],
            'disclaimer',
            exisingDisclaimers,
            aemArticle,
            (article, fields) => {
                return {
                    ...article,
                    title: fields[0]?.node,
                    plainTitle: fields[0]?.plain,
                    headline: fields[1]?.node,
                    plainHeadline: fields[1]?.plain,
                    description: fields[2]?.node,
                    plainDescription: fields[2]?.plain,
                };
            }
        );
        return [processedArticle, processedDisclaimers];
    }

    public static processArticles(
        aemArticles: AemArticle[],
        exisingDisclaimers: DisclaimerContent[]
    ): [Article[], DisclaimerContent[]] {
        const [
            processedArticle,
            processedDisclaimers,
        ] = DisclaimersService.processItemsWithDisclaimers(
            ['title', 'headline', 'description'],
            'disclaimer',
            exisingDisclaimers,
            aemArticles,
            (article, fields) => {
                return {
                    ...article,
                    title: fields[0]?.node,
                    plainTitle: fields[0]?.plain,
                    headline: fields[1]?.node,
                    plainHeadline: fields[1]?.plain,
                    description: fields[2]?.node,
                    plainDescription: fields[2]?.plain,
                };
            }
        );
        return [processedArticle, processedDisclaimers];
    }
    public static processFMBanners(
        fmBanner: FMBannersProps,
        exisingDisclaimers: DisclaimerContent[]
    ): [FMBannersProps, DisclaimerContent[]] {
        const [
            processedFMBanner,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['title', 'description'],
            'disclaimer',
            exisingDisclaimers,
            fmBanner,
            (fmBanners, fields) => {
                return {
                    ...fmBanners,
                    title: fields[0]?.node,
                    description: fields[1]?.node,
                };
            }
        );

        return [processedFMBanner, processedDisclaimers];
    }
    public static processFMFiftyFifty(
        fmFiftyFifty: FMFiftyFiftyProps,
        exisingDisclaimers: DisclaimerContent[]
    ): [FMFiftyFiftyProps, DisclaimerContent[]] {
        const [
            processedFMFiftyFiftyRight,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['title', 'description'],
            'disclaimer',
            exisingDisclaimers,
            fmFiftyFifty,
            (fmFiftyFifty, fields) => {
                return {
                    ...fmFiftyFifty,
                    title: fields[0]?.node,
                    description: fields[1]?.node,
                };
            }
        );
        return [processedFMFiftyFiftyRight, processedDisclaimers];
    }
}
