import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import {
    DEALER_STEP_KEYS,
    STEP_PROGRESS_BAR_KEYS,
} from '../../../osb-constant';
import {
    buildNavigationUrl,
    getObjectFromAEMJson,
    hasMobileService,
} from '../../../osb-utils';
import parse from 'html-react-parser';
import {
    getInitialDealerState,
    useDealerStep,
    useOSBStep,
    useStepProgressBar,
    useVehicleStep,
    useViewport,
} from '../../../../../../hooks/owners-osb/';
import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import AuthenticationService from '../../../../../../services/authentication-service/authentication-service';
import { useEffect, useReducer, useState } from 'react';
import { DealerSearch } from '../light-dealer-search/dealer-search';
import { OSBUseLocation } from '../../../common/osb-use-location/osb-use-location';
import './dealer-landing-page-lincoln.scss';
import { Button } from '@own/fds-react';
import DealerSearchService from '../../../../../../services/osb-service/google-map-service/dealer-search-service';
import { dealerStepReducer } from '../../../../../../reducers/osb-reducers';
import { useHistory } from 'react-router-dom';
import { OsbPathServicesStep } from '../../../osb-controller';
import { OsbLoader } from '../../../../../common/osb-loader/osb-loader';
import { triggerSelectPreferredDealerAnalytics } from '../../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../../hooks/use-analytics';

interface Props {
    errorMsgDealer: string;
    errorMsgDealerDetails: string;
    fetchSelectedLocation: (location: string, searchType?: string) => void;
    dealerServiceLoading: boolean;
    cfLoading: boolean;
    locationErrorMsgDetails: string;
    showCurrentLocation: () => void;
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    dealerStepSpecialServicesContent: JsonFromAEMResponse[];
    preferredDealersProfile?: DealerProfileInfo[];
}
export const DealerLandingPage = (props: Props) => {
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const authService = new AuthenticationService();
    const { isMobileView } = useViewport();
    const { osbDealerStep, setOSBDealerStep } = useDealerStep();
    const [dealerStep] = useReducer(dealerStepReducer, getInitialDealerState());
    const [fireEvents] = useAnalytics();
    const { osbVehicleStep } = useVehicleStep();
    const history = useHistory();
    const [selectedDealerCode, setSelectedDealerCode] = useState<string>('');

    const { updateProgressBarStatus } = useStepProgressBar();
    const numPreferredDealers: number =
        props.preferredDealersProfile?.length || 0;
    const handleOptionChange = (e: any, dealer: any) => {
        setSelectedDealerCode(dealer.dealerCode);
    };

    const handleSignInClick = () => {
        authService.login();
    };
    const getSpecialServicesInfo = (dealer: any) => {
        const specialServicesList = getObjectFromAEMJson(
            DEALER_STEP_KEYS.SPECIAL_SERVICES_LIST,
            props.dealerStepSpecialServicesContent
        ).split(',');

        return specialServicesList.reduce(
            (services: any[], serviceName: string) => {
                if (dealer[serviceName]) {
                    services.push(
                        getObjectFromAEMJson(
                            `${serviceName
                                .charAt(0)
                                .toLowerCase()}${serviceName.slice(1)}`,
                            props.dealerStepSpecialServicesContent
                        )
                    );
                }
                return services;
            },
            []
        );
    };
    function onSearchDealerByNameSuccess(bingDealer: any) {
        const {
            DealerID,
            PostCode,
            AddressLine1,
            Locality,
            openingHours,
            CustomerRatingsService,
            CustomerRespondentsService,
            distance,
            PrimaryPhone,
            location,
            VideoCheck,
            MobileService,
            delivery,
            BEVService,
        } = bingDealer[0];
        const dealerId = DealerID.substr(
            osbStep.numberOfLeadingCharactersToStrip
        );
        const matchingDealer = props.preferredDealersProfile?.find(
            profile => profile.dealerCode === dealerId
        );
        const dealerList: DealerProfileInfo[] = [];
        if (matchingDealer) {
            const dealerProfile: DealerProfileInfo = Object.assign({
                dealerCode: dealerId,
                dealerName: matchingDealer.dealerName,
                postalCode: PostCode,
                street: AddressLine1,
                town: Locality,
                openingHours,
                customerRating: CustomerRatingsService,
                customerRespondentsService: CustomerRespondentsService,
                distance,
                primaryPhone: PrimaryPhone,
                location,
                didVideoCheck: Boolean(VideoCheck),
                didMobileService: Boolean(MobileService),
                delivery,
                dealerType: matchingDealer.dealerType,
                additionalInfo: {
                    earliestAvailableTimeslot:
                        matchingDealer.additionalInfo
                            ?.earliestAvailableTimeslot,
                    dealerFilterServiceAvailability:
                        matchingDealer.additionalInfo
                            ?.dealerFilterServiceAvailability,
                },
                specialServices: getSpecialServicesInfo(bingDealer),
                serviceDepartmentCV:
                    bingDealer.HasServiceDepartmentCV ||
                    bingDealer.TransitService,
                serviceDepartmentPV: bingDealer.HasServiceDepartmentPV,
                electricService: BEVService,
            });
            dealerList.push(dealerProfile);
            Object.assign(dealerStep, {
                selectedDealerProfile: dealerProfile,
                hasMobileService: hasMobileService(
                    dealerProfile.delivery || []
                ),
                selectedDealerName: dealerProfile.dealerName?.trim() || '',
                dealerProfiles: dealerList,
                filteredDealerProfiles: dealerList,
            });
            setOSBDealerStep(dealerStep, false);
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DEALER, true);
            history.push(
                buildNavigationUrl(
                    OsbPathServicesStep(),
                    osbStep.UrlQueryParams
                )
            );
        }
    }
    const populatePreferredDealerDetailsFromBing = (
        selectedDealerCode: string
    ) => {
        setOSBStepPayload({
            preferredDealerJourney: true,
        });
        DealerSearchService.bingDealerByName(
            1,
            (dealer: any) => {
                onSearchDealerByNameSuccess(dealer);
            },
            osbStep.polygon,
            selectedDealerCode,
            osbStep.osbTwoLetterCountryCode
        );
        triggerSelectPreferredDealerAnalytics(osbVehicleStep, fireEvents);
    };

    useEffect(() => {
        if (
            osbStep.preferredDealerJourney &&
            osbDealerStep?.selectedDealerProfile?.dealerCode
        ) {
            const matchingDealer = props.preferredDealersProfile?.find(
                dealer =>
                    dealer.dealerCode ===
                    osbDealerStep.selectedDealerProfile.dealerCode
            );
            if (matchingDealer) {
                setSelectedDealerCode(matchingDealer.dealerCode);
            }
        }
    }, []);

    return (
        <>
            {props.dealerServiceLoading || props.cfLoading ? (
                <OsbLoader />
            ) : (
                <div className="dealer-landing-screen">
                    <div className="new-light-dealer-step-wrapper">
                        <div className="light-journey-title">
                            {parse(osbStep.serviceBookingTitle)}
                        </div>
                    </div>

                    <div className="new-light-dealer-flow-container-wrapper">
                        {osbStep.isAuthenticatedFlow &&
                            props.preferredDealersProfile !== undefined &&
                            numPreferredDealers >= 1 && (
                                <div className="preferred-dealer-container">
                                    <div className="preferred-dealer-title">
                                        {getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.PREFERRED_DEALER_TITLE,
                                            props.dealerStepMoreContent
                                        )}
                                    </div>
                                    {numPreferredDealers === 1 ? (
                                        <>
                                            <div className="single-preferred-dealer">
                                                {
                                                    props
                                                        .preferredDealersProfile[0]
                                                        .dealerName
                                                }
                                            </div>
                                            <div className="continue-btn">
                                                <Button
                                                    href=""
                                                    label={getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.CONTINUE_BUTTON_LABEL,
                                                        props.dealerStepMoreContent
                                                    )}
                                                    noShadow
                                                    type="standard"
                                                    onClick={() =>
                                                        populatePreferredDealerDetailsFromBing(
                                                            props.preferredDealersProfile
                                                                ? props
                                                                      .preferredDealersProfile[0]
                                                                      .dealerCode
                                                                : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        numPreferredDealers > 1 && (
                                            <>
                                                <div className="multi-preferred-dealer">
                                                    <div className="radio-buttons">
                                                        {props.preferredDealersProfile.map(
                                                            dealer => (
                                                                <label
                                                                    key={
                                                                        dealer.dealerCode
                                                                    }
                                                                    className="radio-button-container"
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        className="radio-style"
                                                                        name={
                                                                            dealer.dealerName
                                                                        }
                                                                        value={
                                                                            dealer.dealerCode
                                                                        }
                                                                        checked={
                                                                            selectedDealerCode ===
                                                                            dealer.dealerCode
                                                                        }
                                                                        onChange={(
                                                                            e: any
                                                                        ) =>
                                                                            handleOptionChange(
                                                                                e,
                                                                                dealer
                                                                            )
                                                                        }
                                                                    />
                                                                    {
                                                                        dealer.dealerName
                                                                    }
                                                                </label>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="continue-btn">
                                                    <Button
                                                        href=""
                                                        label={getObjectFromAEMJson(
                                                            DEALER_STEP_KEYS.CONTINUE_BUTTON_LABEL,
                                                            props.dealerStepMoreContent
                                                        )}
                                                        noShadow
                                                        type="standard"
                                                        onClick={() =>
                                                            populatePreferredDealerDetailsFromBing(
                                                                selectedDealerCode
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                            )}

                        {osbStep.isAuthenticatedFlow &&
                            numPreferredDealers !== 0 && (
                                <span className="vertical-line">
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.OR_LABEL,
                                        props.dealerStepMoreContent
                                    )}
                                </span>
                            )}

                        <div
                            className={`light-dealer-search-container ${
                                numPreferredDealers === 0
                                    ? 'no-preferred-dealer'
                                    : ''
                            }`}
                        >
                            <div
                                className={`${
                                    !isMobileView &&
                                    props.preferredDealersProfile !==
                                        undefined &&
                                    numPreferredDealers > 0
                                        ? 'search-float-right'
                                        : ''
                                }`}
                            >
                                <DealerSearch
                                    errorMsgDealer={props.errorMsgDealer}
                                    errorMsgDealerDetails={
                                        props.errorMsgDealerDetails
                                    }
                                    fetchSelectedLocation={
                                        props.fetchSelectedLocation
                                    }
                                    locationErrorMsgDetails={
                                        props.locationErrorMsgDetails
                                    }
                                    showCurrentLocation={
                                        props.showCurrentLocation
                                    }
                                    dealerStepContent={props.dealerStepContent}
                                    dealerStepMoreContent={
                                        props.dealerStepMoreContent
                                    }
                                />
                                <OSBUseLocation
                                    showCurrentLocation={
                                        props.showCurrentLocation
                                    }
                                    useLocationLabel={getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.USE_MY_LOCATION_LABEL,
                                        props.dealerStepMoreContent
                                    )}
                                />
                            </div>
                        </div>

                        {!osbStep.isAuthenticatedFlow && (
                            <span className="vertical-line">
                                {getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.OR_LABEL,
                                    props.dealerStepMoreContent
                                )}
                            </span>
                        )}

                        {!osbStep.isAuthenticatedFlow && (
                            <div className="light-dealer-login-container">
                                <div className="delaer-login-section">
                                    <div className="login-btn">
                                        <Button
                                            href=""
                                            label={getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.LOGIN_OR_REGISTER_LABEL,
                                                props.dealerStepMoreContent
                                            )}
                                            noShadow
                                            type="standard"
                                            onClick={handleSignInClick}
                                        />
                                    </div>
                                    <div className="login-instruction">
                                        {parse(
                                            getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.LOGIN_OR_REGISTER_DESC,
                                                props.dealerStepMoreContent
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
