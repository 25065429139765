import React, { useEffect, useRef, useState } from 'react';
import './fm-vehicle-selector.scss';
import {
    BUTTON_TYPE,
    BUTTONS,
    Option,
    VehicleAttributes,
    FMVehicleSelectorProps,
} from '../../types/vehicleSelector.types';
import Chevron from '../chevron/chevron';
import { KEYBOARD_KEYS } from '../vehicleSelector/vehicleSelector.constants';

const FMVehicleSelector = (props: FMVehicleSelectorProps) => {
    const buttonElement = useRef<HTMLButtonElement>(null);
    const panelElement = useRef<HTMLUListElement>(null);
    const itemElements: HTMLLIElement[] = [];
    const [selectedItemAriaLabel, setSelectedItemAriaLabel] =
        useState<string>();
    const DEFAULT_CURRENT_INDEX = 0;
    const vinLabel = props.content?.vinLabel || 'VIN:';

    const VEHICLE_BUTTON: BUTTONS = {
        ADD: 'Add Vehicle',
        CHANGE: 'Change Vehicle',
    };
    const [keySelectionIndex, setKeySelectionIndex] = useState<number>(
        DEFAULT_CURRENT_INDEX
    );

    const [accessibilityActive, setAccessibilityActive] =
        useState<boolean>(false);

    const [currentIndex, setCurrentIndex] = useState<number>(
        DEFAULT_CURRENT_INDEX
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const buildOptionLabel = (data: VehicleAttributes) => {
        let vehicleTitle;
        if (!data) return '';
        if (data.nickName && data.nickName.trim() !== '') {
            vehicleTitle = `<span class="vehicle-option-label">${data.nickName}</span>`;
        } else
            vehicleTitle = `<span class="vehicle-option-label">${data.year} ${data.make} ${data.model}</span>`;
        if (data && data.vin) {
            return `${vehicleTitle}<span class="vehicle-option-label">${vinLabel} ${data.vin}</span>`;
        }
        return vehicleTitle;
    };

    const buildOptionValue = (data: VehicleAttributes) => {
        if (!data) return '';
        if (data.nickName && data.nickName.trim() !== '')
            return data.nickName.trim();
        return `${data.year} ${data.make} ${data.model}`;
    };

    const getVehicleButton = (type: BUTTON_TYPE) => {
        return `<a class="add-change-vehicle-button">
                <div class="add-change-vehicle-button-text">
                    ${
                        type === VEHICLE_BUTTON.CHANGE
                            ? props.content?.changeVehicleButtonLabel
                            : props.content?.addVehicleCtaLabel
                    }
                </div>
                <span class="fds-icon fds-font--ford-icons__plus" />
            </a>`;
    };

    const buildDropdownOptions = (): Option[] => {
        let opt: Option[] = [];
        if (props.vehiclesData && props.vehiclesData.length > 0) {
            const items = props.vehiclesData.map((item) => {
                return {
                    label: buildOptionLabel(item),
                    value: buildOptionValue(item),
                };
            });
            if (props.content && props.content.addVehicleCtaLabel) {
                opt = [
                    ...items,
                    {
                        label: getVehicleButton(VEHICLE_BUTTON.ADD),
                        value: VEHICLE_BUTTON.ADD,
                    },
                ];
            } else {
                opt = items;
            }
        } else {
            opt.push({
                label: buildOptionLabel(props.value),
                value: buildOptionValue(props.value),
            });
            opt.push({
                label: getVehicleButton(VEHICLE_BUTTON.CHANGE),
                value: VEHICLE_BUTTON.CHANGE,
            });
        }

        return opt;
    };

    const [optionLabels, optionValues]: [Option['label'][], Option['value'][]] =
        buildDropdownOptions().reduce(
            (result, curr) => {
                result[0].push(curr.label);
                result[1].push(curr.value);
                return result;
            },
            [[], []]
        );

    const addItemRef = (element: HTMLLIElement) => {
        itemElements.push(element);
    };

    const getSelectedItemElement = (index: number) => {
        return itemElements.find((element) =>
            element.classList.contains('menu-item-' + index)
        );
    };

    const alignPanelToItem = (index: number) => {
        const panel = panelElement.current;
        const selectedItem = getSelectedItemElement(index);
        if (!!panel && !!selectedItem) {
            const scrollBottom = panel.clientHeight + panel.scrollTop;
            const elementBottom =
                selectedItem.offsetTop + selectedItem.offsetHeight;

            if (elementBottom > scrollBottom) {
                panel.scrollTop = elementBottom - panel.clientHeight;
            } else if (selectedItem.offsetTop < panel.scrollTop) {
                panel.scrollTop = selectedItem.offsetTop;
            }
        }
    };

    const setSelectedItem = (index: number) => {
        alignPanelToItem(index);

        setCurrentIndex(index);
        const selectedItem = getSelectedItemElement(index);
        if (selectedItem) {
            setKeySelectionIndex(index);
            selectedItem.focus();
        }
        props?.handleMenuItemClick &&
            props.handleMenuItemClick(
                optionLabels[index],
                index,
                optionValues[index]
            );
    };

    const handleMenuClick = (
        e:
            | React.KeyboardEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => {
        e.stopPropagation();
        if (!isOpen) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    };

    const closeMenu = (focusButton: boolean) => {
        setIsOpen(false);
        if (focusButton && buttonElement.current) {
            buttonElement.current.focus();
        }
    };

    const closeMenuWithSelectedItem = (focusDiv: boolean) => {
        setIsOpen(false);
        setSelectedItemAriaLabel(optionValues[keySelectionIndex]);
        if (focusDiv && buttonElement.current) {
            buttonElement.current.focus();
        }
    };

    const handleKeyDownList = (
        event: React.KeyboardEvent<HTMLUListElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setAccessibilityActive(true);
        switch (event.key) {
            case KEYBOARD_KEYS.ARROW_UP:
                if (keySelectionIndex !== undefined && keySelectionIndex > 0) {
                    alignPanelToItem(keySelectionIndex - 1);
                    setKeySelectionIndex(keySelectionIndex - 1);
                } else {
                    alignPanelToItem(optionLabels.length - 1);
                    setKeySelectionIndex(optionLabels.length - 1);
                }
                break;
            case KEYBOARD_KEYS.ARROW_DOWN:
                if (
                    keySelectionIndex !== undefined &&
                    keySelectionIndex < optionLabels.length - 1
                ) {
                    alignPanelToItem(keySelectionIndex + 1);
                    setKeySelectionIndex(keySelectionIndex + 1);
                } else {
                    alignPanelToItem(0);
                    setKeySelectionIndex(0);
                }
                break;
            case KEYBOARD_KEYS.ENTER:
                setSelectedItem(keySelectionIndex);
                setIsOpen(false);
                setAccessibilityActive(false);
                closeMenuWithSelectedItem(true);
                break;
            case KEYBOARD_KEYS.HOME:
                setSelectedItem(0);
                break;
            case KEYBOARD_KEYS.END:
                setSelectedItem(optionLabels.length - 1);
                break;
            case KEYBOARD_KEYS.ESC:
                closeMenu(true);
                break;
        }
    };

    const handleKeyDownButton = (
        event: React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (
            !isOpen &&
            (event.key === KEYBOARD_KEYS.ARROW_UP ||
                event.key === KEYBOARD_KEYS.ARROW_DOWN)
        ) {
            event.preventDefault();
            event.stopPropagation();
            handleMenuClick(event);
        }
    };

    const isSelectedItem = (index: number) => {
        return (
            (keySelectionIndex === index && accessibilityActive) ||
            (currentIndex === index && !accessibilityActive)
        );
    };
    const sanitizedOptionId = (index: number) => {
        if (optionValues[index] !== '' && optionValues[index] !== undefined) {
            return index + '_' + optionValues[index].replace(/ /g, '-');
        }

        return index + '_';
    };

    const closeOpenMenuOnOutsideClick = (e) => {
        if (
            isOpen &&
            buttonElement.current &&
            !buttonElement.current.contains(e.target) &&
            panelElement.current &&
            !panelElement.current.contains(e.target)
        ) {
            closeMenu(true);
        }
    };

    useEffect(() => {
        if (isOpen && !!panelElement.current) {
            panelElement.current.focus();
        }
        document.addEventListener('mousedown', closeOpenMenuOnOutsideClick);
        return () => {
            document.removeEventListener(
                'mousedown',
                closeOpenMenuOnOutsideClick
            );
        };
    }, [isOpen]);

    useEffect(() => {
        if (props.value) {
            const index = optionValues.indexOf(buildOptionValue(props.value));

            setCurrentIndex(index);
            setKeySelectionIndex(index);
        }
    }, [props.value]);

    return (
        <>
            <button
                className={`fm-vehicle-selector__switch-vehicle ${props.className}`}
                onClick={handleMenuClick}
                disabled={props.disabled || optionLabels.length === 0}
                ref={buttonElement}
                onKeyDown={handleKeyDownButton}
                aria-label={selectedItemAriaLabel}
                tabIndex={0}
                type='button'
                aria-describedby={''}
                aria-expanded={isOpen}
                data-testid={props.dataTestId}
            >
                {props.content?.switchVehicle}
                <Chevron direction={isOpen ? 'up' : 'down'} />
            </button>
            {isOpen && (
                <ul
                    className='fm-vehicle-selector__menu-items-panel'
                    tabIndex={0}
                    role='listbox'
                    aria-activedescendant={sanitizedOptionId(keySelectionIndex)}
                    ref={panelElement}
                    onKeyDown={handleKeyDownList}
                >
                    {buildDropdownOptions()?.map((option, index) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <li
                            id={sanitizedOptionId(index)}
                            key={`${option.value?.toLowerCase()}-${index}`}
                            className={`menu-item menu-item-${index} ${
                                isSelectedItem(index) ? 'selected-item' : ''
                            }`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedItem(index);
                                closeMenu(false);
                            }}
                            role='option'
                            aria-selected={
                                optionLabels[keySelectionIndex] === option.label
                            }
                            ref={addItemRef}
                            value={index}
                            dangerouslySetInnerHTML={{
                                __html: option.label,
                            }}
                        ></li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default FMVehicleSelector;
