import React, { useEffect, useState, useReducer } from 'react';
import './service-price-calculator-result.scss';
import {
    getInitialDealerState,
    getInitialVehicleDetails,
    useDealerStep,
    useHttp,
    useVehicleStep,
} from '../../../../hooks/owners-osb';
import { VehicleInfo } from '../../../../models/osb-model/osb-vehicle-lookup';
import { VehicleData } from '../../../../models/osb-model/osb-vehicle-step';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import GoogleMapService from '../../../../services/osb-service/google-map-service/google-map-service';
import ServiceHandler from '../../../../services/service-handler';
import { OsbMap } from '../../owners-osb/dealer-step/light-journey/osb-map';
import {
    findDealerFromMarketDealers,
    formatRegVinNumber,
    getObjectFromAEMJson,
} from '../../owners-osb/osb-utils';
import VehicleImageService from '../../vehicle-portal/services/vehicle-image-service/vehicle-image-service';
import { OsbLoader } from '../../../common/osb-loader/osb-loader';
import {
    DealerAdditionalInfo,
    DealerProfileInfo,
} from '../../../../models/osb-model/osb-dealer-info';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import { dealerStepReducer } from '../../../../reducers/osb-reducers/dealer-step-reducer';
import DealerSearchService from '../../../../services/osb-service/google-map-service/dealer-search-service';
import { DealerStep } from '../../../../models/osb-model/osb-dealer-step';
import { ServiceCard } from '../service-card/service-card';
import { ViewportUtil } from '../../../utils/viewport-util/viewport-util';
import filterIcon from '../../../../assets/owners-osb/dealer-filter.png';
import { DealerFilterOptions } from '../dealer-filter-options/dealer-filter-options';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import {
    PRICE_CALCULATOR_ANALYTICS,
    PRICE_CALCULATOR_CONSTANTS,
    PRICE_CALCULATOR_RESULT_CONSTANTS,
} from '../price-calculator-constant';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import {
    triggerDealerSearchResultAnalytics,
    triggerFiltersClick,
    triggerInternalCtaAnalytics,
} from '../analytics/price-calculator-analytics';
import { useAnalytics } from '../../../../hooks/use-analytics';

import { FormatAddress } from '../../owners-osb/common/osb-common-components/osb-common-components';
import { useAllOSBState } from '../../../../hooks/owners-osb/use-all-osb-state';

interface Props {
    allOsbDealers: DealerProfileInfo[];
    vinRegNo: string;
    mileage: string;
    location: string;
    fetchVehicleErrorMessage: (error: string) => void;
    locationErrorMsgDetails: (error: string) => void;
    errorMsgDealerDetails: (error: string) => void;
    errorServicesDetails: (error: string) => void;
    priceCalculatorContent: any;
}
export const ServicePriceCalculatorResult = (props: Props) => {
    const [
        numberOfLeadingCharToStrip,
        setNumberOfLeadingCharToStrip,
    ] = useState<number>(2);
    const [vehiclePicUrl, setVehiclePicUrl] = useState<string>();
    const [isViewMoreButtonVisible, setIsViewMoreButtonVisible] = useState<
        boolean
    >(false);
    const [
        priceCalculatorResultContent,
        setPriceCalculatorResultContent,
    ] = useState<any>();
    const [allDealersShown, setAllDealersShown] = useState<DealerProfileInfo[]>(
        []
    );
    const [defaultFilter, setDefaultFilter] = useState<boolean>(true);
    const [resetDealerIndex, setResetDealerIndex] = useState<boolean>(false);
    const [filteredRecord, setFilteredRecord] = useState<DealerProfileInfo[]>(
        []
    );
    const [PCfilteredRecord, setPCFilteredRecord] = useState<
        DealerProfileInfo[]
    >([]);
    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);
    const [dealerAddressOrder, setDealerAddressOrder] = useState<string>('');
    const [progressPercentToDisplay, setProgressPercentToDisplay] = useState<
        string
    >('');

    const { isMobileView } = ViewportUtil();
    const { httpState, dispatch } = useHttp();
    let { brand } = new AppConfigurationService();
    brand = brand === 'demo' ? 'ford' : brand;
    const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>(
        getInitialVehicleDetails()
    );
    const [dealerStep, setDealerStep] = useReducer(
        dealerStepReducer,
        getInitialDealerState()
    );
    const { osbDealerStep } = useDealerStep();
    const { osbVehicleStep } = useVehicleStep();
    const bookable = useAllOSBState();

    const limitArray = (array: DealerProfileInfo[], limit: any) => {
        if (array) {
            return array.slice(0, limit);
        }
    };
    const [fireEvents] = useAnalytics();

    const setComponentPayload = (
        payload:
            | {
                  [key: string]:
                      | string
                      | DealerProfileInfo
                      | boolean
                      | number
                      | []
                      | {};
              }
            | DealerStep
    ): void => {
        setDealerStep({ type: 'SET', payload: payload });
    };

    //vehicle image service for fetching vehicle image
    const fetchVehicleImage = (vehicleAttributes: VehicleAttributes) => {
        new VehicleImageService()
            .getVehicleImage(vehicleAttributes, 'page')
            .then(vehicleImageUrl => {
                setVehiclePicUrl(
                    vehicleImageUrl !== null ? vehicleImageUrl : '#'
                );
            })
            .catch();
    };

    const getVehicle = async () => {
        if (props.vinRegNo !== '' && props.mileage !== '') {
            let vehicleData: VehicleData;
            if (props.vinRegNo.length <= 10) {
                vehicleData = {
                    registrationNumber: formatRegVinNumber(
                        props.vinRegNo,
                        true
                    ),
                    mileage: props.mileage,
                };
            } else {
                vehicleData = {
                    vin: formatRegVinNumber(props.vinRegNo, true),
                    mileage: props.mileage,
                };
            }
            await ServiceHandler.OsbVehicleLookUp.postVehicleInfo({
                vehicleData: vehicleData,
                prognosticsData: osbVehicleStep.vehiclePrognosticData,
                dealerCode: osbDealerStep.selectedDealerProfile.dealerCode,
                bookable: bookable,
            })
                .then(result => {
                    fetchVehicleImage({
                        model: result.value.modelName,
                        make: brand,
                        vin: result.value.vin,
                        year: parseInt(result.value.buildDate.substring(0, 4)),
                    });
                    setVehicleInfo(result.value);
                    props.fetchVehicleErrorMessage('');
                })
                .catch((error: any) => {
                    dispatch({
                        type: 'ERROR',
                        error: error.errorMessage,
                    });
                    if (error) {
                        const errorMessage = error.errorCode;
                        if (errorMessage === 'OSB_VIN_EXISTS') {
                            props.fetchVehicleErrorMessage('ACTIVE_BOOKING');
                        } else if (
                            errorMessage === 'OSB_NO_DATAFOUND' ||
                            errorMessage === 'OSB_VEHICLE_DETAILS_NOT_FOUND' ||
                            errorMessage === 'VEHICLE_NOT_FOUND'
                        ) {
                            if (props.vinRegNo.length <= 10) {
                                props.fetchVehicleErrorMessage('REG_NOT_FOUND');
                            } else {
                                props.fetchVehicleErrorMessage('VIN_NOT_FOUND');
                            }
                        } else {
                            props.fetchVehicleErrorMessage(
                                getObjectFromAEMJson(
                                    PRICE_CALCULATOR_RESULT_CONSTANTS.COMMON_SERVICE_API_ERROR,
                                    priceCalculatorResultContent
                                )
                            );
                        }
                    }
                });
        }
    };

    const getOpeningHours = (osbDealers: any, dealerId: string) => {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.openingHours;
    };
    const getDealerName = (osbDealers: any, dealerId: string) => {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.dealerName;
    };

    function getDealerFilterServiceAvailability(osbDealers: any, data: any) {
        try {
            return JSON.parse(
                getPropertyFromDealerAdditionalInfo(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? data.DealerID.substring(numberOfLeadingCharToStrip)
                        : data.DealerID,
                    'dealerFilterServiceAvailability'
                )
            );
        } catch (error) {
            return [];
        }
    }

    function getPropertyFromDealerAdditionalInfo(
        osbDealers: any,
        dealerId: string,
        property: keyof DealerAdditionalInfo
    ) {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.additionalInfo[property];
    }
    const createDealerProfileFromBingDealer = (
        osbDealers: DealerProfileInfo[]
    ) => {
        setIsViewMoreButtonVisible(
            osbDealers.length >
                Number(
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                        priceCalculatorResultContent
                    )
                )
        );
        const dealerList: DealerProfileInfo[] = [];
        const _osbEnabledDealerFromBing = dealerStep.osbEnabledDealerFromBing;
        _osbEnabledDealerFromBing.forEach((element: any) => {
            const dealerProfile: DealerProfileInfo = {
                dealerCode:
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID,
                dealerName: getDealerName(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID
                ),
                postalCode: element.PostCode,
                street: element.AddressLine1,
                town: element.Locality,
                openingHours: getOpeningHours(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID
                ),
                customerRating: element.CustomerRatingsService,
                customerRespondentsService: element.CustomerRespondentsService,
                distance: element.distance,
                primaryPhone: element.PrimaryPhone,
                location: element.location,
                didVideoCheck: Boolean(element.VideoCheck),
                didMobileService: Boolean(element.MobileService),
                dealerType:
                    props.allOsbDealers?.find(
                        e =>
                            e.dealerCode ===
                            (numberOfLeadingCharToStrip > 0
                                ? element.DealerID.substring(
                                      numberOfLeadingCharToStrip
                                  )
                                : element.DealerID)
                    )?.dealerType + ''.trim().toString(),
                additionalInfo: {
                    earliestAvailableTimeslot: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'earliestAvailableTimeslot'
                    ),
                    dealerFilterServiceAvailability: getDealerFilterServiceAvailability(
                        osbDealers,
                        element
                    ),
                    nextAppointmentDateAm: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDateAm'
                    ),
                    nextAppointmentDatePm: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDatePm'
                    ),
                    nextAppointmentDateWeekend: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDateWeekend'
                    ),
                },

                specialServices: [],
            };
            dealerList.push(dealerProfile);
        });
        setAllDealersShown(dealerList);
        osbDealerStep.dealerProfiles = dealerList;
        return limitArray(
            dealerList,
            Number(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                    priceCalculatorResultContent
                )
            )
        );
    };

    const getDealersFromMarketDealers = (osbEnabledDealerIds: string[]) => {
        const dealerProfileInfos: DealerProfileInfo[] = [];
        osbEnabledDealerIds.forEach(id => {
            const dealerProfileInfo = findDealerFromMarketDealers(
                id,
                props.allOsbDealers
            );
            if (dealerProfileInfo != null) {
                dealerProfileInfos.push(dealerProfileInfo);
            }
        });
        return dealerProfileInfos;
    };

    const getOSBDealers = async (dealerData: any) => {
        if (dealerData.length <= 0) {
            props.errorMsgDealerDetails('OSB_NO_DATAFOUND');
        }
        // form osb dealer id list for the OSB dealer information
        const osbEnabledDealerIds = dealerData.map(function(dlr: {
            [x: string]: any;
        }) {
            if (numberOfLeadingCharToStrip > 0) {
                return dlr['DealerID'].substring(numberOfLeadingCharToStrip);
            } else {
                return dlr['DealerID'];
            }
        });

        const dealerProfileInfos = getDealersFromMarketDealers(
            osbEnabledDealerIds
        );
        const dealerProfileFromBing = dealerProfileInfos
            ? createDealerProfileFromBingDealer(dealerProfileInfos) || []
            : [];
        setFilteredRecord(dealerProfileFromBing);
        setResetDealerIndex(true);
        setComponentPayload({
            dealerProfiles: dealerProfileFromBing,
            filteredDealerProfiles: dealerProfileFromBing,
            selectedDealerProfile: dealerProfileInfos[0] || {},
            selectedDealerName: dealerProfileInfos[0].dealerName || '',
        });
    };

    const onSearchDealersByDistanceSuccess = (
        bingDealers: any,
        origin: string,
        coordinate?: any,
        callback?: any
    ) => {
        const _osbEnabledDealerFromBing = DealerSearchService.filterOnOsbDealerIds(
            props.allOsbDealers,
            bingDealers,
            numberOfLeadingCharToStrip
        );

        // increase bing search logic - begin
        const osbEnabledDealerFromBingCount = _osbEnabledDealerFromBing.length;
        const maxDealerShown = Number(
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.MAX_DEALER_COUNT,
                priceCalculatorResultContent
            )
        );
        const _searchDistanceIndex = dealerStep.searchDistanceIndex;
        if (
            osbEnabledDealerFromBingCount < maxDealerShown &&
            origin === 'searchByLocation' &&
            _searchDistanceIndex <
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.SEARCH_DISTANCE,
                    priceCalculatorResultContent
                ).length
        ) {
            callback(null);
            return;
        }
        if (origin === 'searchByLocation') {
            GoogleMapService.getDealerDistance(
                coordinate,
                100,
                _osbEnabledDealerFromBing,
                dealerData => {
                    dealerStep.osbEnabledDealerFromBing = dealerData;
                    getOSBDealers(dealerData);
                }
            );
        } else {
            dealerStep.osbEnabledDealerFromBing = _osbEnabledDealerFromBing;
            getOSBDealers(_osbEnabledDealerFromBing);
        }
    };

    const geocodeLocation = (results?: any) => {
        if (results && results !== null) {
            dealerStep.geocoordinate = results;
        } else {
            results = dealerStep.geocoordinate;
        }
        osbDealerStep.geocoordinate = dealerStep.geocoordinate;

        if (results.length <= 0) {
            props.locationErrorMsgDetails('LOCATION_ERROR');
        }

        const coordinate: any = results[0];
        const _searchDistanceIndex: number =
            dealerStep.searchDistanceIndex ||
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.SEARCH_DISTANCE,
                priceCalculatorResultContent
            ).indexOf(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                    priceCalculatorResultContent
                )
            );

        let radius = getObjectFromAEMJson(
            PRICE_CALCULATOR_RESULT_CONSTANTS.SEARCH_DISTANCE,
            priceCalculatorResultContent
        )[_searchDistanceIndex];

        radius = Number(radius) * 1.60934;
        dealerStep.searchDistanceIndex = _searchDistanceIndex + 1;
        const polygon = getObjectFromAEMJson(
            PRICE_CALCULATOR_RESULT_CONSTANTS.POLYGON,
            priceCalculatorResultContent
        );
        const maxResult = 100;
        DealerSearchService.bingDealerSearchByLocation(
            results,
            radius,
            maxResult,
            (dealers: any) => {
                onSearchDealersByDistanceSuccess(
                    dealers,
                    'searchByLocation',
                    coordinate,
                    () => {
                        geocodeLocation();
                    }
                );
            },
            polygon
        );
    };

    const geoCode =
        getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Geo_Country_Code,
            props.priceCalculatorContent
        )
            .trim()
            .toString().length > 0
            ? getObjectFromAEMJson(
                  PRICE_CALCULATOR_CONSTANTS.Geo_Country_Code,
                  props.priceCalculatorContent
              ).trim()
            : OsbUtilService.getAppConfiguration().countryCode;

    const fetchSelectedLocation = (location: string) => {
        GoogleMapService.getGeocodeLocation(geoCode, location, geocodeLocation);
        osbDealerStep.searchLocationType = 'manual location';
    };

    const getPriceCalculatorResultContent = async (modal: string) => {
        await ServiceHandler.PriceCalculatorContentService.getPriceCalculatorContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modal
        )
            .then(results => {
                setNumberOfLeadingCharToStrip(
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.NUMBER_OF_CHAR_STRIP_FROM_DEALERID,
                        results.elements
                    )
                );
                dealerStep.searchDistanceIndex = getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.SEARCH_DISTANCE,
                    results.elements
                ).indexOf(
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                        results.elements
                    )
                );

                setDealerAddressOrder(
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.DEALER_ADDRESS_ORDER,
                        results.elements
                    )
                );

                setPriceCalculatorResultContent(results.elements);
            })
            .catch((error: any) => {
                dispatch({
                    type: 'ERROR',
                    error: error.message,
                });
            });
    };
    // Added this useeffect for mobile view to change to filter icon position depends on scroll bar.
    const listenToScroll = () => {
        const windowScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        if (windowScroll > (vehiclePicUrl === '#' ? 1160 : 1285)) {
            setDefaultFilter(false);
        } else {
            setDefaultFilter(true);
        }
    };

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getPriceCalculatorResultContent('price-calculator-result');
        setProgressPercentToDisplay('20');
        triggerDealerSearchResultAnalytics(fireEvents);
    }, []);

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        if (props.vinRegNo && props.mileage && priceCalculatorResultContent) {
            getVehicle();
            setProgressPercentToDisplay('40');
        }
    }, [props.vinRegNo, props.mileage, priceCalculatorResultContent]);

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        dealerStep.searchDistanceIndex = 0;
        if (
            (vehicleInfo.registrationNumber || vehicleInfo.vin) &&
            props.location &&
            priceCalculatorResultContent
        ) {
            fetchSelectedLocation(props.location);
            setProgressPercentToDisplay('60');
        }
    }, [vehicleInfo, props.location, priceCalculatorResultContent]);

    useEffect(() => {
        if (vehicleInfo.vin && filteredRecord.length > 0) {
            dispatch({ type: 'RESPONSE' });
        }
        if (
            filteredRecord.length > 0 &&
            resetDealerIndex &&
            serverSideService.isClientSide()
        ) {
            const div = document.getElementById('pc-result-container');
            const topHeaderHeight =
                document.getElementById('euheaderId')?.clientHeight || 0;
            const PcBannerHeight =
                document.getElementById('osbBannerId')?.clientHeight || 0;
            const PcHeaderHeight =
                document.getElementById('pc-header-section')?.clientHeight || 0;
            const topPos = div?.offsetTop;
            if (isMobileView && topPos != undefined) {
                setTimeout(
                    () =>
                        window.scrollTo({
                            top: topPos - 50,
                            behavior: 'smooth',
                        }),
                    0
                );
            } else {
                setTimeout(
                    () =>
                        window.scrollTo({
                            top:
                                PcBannerHeight +
                                PcHeaderHeight +
                                topHeaderHeight +
                                10,
                            behavior: 'smooth',
                        }),
                    0
                );
            }
        }
    }, [filteredRecord, resetDealerIndex]);

    useEffect(() => {
        if (isMobileView && serverSideService.isClientSide()) {
            window.addEventListener('scroll', listenToScroll);
            return () => window.removeEventListener('scroll', listenToScroll);
        }
    }, [vehiclePicUrl]);

    const toggleLightDealerFilterOptions = () => {
        triggerFiltersClick(
            fireEvents,
            vehicleInfo.buildDate,
            vehicleInfo.modelName
        );
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };
    const errorServicesDetails = (error: string) => {
        props.errorServicesDetails(error);
    };

    const dealerServiceSection = (dealerProfile: DealerProfileInfo) => {
        const vehicleData: VehicleData = {
            vin: vehicleInfo.vin,
            mileage: props.mileage,
        };

        return (
            vehicleInfo.vin && (
                <ServiceCard
                    dealerProfile={dealerProfile}
                    vehicleData={vehicleData}
                    priceCalculatorResultContent={priceCalculatorResultContent}
                    errorServicesDetails={errorServicesDetails}
                    fireEvents={fireEvents}
                />
            )
        );
    };

    const showMaxDealers = () => {
        triggerInternalCtaAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.LOADMORE_LABEL
        );
        if (allDealersShown) {
            setFilteredRecord(
                limitArray(
                    PCfilteredRecord.length > 0
                        ? PCfilteredRecord
                        : allDealersShown,
                    Number(
                        getObjectFromAEMJson(
                            PRICE_CALCULATOR_RESULT_CONSTANTS.MAX_DEALER_COUNT,
                            priceCalculatorResultContent
                        )
                    )
                ) || []
            );
            setResetDealerIndex(false);
            setIsViewMoreButtonVisible(false);
        }
    };

    const refershDealerList = (dealerProfiles: DealerProfileInfo[]) => {
        setResetDealerIndex(true);
        setPCFilteredRecord(dealerProfiles);
        if (isViewMoreButtonVisible) {
            dealerProfiles =
                limitArray(
                    dealerProfiles,
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                        priceCalculatorResultContent
                    )
                ) || [];
        } else {
            dealerProfiles =
                limitArray(
                    dealerProfiles,
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.MAX_DEALER_COUNT,
                        priceCalculatorResultContent
                    )
                ) || [];
        }

        setFilteredRecord(dealerProfiles);
    };

    const filterDealerList = filteredRecord.map((dealerProfile, index) => {
        const distanceUnit = getObjectFromAEMJson(
            PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_UNIT,
            priceCalculatorResultContent
        ).toLowerCase();

        const distanceValue =
            distanceUnit === 'miles'
                ? OsbUtilService.getDistanceInMiles(
                      Number(dealerProfile.distance)
                  )
                : OsbUtilService.getDistanceInKm(
                      Number(dealerProfile.distance)
                  );

        const milesLabel = getObjectFromAEMJson(
            PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_FROM_YOU,
            priceCalculatorResultContent
        );
        return (
            <div
                key={index}
                className="dealer-items-container"
                id={`dealerScrollView-${index}`}
            >
                <div className="sequence-number">{index + 1}</div>
                <div className="dealer-info">
                    <div className="dealer-name">
                        {dealerProfile.dealerName &&
                            dealerProfile.dealerName !== '' &&
                            dealerProfile.dealerName}
                    </div>
                    <div className="dealer-address">
                        <FormatAddress
                            addressData={dealerProfile}
                            addressOrder={dealerAddressOrder}
                            isDisplaySingleRow={true}
                        />
                    </div>
                    {dealerProfile.distance > 0 && (
                        <div className="distance-miles">
                            <div className="distance-value">
                                {distanceValue}
                            </div>
                            <div className="miles-label">{milesLabel}</div>
                        </div>
                    )}
                    {dealerServiceSection(dealerProfile)}
                </div>

                <div className="hr-line-style"></div>
            </div>
        );
    });

    return (
        <>
            <div
                className="service-price-calculator-result-container"
                id="pc-result-container"
            >
                {httpState.isLoading ? (
                    <OsbLoader
                        osbLoaderMessage={getObjectFromAEMJson(
                            PRICE_CALCULATOR_RESULT_CONSTANTS.LOADER_MSG,
                            priceCalculatorResultContent
                        )}
                        progressPercent={progressPercentToDisplay}
                    />
                ) : (
                    <>
                        {isMobileView ? (
                            <div className="pc-vehicle-image-container">
                                <div className="pc-recommended-label">
                                    {getObjectFromAEMJson(
                                        PRICE_CALCULATOR_RESULT_CONSTANTS.RECOMMENDEC_LABEL,
                                        priceCalculatorResultContent
                                    )}
                                </div>
                                <div className="pc-vehicle-detail-name">
                                    {vehicleInfo.modelName} &nbsp;{' '}
                                    {vehicleInfo.buildDate?.substring(0, 4)}
                                </div>
                                {vehiclePicUrl && vehiclePicUrl !== '#' && (
                                    <div className="pc-vehicle-detail-image">
                                        <img
                                            className="pc-osb-vehicle-image"
                                            src={vehiclePicUrl}
                                            alt=""
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="pc-vehicle-image-container">
                                {vehiclePicUrl && vehiclePicUrl !== '#' && (
                                    <div className="pc-vehicle-detail-image">
                                        <img
                                            className="pc-osb-vehicle-image"
                                            src={vehiclePicUrl}
                                            alt=""
                                        />
                                    </div>
                                )}

                                <div className="pc-recommended-label">
                                    {getObjectFromAEMJson(
                                        PRICE_CALCULATOR_RESULT_CONSTANTS.RECOMMENDEC_LABEL,
                                        priceCalculatorResultContent
                                    )}
                                </div>
                                <div className="pc-vehicle-detail-name">
                                    {vehicleInfo.modelName}{' '}
                                    {vehicleInfo.buildDate?.substring(0, 4)}
                                </div>
                            </div>
                        )}
                        <div className="pc-map-dealer-view-container">
                            <div className="pc-map">
                                {filteredRecord.length > 0 && (
                                    <OsbMap
                                        dealers={filteredRecord}
                                        isPcflow={true}
                                    />
                                )}
                            </div>
                            <div className="pc-filter-dealer-result-container">
                                {showDealerFilterOptions ? (
                                    <div className="dealer-filter-options-container">
                                        <DealerFilterOptions
                                            toggleLightDealerFilterOptions={
                                                toggleLightDealerFilterOptions
                                            }
                                            priceCalculatorResultContent={
                                                priceCalculatorResultContent
                                            }
                                            refreshDealerDetailsList={
                                                refershDealerList
                                            }
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {(!isMobileView || defaultFilter) && (
                                            <div className="pc-filter">
                                                <div
                                                    className="dealer-filter"
                                                    onClick={
                                                        toggleLightDealerFilterOptions
                                                    }
                                                >
                                                    <div>
                                                        <img
                                                            src={filterIcon}
                                                            className="filter-icon"
                                                            alt="dealer-filter-icon"
                                                        />{' '}
                                                        &nbsp;
                                                    </div>
                                                    <div>
                                                        {getObjectFromAEMJson(
                                                            PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_LABEL,
                                                            priceCalculatorResultContent
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="pc-dealer-list">
                                            {filterDealerList}
                                            {isViewMoreButtonVisible ? (
                                                <>
                                                    <div className="record-count">
                                                        {getObjectFromAEMJson(
                                                            PRICE_CALCULATOR_RESULT_CONSTANTS.DEFAULT_DEALER_COUNT,
                                                            priceCalculatorResultContent
                                                        )}
                                                        &nbsp;
                                                        {getObjectFromAEMJson(
                                                            PRICE_CALCULATOR_RESULT_CONSTANTS.COUNT_DEALER_OF,
                                                            priceCalculatorResultContent
                                                        )}
                                                        &nbsp;
                                                        {getObjectFromAEMJson(
                                                            PRICE_CALCULATOR_RESULT_CONSTANTS.MAX_DEALER_COUNT,
                                                            priceCalculatorResultContent
                                                        )}
                                                    </div>
                                                    <div className="view-more-button-container">
                                                        <div
                                                            className="view-more"
                                                            onClick={
                                                                showMaxDealers
                                                            }
                                                        >
                                                            <div>
                                                                {getObjectFromAEMJson(
                                                                    PRICE_CALCULATOR_RESULT_CONSTANTS.VIEW_MORE,
                                                                    priceCalculatorResultContent
                                                                )}
                                                            </div>
                                                            <FdsChevron
                                                                direction={
                                                                    'down'
                                                                }
                                                                type="filled"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <div className="empty-div"></div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        {isMobileView &&
                            !defaultFilter &&
                            !showDealerFilterOptions && (
                                <>
                                    <div
                                        className="mobile-filters"
                                        onClick={toggleLightDealerFilterOptions}
                                    >
                                        <div>
                                            <img
                                                src={filterIcon}
                                                className="filter-icon"
                                                alt="dealer-filter-icon"
                                            />{' '}
                                            &nbsp;
                                        </div>
                                        <div className="filter-label">
                                            {getObjectFromAEMJson(
                                                PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_LABEL,
                                                priceCalculatorResultContent
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                    </>
                )}
            </div>
        </>
    );
};
