import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { PrimaryButton } from '../../../../../common';
import './light-vouchers-lincoln.scss';
import {
    useDealerStep,
    useHttp,
    useOSBStep,
    useServiceStep,
    useVehicleStep,
} from '../../../../../../hooks/owners-osb';
import { OsbContentResponse } from '../../../../../../models/osb-model/osb-content-details';
import {
    LIGHT_JOURNEY_ANALYTICS,
    OSB_LIGHTT_VOUCHER_KEYS,
    SERVICE_DELIVERY_TYPE,
} from '../../../osb-constant';
import { getLightVehicleData, ErrorResponse } from '../../../osb-utils';
import errorWarning from '../../../../../../assets/error-warning.svg';
import {
    DealerServices,
    DealerServicesArray,
} from '../../../../../../models/osb-model/osb-dealerservice-info';
import {
    triggerApplyVoucherConfirmCtaAnalytics,
    triggerApplyVoucherCtaAnalytics,
    triggerErrorVoucherCtaAnalytics,
    triggerInvalidVoucherCtaAnalytics,
} from '../../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { OsbLoader } from '../../../../../common/osb-loader/osb-loader';
import ServiceHandler from '../../../../../../services/service-handler';
import { useCampaignStatesStep } from '../../../../../../hooks/owners-osb/use-campaign-states-step';
import { useAllOSBState } from '../../../../../../hooks/owners-osb/use-all-osb-state';
import { DealerServiceAPIResponse } from '../../../../../../services/osb-service/services-info-service/dealer-services-client';
import { isAPIResponseError } from '../../../osb-bookable-utils';

interface Props {
    setStatus: (status: boolean) => void;
    openPopupData: boolean;
    refreshBookingSummary?: () => void;
    refreshServicesData?: (
        dealerServices: DealerServices,
        isVoucherApplied: boolean
    ) => void;
    refreshDeliveryData?: (
        dealerServices: DealerServices,
        isVoucherApplied: boolean
    ) => void;
    refreshLocationData?: (
        dealerServices: DealerServices,
        isVoucherApplied: boolean
    ) => void;
    loadVoucherAlertMessage: (
        alertMessage: string,
        alertSuccess: boolean
    ) => void;
}
export const LightJourneyVouchers = (props: Props) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [voucherCode, setVoucherCode] = useState('');
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const { osbDealerStep, callContentService } = useDealerStep();
    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const [errorMsgService, setErrorMsgService] = useState('');
    const [error, setError] = useState('');
    const [isValidVoucher, setIsValidVoucher] = useState(true);
    const { osbVehicleStep } = useVehicleStep();
    const bookable = useAllOSBState();
    const [fireEvents] = useAnalytics();
    const { setOSBCampaignStates } = useCampaignStatesStep();
    const dealerProfile = osbDealerStep.selectedDealerProfile;
    const selectedDealerCode =
        dealerProfile.dealerCode || osbDealerStep.selectedDealerId || '';
    const closeModal = () => {
        setOpenPopup(false);
        props.setStatus(false);
    };
    const { httpState, dispatch } = useHttp();
    const handleInvalidVoucherState = () => {
        setOpenPopup(true);
        props.setStatus(true);
        setError('OSB_VOUCHER_CODE_INVALID');
        triggerInvalidVoucherCtaAnalytics(
            osbDealerStep,
            osbVehicleStep,
            osbStep,
            fireEvents,
            voucherCode
        );
        setIsValidVoucher(false);
    };

    const campaignValidation = (data: DealerServicesArray) => {
        return (
            data.dealerServices?.campaignStates
                ?.flatMap(campaignState => campaignState.validations)
                ?.filter(
                    validation =>
                        validation.type === 'Dealer' &&
                        validation.state === 'INVALID'
                ) || []
        );
    };

    const errorResponseHandler = (
        errorResponse: ErrorResponse | DealerServiceAPIResponse
    ) => {
        if (isAPIResponseError<DealerServiceAPIResponse>(errorResponse)) {
            dispatch({ type: 'ERROR' });
            if (errorResponse.errorCode === 'OSB_VOUCHER_CODE_INVALID') {
                handleInvalidVoucherState();
            } else {
                setError('INTERNAL_SERVER_ERROR');
                setOpenPopup(true);
                props.setStatus(true);
                triggerErrorVoucherCtaAnalytics(
                    osbDealerStep,
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    voucherCode
                );
                setIsValidVoucher(false);
            }
        } else {
            updateVoucherDetailsOnSubmit(errorResponse);
        }
    };

    const setAppliedVoucher = async (voucherCode: string) => {
        dispatch({ type: 'REQUEST' });
        const vehicleData = getLightVehicleData(osbVehicleStep);

        // we need to only update state and not mutate bookable.
        // probably need to wrap setAppliedVoucher with useEffect
        bookable.campaignSelections = [{ voucherCode }];

        if (vehicleData && voucherCode) {
            await ServiceHandler.OsbDealerServices.getServicesList({
                dealerCode: selectedDealerCode,
                vehicleData: vehicleData,
                vouchers: voucherCode ? [voucherCode] : [],
                serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                    ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                    : SERVICE_DELIVERY_TYPE.WORKSHOP,
                requestSourceType: osbStep.source,
                bookable: bookable,
                DSLAPIVersion: osbStep.DSLAPIVersion,
            })
                .then(results => {
                    const validationErrors = campaignValidation(results?.value);
                    if (validationErrors.length === 0) {
                        updateVoucherDetailsOnSubmit(results);
                    } else {
                        dispatch({ type: 'ERROR' });
                        handleInvalidVoucherState();
                    }
                })
                .catch(
                    (
                        errorResponse: ErrorResponse | DealerServiceAPIResponse
                    ) => {
                        errorResponseHandler(errorResponse);
                    }
                );
        }
    };

    const updateVoucherDetailsOnSubmit = (
        results: DealerServiceAPIResponse
    ) => {
        setErrorMsgService('');
        setError('');
        triggerApplyVoucherConfirmCtaAnalytics(
            osbDealerStep,
            osbVehicleStep,
            osbStep,
            fireEvents,
            voucherCode
        );
        setOSBServiceStepPayload({
            dealerServiceInfo: results.value,
            voucherCode: voucherCode,
            voucherDesc:
                results.value.dealerServices.serviceVouchers.length > 0
                    ? results.value.dealerServices.serviceVouchers[0]
                          .description
                    : '',
        });

        if (Array.isArray(results.value.dealerServices?.campaignStates)) {
            setOSBCampaignStates({
                campaignStates: [
                    ...results.value.dealerServices.campaignStates,
                ],
            });
        }
        props.refreshDeliveryData?.(results.value.dealerServices, true);
        props.refreshServicesData?.(results.value.dealerServices, true);
        props.refreshLocationData?.(results.value.dealerServices, true);
        props.refreshBookingSummary?.();
        dispatch({ type: 'RESPONSE' });
        setOpenPopup(false);
        props.setStatus(false);
        props.loadVoucherAlertMessage(
            voucherCode.toUpperCase() +
                ' ' +
                osbStep.voucherSuccessMessagelabel,
            true
        );
        setIsValidVoucher(true);
        props.refreshBookingSummary?.();
    };
    const getVoucherDetails = async () => {
        if (voucherCode.length > 0) {
            triggerApplyVoucherCtaAnalytics(
                osbDealerStep,
                osbVehicleStep,
                osbStep,
                fireEvents,
                voucherCode,
                LIGHT_JOURNEY_ANALYTICS.APPLY_VOUCHER_CODE
            );
            await setAppliedVoucher(voucherCode);
            if (osbStep.isVoucherDeepLinked) {
                setOSBStepPayload({ isVoucherDeepLinked: false });
            }
        } else {
            setIsValidVoucher(false);
        }
    };

    const onEnterVoucher = (e: any) => {
        setVoucherCode(e.target.value);
    };

    const onEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') getVoucherDetails();
    };

    const [
        voucherContent,
        setVoucherContent,
    ] = useState<OsbContentResponse | null>(null);

    const loadVoucherContent = async () => {
        await callContentService(OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_FRAGMENT_NAME)
            .then(results => {
                setVoucherContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                if (error.response) {
                    dispatch({ type: 'ERROR', error: error.message });
                }
            });
    };

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        loadVoucherContent();
        setOpenPopup(props.openPopupData);
    }, [props.openPopupData]);

    return (
        <div>
            <Popup
                className={`brand-${osbStep.brandName} voucher-popup`}
                modal={true}
                closeOnDocumentClick={false}
                open={openPopup}
            >
                {httpState.isLoading && voucherContent ? (
                    <OsbLoader />
                ) : (
                    <div className={`brand-${osbStep.brandName} modal-voucher`}>
                        <button
                            className="close-button-voucher"
                            onClick={closeModal}
                        >
                            &times;
                        </button>

                        <div className="content-header-voucher">
                            {voucherContent?.elements.find(
                                e =>
                                    e.name ===
                                    OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_TITLE
                            )?.value + ''.toString()}
                        </div>

                        {errorMsgService.length > 0 ? (
                            <div>{errorMsgService}</div>
                        ) : (
                            ''
                        )}
                        <div className="content-voucher-body">
                            <div>
                                <label className="input-label">
                                    {voucherCode?.length > 0 && (
                                        <span>
                                            {voucherContent?.elements.find(
                                                e =>
                                                    e.name ===
                                                    OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_TEXTBOX_PLACEHOLDER
                                            )?.value + ''.toString()}
                                        </span>
                                    )}
                                    {!isValidVoucher ? (
                                        <img
                                            className="osb-error-icon"
                                            src={errorWarning}
                                            alt="error-icon"
                                        />
                                    ) : (
                                        <span></span>
                                    )}
                                </label>
                                <input
                                    name="voucherCode"
                                    className={`content-voucher-input ${
                                        !isValidVoucher
                                            ? 'voucher-input-error'
                                            : ''
                                    } ${
                                        voucherCode.length > 0
                                            ? 'voucher-input-shadow'
                                            : ''
                                    }`}
                                    placeholder={
                                        voucherContent?.elements.find(
                                            e =>
                                                e.name ===
                                                OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_TEXTBOX_PLACEHOLDER
                                        )?.value + ''.toString()
                                    }
                                    value={voucherCode}
                                    onKeyDown={onEnterKeyPress}
                                    onChange={e => onEnterVoucher(e)}
                                />
                                {!isValidVoucher && (
                                    <div className="voucher-invalid">
                                        {error &&
                                            error ===
                                                'OSB_VOUCHER_CODE_INVALID' &&
                                            voucherContent?.elements.find(
                                                e =>
                                                    e.name ===
                                                    OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_INVALID_ERROR
                                            )?.value + ''.toString()}
                                        {error &&
                                            error === 'INTERNAL_SERVER_ERROR' &&
                                            osbStep.internalErrorMessage}
                                    </div>
                                )}
                            </div>

                            <div className="content-vouchers-text-description">
                                {voucherContent?.elements.find(
                                    e =>
                                        e.name ===
                                        OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_CODE_LIMIT_TEXT
                                )?.value + ''.toString()}
                            </div>
                            <div className="content-add-voucher-button">
                                <PrimaryButton
                                    className="primary-button"
                                    color={'dark'}
                                    fill={'fill'}
                                    chevron={false}
                                    onClick={getVoucherDetails}
                                >
                                    {voucherContent?.elements.find(
                                        e =>
                                            e.name ===
                                            OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_APPLY_BUTTON
                                    )?.value + ''.toString()}
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    );
};
