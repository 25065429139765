import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { getObjectFromAEMJson } from '../../../osb-utils';
import './dealer-search-lincoln.scss';
import { DEALER_STEP_KEYS } from '../../../osb-constant';
import { useDealerStep } from '../../../../../../hooks/owners-osb';
import { OSBUseLocation } from '../../../common/osb-use-location/osb-use-location';
import DealerSearchDropdown from '../../../../osb-v3/dealer-search-dropdown/dealer-search-dropdown';
interface Props {
    errorMsgDealer: string;
    errorMsgDealerDetails: string;
    fetchSelectedLocation: (location: string, searchType?: string) => void;
    locationErrorMsgDetails: string;
    showCurrentLocation: () => void;
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    includeUseMyLocationLink?: boolean;
}
export const DealerSearch = (props: Props) => {
    const { osbDealerStep } = useDealerStep();
    return (
        <div className="osb-light-dealer-search-container">
            {props.errorMsgDealer &&
                props.errorMsgDealer === 'OSB_NO_DATAFOUND' && (
                    <div className="dealer-error-message">
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.NO_RECORDS_FOUND_LABEL,
                            props.dealerStepContent
                        )}
                    </div>
                )}
            {props.errorMsgDealer &&
                props.errorMsgDealer === 'OSB_INVALID_MARKETCODE' && (
                    <div className="dealer-error-message">
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.POSITION_UNAVAILABLE_LABEL,
                            props.dealerStepContent
                        )}
                    </div>
                )}
            {props.errorMsgDealer &&
                props.errorMsgDealer === 'SERVICE_ERROR' && (
                    <div className="dealer-error-message">
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.TRY_AGAIN_LATER_LABEL,
                            props.dealerStepContent
                        )}
                    </div>
                )}
            {props.errorMsgDealerDetails && (
                <div className="dealer-error-message">
                    {props.errorMsgDealerDetails}
                </div>
            )}
            <div className="osb-light-dealer-search-box-container">
                <div className="serach-type-label">
                    {getObjectFromAEMJson(
                        DEALER_STEP_KEYS.SEARCH_BY_LABEL,
                        props.dealerStepMoreContent
                    )}
                </div>
                <div className="light-dealer-search-box">
                    <div className="autocomplete-box">
                        <DealerSearchDropdown
                            onSelect={function(value: string, type): void {
                                props.fetchSelectedLocation(value, type);
                            }}
                            placeholder={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.DEALER_SEARCH_DROPDOWN_PLACEHOLDER_LABEL,
                                props.dealerStepMoreContent
                            )}
                            locationLabel={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.SEARCH_BY_LOCATION_LABEL,
                                props.dealerStepMoreContent
                            )}
                            serviceCentreLabel={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.SEARCH_BY_DEALER_NAME_LABEL,
                                props.dealerStepMoreContent
                            )}
                            selectedValue={osbDealerStep.selectedDealerName}
                        />
                    </div>
                    {props.includeUseMyLocationLink &&
                        props.includeUseMyLocationLink === true && (
                            <div className="use-location-box">
                                <div
                                    className={`location-link-container ${
                                        props.locationErrorMsgDetails
                                            ? 'location-error'
                                            : ''
                                    }`}
                                >
                                    <OSBUseLocation
                                        showCurrentLocation={
                                            props.showCurrentLocation
                                        }
                                        useLocationLabel={getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.USE_MY_LOCATION_LABEL,
                                            props.dealerStepMoreContent
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};
