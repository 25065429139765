import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import {
    ShortcodeProviders,
    ShortcodeService,
} from '../shortcode-service/shortcode-service';
import { DataLayerService } from '../data-layer-service/data-layer-service';
import { LogService } from '../log-service/log-service';

export interface Event {
    eventName: string;
    directCallName: string;
    eventProperties: EventProperty[];
}

export interface EventProperty {
    propertyDescriptor: string;
    value: string;
}
export interface ReferralExit {
    url: string;
    pageName: string;
}

async function asyncForEach<T>(array: T[], callback: Function) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export class NewAnalyticsService {
    public constructor(
        private targetViewName?: string,
        private shortcodeProviders?: ShortcodeProviders
    ) {}

    public async getAnalyticsConfig(): Promise<any | null> {
        const { currentCountryCode, brand } = new AppConfigurationService();
        try {
            const response = await HttpService.get(
                process.env.REACT_APP_AEM_BASE_URL +
                    `/content/experience-fragments/global-owner/${brand}/${currentCountryCode}/config/analytics/analytics-config/master.model.json`,
                true
            );
            return response?.data[':items']['root'][':items'][
                'analyticsconfig'
            ];
        } catch (e) {
            console.error(
                `An error occurred while trying to get analytics config. Status Text: ${
                    (e as Error).message
                }`
            );
            return null;
        }
    }

    public async fireEvents(
        eventNames: string | string[],
        clearDigitaldata = true,
        skipFireTargetAnalytics: boolean
    ) {
        try {
            if (
                typeof (window as any)._satellite === 'undefined' ||
                typeof (window as any)._satellite.track === 'undefined'
            ) {
                setTimeout(() => {
                    this.fireEvents(
                        eventNames,
                        clearDigitaldata,
                        skipFireTargetAnalytics
                    );
                }, 250);
            } else {
                const events = Array.isArray(eventNames)
                    ? eventNames
                    : [eventNames];
                if (clearDigitaldata) {
                    (window as any).digitaldata = {};
                }
                DataLayerService.populatePersistentProperties();
                const eventNamesMap = new Map();
                const eventPromises = events.map(async event => {
                    const eventDataObj = await this.getEventProperties(event);
                    if (eventDataObj && eventDataObj.eventProperties !== null) {
                        eventNamesMap.set(
                            eventDataObj.eventName,
                            eventDataObj.directCallName
                        );
                        const eventProperties = eventDataObj.eventProperties;
                        const processedEventProperties: EventProperty[] = await this.handleShortcodes(
                            eventProperties
                        );
                        this.setPropertyDescriptor(processedEventProperties);
                        await DataLayerService.setProperties(
                            processedEventProperties,
                            (window as any).digitaldata
                        );
                        await this.fireTargetUpdateIfNeeded(
                            processedEventProperties,
                            skipFireTargetAnalytics
                        );
                    }
                });
                await Promise.all(eventPromises);
                events.forEach(event => {
                    const eventName = eventNamesMap.get(event) || event;
                    (window as any)._satellite.track(eventName);
                    LogService.log('Analytics', `Event Fired: "${event}"`);
                });
            }
        } catch (e) {
            console.error(`Error firing analytics event: ${eventNames}`);
            console.error(`Reason: ${(e as Error).message}`);
        }
    }

    private setPropertyDescriptor(processedEventProperties: EventProperty[]) {
        if (this.targetViewName) {
            processedEventProperties.push({
                propertyDescriptor: 'target.viewName',
                value: this.targetViewName,
            });
        }
    }

    private async getEventProperties(eventName: string): Promise<Event | null> {
        try {
            const config = await this.getAnalyticsConfig();
            const eventConfig = config['events'].filter(
                (e: any) => e['eventName'] === eventName
            )[0];
            if (typeof eventConfig !== 'undefined') {
                return eventConfig as Event;
            } else {
                return null;
            }
        } catch (e) {
            throw new Error(
                `An error occurred while trying to get analytics config for event "${eventName}". Status Text: ${
                    (e as Error).message
                }`
            );
        }
    }

    public async handleShortcodes(
        eventProperties: EventProperty[]
    ): Promise<EventProperty[]> {
        const processedEventProperties: EventProperty[] = [];
        await asyncForEach(
            eventProperties,
            async (eventProperty: EventProperty) => {
                const processedEventProperty: EventProperty = {
                    propertyDescriptor: eventProperty.propertyDescriptor.replace(
                        '*',
                        ''
                    ),
                    value: eventProperty.value,
                };
                try {
                    processedEventProperty.value = await new ShortcodeService(
                        this.shortcodeProviders
                    ).processShortcodes(processedEventProperty.value);
                    processedEventProperties.push(processedEventProperty);
                } catch (e) {
                    if (
                        eventProperty.propertyDescriptor[
                            eventProperty.propertyDescriptor.length - 1
                        ] === '*'
                    ) {
                        LogService.log(
                            'Analytics',
                            `Property "${
                                processedEventProperty.propertyDescriptor
                            }" was marked as conditional, and could not be populated. Reason: ${
                                (e as Error).message
                            }`
                        );
                    } else {
                        LogService.log(
                            'Analytics',
                            `Property "${
                                processedEventProperty.propertyDescriptor
                            }" was NOT marked as conditional, and could not be populated. Reason: ${
                                (e as Error).message
                            }`
                        );
                    }
                }
            }
        );
        return processedEventProperties;
    }

    private async fireTargetUpdateIfNeeded(
        eventProperties: EventProperty[],
        skipFireTargetAnalytics: boolean
    ) {
        const targetRelProps: string[] = [
            'target.selectedVehicle',
            'target.deliveryStatus',
        ];
        if (skipFireTargetAnalytics) return;
        const fireTargetUpdateConditionally = false;
        if (
            !fireTargetUpdateConditionally ||
            eventProperties
                .map(ep => ep.propertyDescriptor)
                .filter(pd => targetRelProps.includes(pd)).length > 0
        ) {
            (window as any)._satellite.track('target-update');
        }
    }

    public async findMatchingReferralExit(
        url: string
    ): Promise<ReferralExit | undefined> {
        const config = await this.getAnalyticsConfig();
        url = encodeURI(url);
        if (config) {
            const referralExits = config['referralExits'] as ReferralExit[];
            return referralExits?.find(
                referralExit =>
                    encodeURI(referralExit.url) === url ||
                    (url.indexOf('?vin=') !== -1 &&
                        url.indexOf(encodeURI(referralExit.url)) !== -1)
            );
        }
    }

    public static fireReferralExitEventBasedOnUrl(
        url: string,
        fireEvents: Function
    ) {
        new NewAnalyticsService()
            .findMatchingReferralExit(url)
            .then(referralExit => {
                NewAnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            });
    }

    public static fireReferralExitsEvent(
        fireEvents: Function,
        referralExit?: ReferralExit
    ) {
        if (referralExit && referralExit.pageName) {
            fireEvents(
                'referral-exit-event',
                undefined,
                { referredUrlPageName: referralExit.pageName },
                false
            );
        }
    }
}
