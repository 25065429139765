import React, { useEffect, useState } from 'react';
import { Accordion, PrimaryButton } from '../../../common';
import './dealer-filter-options-ford.scss';
import { useDealerStep } from '../../../../hooks/owners-osb';
import closeIcon from '../../../../assets/owners-osb/close-icon.png';

import {
    FilterServicesInfo,
    ServiceInfo,
} from '../../../../models/osb-model/osb-dealerservice-info';
import {
    DealerProfileInfo,
    FilterServiceAvailabilityInfo,
} from '../../../../models/osb-model/osb-dealer-info';
import { DEALER_STEP_KEYS } from '../../owners-osb/osb-constant';
import { OsbServiceRenderer } from '../../owners-osb/common/osb-service-renderer/osb-service-renderer';

import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../price-calculator-constant';
import { getObjectFromAEMJson } from '../../owners-osb/osb-utils';
import { ViewportUtil } from '../../../utils/viewport-util/viewport-util';
import serverSideService from '../../../../services/server-side-service/server-side-service';

interface Props {
    toggleLightDealerFilterOptions?: () => void;
    toggleLightDealerFilterModel?: () => void;
    openDealerFilterModel?: boolean;
    priceCalculatorResultContent: any;
    refreshDealerDetailsList?: (dealerProfiles: DealerProfileInfo[]) => void;
}

export const DealerFilterOptions = (props: Props) => {
    const { isMobileView } = ViewportUtil();
    const { osbDealerStep, setOSBDealerStepPayload } = useDealerStep();

    const [dealerFilterServices, setDealerFilterServices] = useState<
        FilterServicesInfo[]
    >();

    const [selectedFilterServices, setSelectedFilterServices] = useState<
        FilterServicesInfo[]
    >(osbDealerStep.selectedFilterServices);
    const [filteredDealerProfiles, setFilteredDealerProfiles] = useState<
        DealerProfileInfo[]
    >(osbDealerStep.filteredDealerProfiles);
    const [noDealersMessage, setNoDealersMessage] = useState<string>('');

    const getFilterServicesList = (
        servicesList: FilterServicesInfo[],
        serviceFilterType: string
    ) => {
        const filterServicesInfoList: FilterServicesInfo[] = [];
        if (servicesList) {
            servicesList.forEach(service => {
                const filterServices: FilterServicesInfo = {
                    serviceKey: service.serviceKey,
                    serviceName: service.serviceName,
                    serviceDescription: service.serviceDescription,
                    serviceFilterType: serviceFilterType,
                    isChecked: false,
                };
                filterServicesInfoList.push(filterServices);
            });
        }

        return filterServicesInfoList;
    };

    useEffect(() => {
        if (isMobileView) {
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
        const filterDeliveryServices = getFilterServicesList(
            osbDealerStep.filterDeliveryServices,
            DEALER_STEP_KEYS.DEALER_FILTER_DELIVERY_SERVICES
        );
        const filterRegularServices = getFilterServicesList(
            osbDealerStep.filterRegularServices,
            DEALER_STEP_KEYS.DEALER_FILTER_REGULAR_SERVICES
        );
        const filterDIDServices = getFilterServicesList(
            osbDealerStep.filterDidServices,
            DEALER_STEP_KEYS.DEALER_FILTER_DID_SERVICES
        );

        setDealerFilterServices(
            filterDeliveryServices
                .concat(filterRegularServices)
                .concat(filterDIDServices)
        );
    }, [osbDealerStep.selectedFilterServices]);

    const removeFilterServices = (service: FilterServicesInfo) => {
        let filterServicesAfterRemoval: FilterServicesInfo[] = [];
        if (selectedFilterServices) {
            filterServicesAfterRemoval = selectedFilterServices?.filter(
                (item: FilterServicesInfo) =>
                    item.serviceKey !== service.serviceKey
            );
        }
        setSelectedFilterServices(filterServicesAfterRemoval);
    };

    const handleFilterServicesSelection = (
        e: boolean,
        service: ServiceInfo
    ) => {
        setNoDealersMessage('');
        const filterService: FilterServicesInfo = {
            serviceKey: service.serviceKey,
            serviceName: service.name,
            serviceDescription: service.description,
            serviceFilterType: service.serviceFilterType ?? '',
            isChecked: e,
        };

        if (e) {
            const existingService = selectedFilterServices?.find(
                serviceItem =>
                    serviceItem.serviceKey === filterService.serviceKey
            );
            if (!existingService) {
                selectedFilterServices
                    ? setSelectedFilterServices([
                          ...selectedFilterServices,
                          filterService,
                      ])
                    : setSelectedFilterServices([filterService]);
            }
        } else {
            removeFilterServices(filterService);
        }
    };

    const getTransformedFilterServices = (
        filterServicesInfoList: FilterServicesInfo[]
    ) => {
        const transformedFilterServices: ServiceInfo[] = [];
        if (filterServicesInfoList && filterServicesInfoList.length > 0) {
            filterServicesInfoList.forEach((service: FilterServicesInfo) => {
                const serviceData: ServiceInfo = {
                    serviceKey: service.serviceKey,
                    serviceId: 0,
                    name: service.serviceName,
                    price: 0,
                    priceAfterDiscount: 0,
                    additionalInfo: {
                        offer: '',
                        subType: '',
                        serviceType: '',
                        sortOrder: '',
                    },
                    description: service.serviceDescription,
                    isChecked: service.isChecked,
                    type: '',
                    subType: service.serviceFilterType,
                    serviceUniqueId: service.serviceKey,
                    serviceFilterType: service.serviceFilterType,
                };
                transformedFilterServices.push(serviceData);
            });
        }
        return transformedFilterServices;
    };

    const isDealerFilterServiceSelected = (serviceName: string) => {
        if (selectedFilterServices.length <= 0) {
            return false;
        }
        const selectedService = selectedFilterServices?.find(
            item => item.serviceName === serviceName
        );
        return !!selectedService;
    };

    const showFilterServicesCheckboxGroup = (
        filterServicesInfoList: FilterServicesInfo[]
    ) => {
        const transformedFilterServices: ServiceInfo[] = getTransformedFilterServices(
            filterServicesInfoList
        );
        return (
            <div className="services-list">
                <OsbServiceRenderer
                    servicesList={transformedFilterServices}
                    handleServiceSelection={handleFilterServicesSelection}
                    hasDealerFilterServiceSelected={
                        isDealerFilterServiceSelected
                    }
                    hideServicePriceSection={true}
                />
            </div>
        );
    };

    const handleResetFilterServices = () => {
        setNoDealersMessage('');
        setSelectedFilterServices([]);
        props.refreshDealerDetailsList?.(osbDealerStep.dealerProfiles);
        setOSBDealerStepPayload({
            filteredDealerProfiles: osbDealerStep.dealerProfiles,
            selectedFilterServices: [],
        });
    };

    const filterDealers = (
        filterServiceAvailabilityInfo: FilterServiceAvailabilityInfo[],
        filterServicesInfo: FilterServicesInfo[],
        dealer: DealerProfileInfo
    ) => {
        const filterDateTimeInfo: FilterServicesInfo[] = [];
        const filterServicesWithoutDateTime: FilterServicesInfo[] = filterServicesInfo.filter(
            service => {
                if (service.serviceFilterType === 'dateTimeService') {
                    filterDateTimeInfo.push(service);
                    return false;
                } else {
                    return true;
                }
            }
        );

        const hasDealers = filterServicesWithoutDateTime.every(service => {
            if (
                service.serviceFilterType !=
                DEALER_STEP_KEYS.DEALER_FILTER_DID_SERVICES
            ) {
                return filterServiceAvailabilityInfo.find(
                    filter => service.serviceKey === filter.filterKey
                );
            } else {
                const hasDID = dealer.specialServices?.find(
                    item => item === service.serviceName
                );
                return !!hasDID;
            }
        });

        const hasDateTimeServices = filterDateTimeInfo.every(service => {
            let hasDTS = false;
            if (service.serviceKey === 'am') {
                hasDTS = !!dealer.additionalInfo.nextAppointmentDateAm;
            } else if (service.serviceKey === 'pm') {
                hasDTS = !!dealer.additionalInfo.nextAppointmentDatePm;
            } else if (service.serviceKey === 'weekend') {
                hasDTS = !!dealer.additionalInfo.nextAppointmentDateWeekend;
            }
            return hasDTS;
        });

        return hasDealers && hasDateTimeServices;
    };

    const handleDealerFilterApplyClick = () => {
        const filteredDealerProfiles: DealerProfileInfo[] = [];

        const selectedFilters: string[] = [];

        if (selectedFilterServices && selectedFilterServices.length > 0) {
            osbDealerStep.dealerProfiles.forEach(dealer => {
                const hasDealers = filterDealers(
                    dealer.additionalInfo.dealerFilterServiceAvailability,
                    selectedFilterServices,
                    dealer
                );
                if (hasDealers) {
                    filteredDealerProfiles.push(dealer);
                }
            });

            setFilteredDealerProfiles(filteredDealerProfiles);
            props.refreshDealerDetailsList?.(filteredDealerProfiles);

            setOSBDealerStepPayload({
                filteredDealerProfiles: filteredDealerProfiles,
                selectedFilterServices: selectedFilterServices,
            });

            selectedFilterServices.forEach((serviceFilter: any) => {
                const productString = `${serviceFilter.serviceFilterType.toLowerCase()}:${
                    serviceFilter.serviceName
                }`;
                selectedFilters.push(productString);
            });
        } else {
            handleResetFilterServices();
        }

        if (
            filteredDealerProfiles.length <= 0 &&
            selectedFilterServices &&
            selectedFilterServices.length > 0
        ) {
            if (serverSideService.isClientSide()) {
                window.scrollTo(0, document.body.scrollHeight);
            }
            setNoDealersMessage(
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_ERROR_MSG,
                    props.priceCalculatorResultContent
                )
            );
        } else if (props.openDealerFilterModel) {
            props.toggleLightDealerFilterModel?.();
        } else {
            props.toggleLightDealerFilterOptions?.();
        }
    };

    const handleFilterClose = () => {
        if (
            filteredDealerProfiles.length <= 0 &&
            osbDealerStep.selectedFilterServices &&
            osbDealerStep.selectedFilterServices.length > 0
        ) {
            handleResetFilterServices();
        }

        if (props.openDealerFilterModel) {
            props.toggleLightDealerFilterModel?.();
        } else {
            props.toggleLightDealerFilterOptions?.();
        }
    };

    return (
        <div className="dealer-filter-options-container">
            {!isMobileView && <div className="full-hr-line-style"></div>}
            <div className="filters-header-container">
                <div className="filters-header-reset">
                    <div className="filters-header">
                        {getObjectFromAEMJson(
                            PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_HEADING,
                            props.priceCalculatorResultContent
                        )}
                    </div>
                    <div
                        className="filters-reset"
                        onClick={handleResetFilterServices}
                    >
                        {getObjectFromAEMJson(
                            PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_RESET,
                            props.priceCalculatorResultContent
                        )}
                    </div>
                </div>
                <div className="filter-close" onClick={handleFilterClose}>
                    <img
                        src={closeIcon}
                        className="close-icon"
                        alt="close-icon"
                    />
                </div>
            </div>
            <div className={`accordion-filter-container  brand-ford`}>
                {dealerFilterServices && dealerFilterServices.length > 0 && (
                    <div className="delivery-services-accordion">
                        <Accordion
                            index="1"
                            className=""
                            header={getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_AVAILABLE_SERVICES_HEADING,
                                props.priceCalculatorResultContent
                            )}
                            panel={
                                dealerFilterServices &&
                                showFilterServicesCheckboxGroup(
                                    dealerFilterServices
                                )
                            }
                            expandMultiplePanels={true}
                            expandPanel={true}
                            borderBottom={false}
                        />
                    </div>
                )}
            </div>
            <div className="apply-button-container">
                {noDealersMessage && (
                    <div className="no-dealer-message">{noDealersMessage}</div>
                )}
                <div className="apply-button-style">
                    <PrimaryButton
                        color={'dark'}
                        fill={'fill'}
                        ariaLabel={'apply-button'}
                        chevron={false}
                        disabled={!!noDealersMessage}
                        onClick={handleDealerFilterApplyClick}
                    >
                        {getObjectFromAEMJson(
                            PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_APPLY_BUTTON,
                            props.priceCalculatorResultContent
                        )}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};
