import { useEffect, useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import {
    StepProgressBar,
    StepProgressBarDetail,
    SwipeLocationCoordinates,
} from '../../models/osb-model/osb-progress-bar';
import {
    StoreState,
    StepProgressBarState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import {
    OSB_CLIENT_STORAGE_KEYS,
    STEP_PROGRESS_BAR_KEYS,
} from '../../components/sections/owners-osb/osb-constant';
import { useOSBStep, useViewport } from '../../hooks/owners-osb';
import {
    getOsbAppContent,
    setClientStorageValue,
    getClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';

export const getInitialProgressBarState = (): StepProgressBar => {
    return {
        progressBarDetails: [
            {
                key: STEP_PROGRESS_BAR_KEYS.DEALER,
                stepName: '',
                isActive: true,
                hasChanged: false,
                isEditable: true,
                navigationPath: '',
            },
            {
                key: STEP_PROGRESS_BAR_KEYS.SERVICE,
                stepName: '',
                isActive: false,
                hasChanged: false,
                isEditable: false,
                navigationPath: '',
            },
            {
                key: STEP_PROGRESS_BAR_KEYS.DELIVERY,
                stepName: '',
                isActive: false,
                hasChanged: false,
                isEditable: false,
                navigationPath: '',
            },
            {
                key: STEP_PROGRESS_BAR_KEYS.DATETIME,
                stepName: '',
                isActive: false,
                hasChanged: false,
                isEditable: false,
                navigationPath: '',
            },
            {
                key: STEP_PROGRESS_BAR_KEYS.CONTACT,
                stepName: '',
                isActive: false,
                hasChanged: false,
                isEditable: false,
                navigationPath: '',
            },
        ],
        touchStartLocation: {
            x: 0,
            y: 0,
        },
        isSwipeLeft: false,
        isSwipeRight: false,
        swipeStartIndex: 0,
        swipeEndIndex: STEP_PROGRESS_BAR_KEYS.MOBILE_MAX_STEP_COUNT - 1,
        isMobileView: false,
        isRetrieveFlow: false,
        mobileMaxStepCount: STEP_PROGRESS_BAR_KEYS.MOBILE_MAX_STEP_COUNT,
        isContentLoaded: false,
        isStepNameExceedFirstBreakpoint: false,
    };
};
//Configure and initialize calendar step state in global store
const configureStepProgressBarStore = () => {
    const actions = {
        SET_STEP_PROGRESS_BAR_PAYLOAD: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as StepProgressBarState).stepProgressBar,
                ...(payload as StepProgressBar),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_PROGRESS_BAR_STEP_KEY,
                newState,
                localStorageExpiry,
                true
            );
            return { stepProgressBar: newState };
        },

        RESET_STEP_PROGRESS_BAR: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            (payload as StepProgressBar).progressBarDetails.forEach(
                (step: StepProgressBarDetail) => {
                    step.isActive = false;
                    step.hasChanged = false;
                    step.isEditable = false;
                }
            );
            const newState = {
                ...(currentState as StepProgressBarState).stepProgressBar,
                ...(payload as StepProgressBar),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_PROGRESS_BAR_STEP_KEY,
                newState,
                localStorageExpiry,
                true
            );
            return { stepProgressBar: newState };
        },
    };
    initializeStore(actions, {
        stepProgressBar:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_PROGRESS_BAR_STEP_KEY
            ) || getInitialProgressBarState(),
    });
};

configureStepProgressBarStore();

// custom hook for manual calendar step to handle the actions and updating payload in global store
export const useStepProgressBar = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();
    const { isMobileView } = useViewport();

    const setGlobalStepProgressBarPayload = (
        payload:
            | {
                  [key: string]:
                      | boolean
                      | number
                      | SwipeLocationCoordinates
                      | StepProgressBarDetail[];
              }
            | StepProgressBarDetail
            | StepProgressBar,
        shouldReload = true
    ) => {
        dispatch('SET_STEP_PROGRESS_BAR_PAYLOAD', payload, shouldReload, 0);
    };

    /**
     * @param  {string} stepKeyToAdd Step to add
     * @param  {string} showStep Flag to decide whether to add/delete step
     * @param  {string} previousStepKey Optional param previous step name, decides if we need to add steps in between
     * @param  {string} stepKeyToDelete Optional param, Step to delete
     * @description This method will Add, Delete, Add & Delete steps from progress bar
     */
    const manageOsbStepVisibility = (
        stepKeyToAdd: string,
        showStep: boolean,
        previousStepKey = '',
        stepKeyToDelete = ''
    ) => {
        let progressBarDetailsToUpdate: StepProgressBarDetail[] = [];
        const stepProgressBarData = (globalState as StepProgressBarState)
            .stepProgressBar;

        if (showStep) {
            let osbUpdateStepDetail = null;

            const osbStepToUpdate = stepProgressBarData.progressBarDetails.find(
                step => step.key === stepKeyToAdd
            );

            // Add if step not exist in the list
            if (!osbStepToUpdate) {
                const previousStepIndex = stepProgressBarData.progressBarDetails.findIndex(
                    step => step.key === previousStepKey
                );

                if (previousStepIndex > -1) {
                    // check for previous step existence
                    //adding step in between other steps
                    osbUpdateStepDetail = {
                        key: stepKeyToAdd,
                        stepName: '',
                        isActive: false,
                        hasChanged: false,
                        isEditable: false,
                        navigationPath: '',
                    };
                    stepProgressBarData.progressBarDetails.splice(
                        previousStepIndex + 1,
                        0,
                        osbUpdateStepDetail
                    );
                } else {
                    //adding step to the end of steps
                    osbUpdateStepDetail = {
                        key: stepKeyToAdd,
                        stepName: '',
                        isActive: true,
                        hasChanged: true,
                        isEditable: true,
                        navigationPath: '',
                    };
                    stepProgressBarData.progressBarDetails.push(
                        osbUpdateStepDetail
                    );
                }
                setGlobalStepProgressBarPayload({
                    progressBarDetails: stepProgressBarData.progressBarDetails,
                    isContentLoaded: false,
                });
            }
        } else if (!stepKeyToDelete) {
            // Hide Step
            progressBarDetailsToUpdate = stepProgressBarData?.progressBarDetails?.filter(
                (progressBarStep: StepProgressBarDetail) => {
                    return progressBarStep.key !== stepKeyToAdd;
                }
            );

            setGlobalStepProgressBarPayload({
                progressBarDetails: progressBarDetailsToUpdate,
            });
        }

        if (stepKeyToDelete) {
            // Delete Step along with adding another step at the same time
            progressBarDetailsToUpdate = stepProgressBarData?.progressBarDetails?.filter(
                (progressBarStep: StepProgressBarDetail) => {
                    return progressBarStep.key !== stepKeyToDelete;
                }
            );
            setGlobalStepProgressBarPayload({
                progressBarDetails: progressBarDetailsToUpdate,
            });
        }
    };

    useEffect(() => {
        if (osbStep.isRetrieveFlow) {
            manageOsbStepVisibility(STEP_PROGRESS_BAR_KEYS.DEALER, false);
        }
        setGlobalStepProgressBarPayload({
            isMobileView: isMobileView,
            isRetrieveFlow: osbStep.isRetrieveFlow,
        });
    }, [isMobileView, osbStep.isRetrieveFlow]);

    const resetStepProgressBar = (shouldReload = true) => {
        dispatch(
            'RESET_STEP_PROGRESS_BAR',
            (globalState as StepProgressBarState).stepProgressBar,
            shouldReload,
            0
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await getOsbAppContent(modelName);
    }, []);

    function handleConditionA(stepIndex: any, stepProgressBarData: any) {
        // logic for conditionA
        if (
            stepIndex >= 0 &&
            stepIndex < stepProgressBarData.progressBarDetails.length
        ) {
            const currentStepDetail =
                stepProgressBarData.progressBarDetails[stepIndex];
            currentStepDetail.hasChanged = true;
            if (
                !osbStep.isRetrieveFlow &&
                stepIndex < stepProgressBarData.progressBarDetails.length - 1
            ) {
                const nextStepDetail =
                    stepProgressBarData.progressBarDetails[stepIndex + 1];
                nextStepDetail.isActive = true;
                nextStepDetail.isEditable = true;
            }
            setGlobalStepProgressBarPayload(currentStepDetail);
        }
    }

    function handleConditionB(
        showSelectedStep: any,
        stepIndex: any,
        stepProgressBarData: any
    ) {
        // logic for conditionB
        if (showSelectedStep) {
            const currentStepDetail =
                stepProgressBarData.progressBarDetails[stepIndex];
            const initialStepDetail = stepProgressBarData.progressBarDetails[0];
            currentStepDetail.isActive = true;
            //set the initial step to be editable
            if (!initialStepDetail.isEditable) {
                initialStepDetail.isEditable = true;
            }
            setGlobalStepProgressBarPayload(currentStepDetail);
        } else if (
            //logic to change status of progress bar on moving backward
            stepIndex >= 1 &&
            stepIndex < stepProgressBarData.progressBarDetails.length
        ) {
            const previousStepDetail =
                stepProgressBarData.progressBarDetails[stepIndex - 1];
            previousStepDetail.isActive = true;
            setGlobalStepProgressBarPayload(previousStepDetail);
        }
    }

    function selectedStepForMobile(stepIndex: any, stepProgressBarData: any) {
        //This condition will execute  to show the selected step in the mobile screen,
        //when the change button in summary page is clicked
        if (stepIndex === 0) {
            // for first step
            setGlobalStepProgressBarPayload({
                isSwipeRight: true,
                isSwipeLeft: false,
                swipeStartIndex: stepIndex,
                swipeEndIndex:
                    stepIndex + (stepProgressBarData.mobileMaxStepCount - 1),
            });
        } else if (
            stepIndex ===
            stepProgressBarData.progressBarDetails.length - 1
        ) {
            //for Last step
            setGlobalStepProgressBarPayload({
                isSwipeRight: true,
                isSwipeLeft: false,
                swipeStartIndex:
                    stepIndex - (stepProgressBarData.mobileMaxStepCount - 1),
                swipeEndIndex: stepIndex,
            });
        } else {
            //For rest of the steps
            setGlobalStepProgressBarPayload({
                isSwipeRight: true,
                isSwipeLeft: false,
                swipeStartIndex: stepIndex - 1,
                swipeEndIndex:
                    stepProgressBarData.mobileMaxStepCount !==
                    STEP_PROGRESS_BAR_KEYS.MOBILE_MAX_STEP_COUNT
                        ? stepIndex
                        : stepIndex + 1,
            });
        }
    }

    const updateProgressBarStatus = (
        stepKey: string,
        isMoveForward: boolean,
        showSelectedStep = false
    ) => {
        const stepProgressBarData = (globalState as StepProgressBarState)
            .stepProgressBar;
        const stepIndex = stepProgressBarData.progressBarDetails.findIndex(
            (step: StepProgressBarDetail) => step.key === stepKey
        );

        stepProgressBarData.progressBarDetails.forEach(
            (step: StepProgressBarDetail) => {
                if (step.isActive) {
                    step.isActive = false;
                }
            }
        );

        if (stepIndex > -1) {
            if (isMoveForward) {
                //logic to change status of progress bar on moving forward
                handleConditionA(stepIndex, stepProgressBarData);
                // Move the steps automatically on forward button click for mobile
            } else {
                // logic to make the current navigated step, active and editable
                handleConditionB(
                    showSelectedStep,
                    stepIndex,
                    stepProgressBarData
                );
                if (isMobileView) {
                    //Logic to move the steps automatically according to differenct scenarios in mobile
                    if (showSelectedStep) {
                        selectedStepForMobile(stepIndex, stepProgressBarData);
                    } // Move the steps automatically on backward button click for mobile
                    else if (
                        stepIndex >=
                            stepProgressBarData.mobileMaxStepCount - 2 &&
                        stepIndex <
                            stepProgressBarData.progressBarDetails.length - 1
                    ) {
                        setGlobalStepProgressBarPayload({
                            isSwipeRight: true,
                            isSwipeLeft: false,
                            swipeStartIndex: stepIndex - 1,
                            swipeEndIndex:
                                stepProgressBarData.mobileMaxStepCount !==
                                STEP_PROGRESS_BAR_KEYS.MOBILE_MAX_STEP_COUNT
                                    ? stepIndex
                                    : stepIndex + 1,
                        });
                    }
                }
            }
        }
    };

    return {
        osbStepProgressBar: (globalState as StepProgressBarState)
            .stepProgressBar,
        setOSBStepProgressBarPayload: setGlobalStepProgressBarPayload,
        resetStepProgressBar: resetStepProgressBar,
        updateProgressBarStatus: updateProgressBarStatus,
        callContentService: callContentService,
        manageOsbStepVisibility: manageOsbStepVisibility,
    };
};
