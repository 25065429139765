import {
    BookedServices,
    RetrieveBookingResponse,
} from '../../../../models/osb-model/osb-retrieve-booking';
import {
    AdditionalInfo,
    Bookings,
    BookingsInfo,
    DealerDetails,
    MainServiceInfo,
    PersonalDetail,
    ServiceInfo,
    VehicleDetails,
} from '../../../../models/osb-model/osb-retrieve-booking-step';
import {
    CONTACT_OPTIONS,
    SERVICE_FLOW,
    SERVICE_GROUP_TYPE,
} from '../osb-constant';

//outside
const additionalInfo: AdditionalInfo = {
    offer: '',
    subType: '',
};

const getEmailID = (retrieveAPIResponse: RetrieveBookingResponse): string => {
    for (const contactOptions of retrieveAPIResponse.customers[0]
        .contactOptions) {
        if (contactOptions.type === CONTACT_OPTIONS.EMAIL)
            return contactOptions.value;
    }
    return '';
};

const getPhone = (retrieveAPIResponse: RetrieveBookingResponse): string => {
    for (const contactOptions of retrieveAPIResponse.customers[0]
        .contactOptions) {
        if (contactOptions.type === CONTACT_OPTIONS.PHONE)
            return contactOptions.value;
    }
    return '';
};

const getAdditionalInfo = (service: BookedServices): AdditionalInfo => {
    const additionalInfo: AdditionalInfo = {
        offer: service.offer ? service.offer : '',
        subType: service.subType ? service.subType : '',
    };
    return additionalInfo;
};

const getServiceType = (serviceType: string): string => {
    switch (serviceType) {
        case SERVICE_GROUP_TYPE.MOT:
            return SERVICE_FLOW.MOT_SERVICE;
        case SERVICE_GROUP_TYPE.REPAIR:
            return SERVICE_FLOW.REPAIR_SERVICE;
        case SERVICE_GROUP_TYPE.VALUE:
            return SERVICE_FLOW.VALUE_SERVICE;
        case SERVICE_GROUP_TYPE.ADDITIONAL:
            return SERVICE_FLOW.ADDITIONAL_SERVICE;
        case SERVICE_GROUP_TYPE.MAIN:
            return SERVICE_FLOW.MAIN_SERVICE;
        default:
            return '';
    }
};

const getOldServicesList = (
    retrieveAPIResponse: RetrieveBookingResponse
): ServiceInfo[] => {
    const oldServicesList: ServiceInfo[] = [];
    for (const service of retrieveAPIResponse.services) {
        if (
            service.type === SERVICE_GROUP_TYPE.MOT ||
            service.type === SERVICE_GROUP_TYPE.REPAIR ||
            service.type === SERVICE_GROUP_TYPE.VALUE
        ) {
            const serviceInfo: ServiceInfo = {
                serviceId: service.serviceId,
                serviceCode: service.serviceId.toString(),
                serviceType: getServiceType(service.type),
                name: service.name,
                serviceName: service.name,
                price: service.prices[0].price.basePrice,
                priceAfterDiscount: service.prices[0].price.priceAfterDiscount,
                additionalInfo: getAdditionalInfo(service),
                description: service.description ?? '',
                isChecked: service.selected,
                length: 0,
                type: service.type,
            };
            oldServicesList.push(serviceInfo);
        }
    }
    return oldServicesList;
};

const getAdditionalServicesList = (
    retrieveAPIResponse: RetrieveBookingResponse
): ServiceInfo[] => {
    const additionalServicesList: ServiceInfo[] = [];
    for (const service of retrieveAPIResponse.services) {
        if (service.type === SERVICE_GROUP_TYPE.ADDITIONAL) {
            const serviceInfo: ServiceInfo = {
                comment: service.comment,
                serviceId: service.serviceId,
                serviceCode: service.serviceId.toString(),
                serviceType: getServiceType(service.type),
                name: service.name,
                serviceName: service.name,
                price: service.prices[0].price.basePrice,
                priceAfterDiscount: service.prices[0].price.priceAfterDiscount,
                additionalInfo: getAdditionalInfo(service),
                description: service.description ? service.description : '',
                isChecked: service.selected,
                length: 0,
                type: service.type,
            };
            additionalServicesList.push(serviceInfo);
        }
    }
    return additionalServicesList;
};

const getMainServiceInfo = (
    retrieveAPIResponse: RetrieveBookingResponse
): MainServiceInfo => {
    const mainServiceInfo: MainServiceInfo = {
        serviceId: 0,
        serviceCode: '0',
        serviceType: '',
        name: '',
        serviceName: '',
        price: 0,
        priceAfterDiscount: 0,
        additionalInfo: additionalInfo,
        description: '',
        serviceOption: '',
        isChecked: false,
        length: 0,
        type: '',
    };
    for (const service of retrieveAPIResponse.services) {
        if (service.type === SERVICE_GROUP_TYPE.MAIN) {
            const mainServiceInfo: MainServiceInfo = {
                serviceId: service.serviceId,
                serviceCode: service.serviceId.toString(),
                serviceType: getServiceType(service.type),
                name: service.name,
                serviceName: service.name,
                price: service.prices[0].price.basePrice,
                priceAfterDiscount: service.prices[0].price.priceAfterDiscount,
                additionalInfo: getAdditionalInfo(service),
                description: service.description ? service.description : '',
                serviceOption: '',
                isChecked: service.selected,
                length: 0,
                type: service.type,
                serviceUniqueId: '',
                subType: service.subType,
                brightCoveAccountId: '',
                brightCoveVideoId: '',
                brightCovePlayerId: '',
                brightCoveVideoName: '',
            };
            return mainServiceInfo;
        }
    }
    return mainServiceInfo;
};
const getPersonalDetails = (retrieveAPIResponse: RetrieveBookingResponse) => {
    const personalDetails = {
        lastName: retrieveAPIResponse.customers[0].lastName ?? '',
        fordId: retrieveAPIResponse.customers[0].fordId ?? '',
        title: retrieveAPIResponse.customers[0].title ?? '',
        contactNumber: getPhone(retrieveAPIResponse) ?? '',
        firstName: retrieveAPIResponse.customers[0].firstName ?? '',
        email: getEmailID(retrieveAPIResponse) ?? '',
        phone: getPhone(retrieveAPIResponse) ?? '',
    };

    return personalDetails;
};

const getDealerDetails = (retrieveAPIResponse: RetrieveBookingResponse) => {
    const dealerDetails: DealerDetails = {
        country: retrieveAPIResponse?.dealer?.dealerProfile?.country ?? '',
        dealerCode:
            retrieveAPIResponse?.dealer?.dealerProfile?.dealerCode ?? '',
        dealerName: retrieveAPIResponse?.dealer?.dealerProfile?.dealerName,
        district: '',
        email: '',
        phone: retrieveAPIResponse.dealer?.dealerProfile?.phone ?? '',
        postalCode:
            retrieveAPIResponse?.dealer?.dealerProfile?.postalCode ?? '',
        street: retrieveAPIResponse?.dealer?.dealerProfile?.street ?? '',
        town: retrieveAPIResponse?.dealer?.dealerProfile?.town ?? '',
        openingHours: [],
        vehicleDeliveryTypes: retrieveAPIResponse?.dealer?.vehicleDeliveryTypes,
    };

    return dealerDetails;
};

const getVehicleDetails = (retrieveAPIResponse: RetrieveBookingResponse) => {
    const vehicleDetails: VehicleDetails = {
        bodyStyle: retrieveAPIResponse.vehicle.bodyStyle ?? '',
        buildDate: retrieveAPIResponse.vehicle.buildDate ?? '',
        color: retrieveAPIResponse.vehicle.color ?? '',
        engine: retrieveAPIResponse.vehicle.engine ?? '',
        fuelType: retrieveAPIResponse.vehicle.fuelType ?? '',
        mileage: retrieveAPIResponse.vehicle.mileage ?? '',
        modelName: retrieveAPIResponse.vehicle.modelName ?? '',
        registrationNumber:
            retrieveAPIResponse.vehicle.registrationNumber ?? '',
        transmission: retrieveAPIResponse.vehicle.transmission ?? '',
        transmissionType: retrieveAPIResponse.vehicle.transmissionType ?? '',
        vehicleLineCode: retrieveAPIResponse.vehicle.vehicleLineCode ?? '',
        version: retrieveAPIResponse.vehicle.version ?? '',
        vin: retrieveAPIResponse.vehicle.vin ?? '',
    };

    return vehicleDetails;
};
//transforms the DSL API response into old Response expected by UI
export const transformRetrieveBookingResponse = (
    retrieveAPIResponse: RetrieveBookingResponse
): Bookings => {
    const personalDetail: PersonalDetail = getPersonalDetails(
        retrieveAPIResponse
    );
    const dealerDetails: DealerDetails = getDealerDetails(retrieveAPIResponse);
    const vehicleDetails: VehicleDetails = getVehicleDetails(
        retrieveAPIResponse
    );
    const bookingInfo: BookingsInfo = {
        oldServices: getOldServicesList(retrieveAPIResponse),
        additionalServices: getAdditionalServicesList(retrieveAPIResponse),
        mainService: getMainServiceInfo(retrieveAPIResponse),
        appointmentDateTimeLocal: retrieveAPIResponse.appointmentDateTimeLocal,
        mobileServiceVanAppointmentTimeRange:
            retrieveAPIResponse.mobileServiceVanAppointmentTimeRange,
        bookingReferenceNumber:
            retrieveAPIResponse.customers[0].customerReferenceNumber,
        comments: retrieveAPIResponse.customerAnnotation ?? '',
        source: retrieveAPIResponse.source,
        customer: personalDetail,
        dealer: dealerDetails,
        vehicleDetails: vehicleDetails,
        vehicleLineDescription:
            retrieveAPIResponse.vehicle.vehicleLineDescription,
        accesscode: '',
        vehicleLocation:
            Array.isArray(retrieveAPIResponse.vehicleLocations) &&
            retrieveAPIResponse.vehicleLocations.length > 0
                ? retrieveAPIResponse.vehicleLocations[0]
                : undefined,
        campaignStates: retrieveAPIResponse.campaignStates,
    };

    //Creating Transformed Response
    const bookings: Bookings = {
        bookings: [bookingInfo],
    };
    return bookings;
};
