import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';
import mapStyles from '../mapStyles';
import { DealerProfileInfo } from '../../../../../models/osb-model/osb-dealer-info';
import './osb-map.scss';
import { useDealerStep } from '../../../../../hooks/owners-osb';
import { ViewportUtil } from '../../../../utils/viewport-util/viewport-util';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { DEALER_STEP_KEYS } from '../../osb-constant';

interface Props {
    dealers: DealerProfileInfo[];
    isDealerIndexValue?: number;
    isPcflow?: boolean;
    zoomControl?: boolean;
    fullscreenControl?: boolean;
    zoomControlPosition?: string;
    fullscreenControlPosition?: string;
    leftShiftDegree?: number; // added for left shift degree
    upShiftDegree?: number; // added for up shift degree
    zoom?: number; // added for zoom level
    selectedDealerCode?: string;
    onDealerClick?: (dealerCode: string) => void;
    newUIDesign?: boolean;
    preferredDealerIds?: string[];
}
export const OsbMap = (props: Props) => {
    const mapRef = React.useRef<google.maps.Map>();
    const { osbDealerStep } = useDealerStep();
    const dealers = props.dealers;
    let center;
    const selectedDealer = dealers.find(
        dealer => dealer.dealerCode === props.selectedDealerCode
    );
    center = selectedDealer
        ? selectedDealer.location
        : dealers[0]
        ? dealers[0].location
        : osbDealerStep.dealerProfiles[0].location;
    // Use the leftShiftDegree prop to shift the center of the map
    // Use the upShiftDegree prop to shift the center of the map to up
    if (props.leftShiftDegree || props.upShiftDegree) {
        center = {
            lat: center.lat - (props.upShiftDegree ?? 0),
            lng: center.lng - (props.leftShiftDegree ?? 0),
        };
    }
    const { isMobileView } = ViewportUtil();

    const mapContainerStyle = {
        width: '100%',
        height: '100%',
    };

    const positionMap: Record<string, google.maps.ControlPosition> = {
        BOTTOM_CENTER: google.maps.ControlPosition.BOTTOM_CENTER,
        BOTTOM_LEFT: google.maps.ControlPosition.BOTTOM_LEFT,
        BOTTOM_RIGHT: google.maps.ControlPosition.BOTTOM_RIGHT,
        LEFT_BOTTOM: google.maps.ControlPosition.LEFT_BOTTOM,
        LEFT_CENTER: google.maps.ControlPosition.LEFT_CENTER,
        LEFT_TOP: google.maps.ControlPosition.LEFT_TOP,
        RIGHT_BOTTOM: google.maps.ControlPosition.RIGHT_BOTTOM,
        RIGHT_CENTER: google.maps.ControlPosition.RIGHT_CENTER,
        RIGHT_TOP: google.maps.ControlPosition.RIGHT_TOP,
        TOP_CENTER: google.maps.ControlPosition.TOP_CENTER,
        TOP_LEFT: google.maps.ControlPosition.TOP_LEFT,
        TOP_RIGHT: google.maps.ControlPosition.TOP_RIGHT,
    };

    const mapPositionToValue = (
        position: string
    ): google.maps.ControlPosition =>
        positionMap[position] || google.maps.ControlPosition.TOP_CENTER;

    const locationCoordinate = {
        lat:
            osbDealerStep.geocoordinate.length > 0
                ? osbDealerStep.geocoordinate[0].lat
                : 0,
        lng:
            osbDealerStep.geocoordinate.length > 0
                ? osbDealerStep.geocoordinate[0].lng
                : 0,
    };
    // preferredDealerIds.includes(
    // dealer.dealerCode
    // )
    const getIcon = (isLocation?: boolean, dealerCode?: any) => {
        if (isLocation) {
            return {
                url: './icons/map-marker-current-location.png',
                scaledSize: new window.google.maps.Size(40, 50),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
                fillColor: 'red',
            };
        } else {
            return {
                url: props.preferredDealerIds?.includes(dealerCode)
                    ? './icons/map-marker-prefered-dealer-location.png'
                    : './icons/map-marker-dealer-location.svg',
                scaledSize: new window.google.maps.Size(40, 50),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
                fillColor: 'red',
            };
        }
    };
    const getLabel = (i: number) => {
        if (props.isDealerIndexValue) {
            return props.isDealerIndexValue.toString();
        } else {
            return (i + 1).toString();
        }
    };
    const getZoom = () => {
        switch (true) {
            case props.zoom != null:
                return props.zoom;
            case props.dealers.length === 1:
                return 10;
            case props.dealers.length === 5:
                return 11;
            default:
                return 10;
        }
    };

    const getOptions = {
        styles: mapStyles,
        disableDefaultUI: true,
        zoomControl: props.zoomControl ?? true,
        fullscreenControl: props.fullscreenControl ?? true,
        ...(props.zoomControlPosition && {
            zoomControlOptions: {
                position: mapPositionToValue(props.zoomControlPosition),
            },
        }),
        ...(props.fullscreenControlPosition && {
            fullscreenControlOptions: {
                position: mapPositionToValue(props.fullscreenControlPosition),
            },
        }),
    };

    const getSelectedDealerPosition = (index: number) => {
        const div = document.getElementById('dealerScrollView-' + index);
        div?.scrollIntoView({ block: 'start', inline: 'nearest' });
        const getHeight = (id: string) => {
            const element = document.getElementById(id);
            return element?.clientHeight ?? 0;
        };

        const topHeaderHeight = getHeight('euheaderId');
        const PcBannerHeight = getHeight('osbBannerId');
        const PcHeaderHeight = getHeight('pc-header-section');
        const isLastDealer = dealers.length === index + 1;
        const scrollToPosition = (top: number) => {
            if (serverSideService.isClientSide()) {
                window.scrollTo({ top, behavior: 'smooth' });
            }
        };
        const pcFlowScrollTop =
            PcBannerHeight + PcHeaderHeight + topHeaderHeight + 10;
        const calculateScrollTop = () => {
            if (isMobileView) {
                const topPos = div?.offsetTop;
                return topPos !== undefined
                    ? props.isPcflow
                        ? topPos - 300
                        : topPos - 170
                    : 0;
            } else {
                return isLastDealer
                    ? props.isPcflow
                        ? pcFlowScrollTop
                        : 300
                    : 0;
            }
        };
        const scrollTop = calculateScrollTop();
        scrollTop && scrollToPosition(scrollTop);
    };
    return (
        <div className="osb-map-container">
            <GoogleMap
                ref={mapRef}
                mapContainerStyle={mapContainerStyle}
                zoom={getZoom()}
                center={center}
                options={getOptions}
            >
                <Marker
                    key={DEALER_STEP_KEYS.LOCATION}
                    position={locationCoordinate}
                    icon={getIcon(true, '')}
                />
                {dealers.map((item, i) => {
                    return (
                        <React.Fragment key={item.dealerCode}>
                            <Marker
                                key={item.dealerCode}
                                position={item.location}
                                label={{
                                    text: getLabel(i),
                                    color: '#ffffff',
                                    fontFamily: 'FordF1Regular',
                                    fontSize: '20px',
                                    fontWeight: '500',
                                    className: 'osb-map-marker-position',
                                }}
                                icon={getIcon(false, item.dealerCode)}
                                onClick={() => {
                                    if (props.newUIDesign) {
                                        props.onDealerClick &&
                                            props.onDealerClick(
                                                item.dealerCode
                                            );
                                    } else {
                                        getSelectedDealerPosition(i);
                                    }
                                }}
                            />
                        </React.Fragment>
                    );
                })}
            </GoogleMap>
        </div>
    );
};
