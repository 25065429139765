import React, { useEffect, useState } from 'react';
import './osb-service-renderer-lincoln.scss';
import { InfoIconToolTip } from '../../../../common/info-icon-tooltip/info-icon-tooltip';
import {
    useOSBStep,
    useServiceStep,
    useVehicleStep,
    useViewport,
} from '../../../../../hooks/owners-osb';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import { getFormattedPrice, getObjectFromAEMJson } from '../../osb-utils';
import {
    SERVICE_FLOW,
    OSB_SERVICE_PRICE_TYPE,
    OSB_SERVICE_TYPE,
    DELIVERY_STEPS_KEYS,
} from '../../osb-constant';
import errorWarning from '../../../../../assets/error-warning.svg';
import {
    ServiceData,
    ServiceInfo,
} from '../../../../../models/osb-model/osb-dealerservice-info';
import parse from 'html-react-parser';
import { triggerServiceInfoTooltipClickAnalytics } from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { Button } from '@own/fds-react';

interface Props {
    servicesList: ServiceInfo[] | undefined;
    placeHolder?: string;
    className?: string;
    handleServiceSelection: (e: any, service: any) => void;
    defaultChecked?: boolean;
    hideServicePriceSection?: boolean;
    fsaDescription?: string[];
    vhaDescription?: string[];
    oilLifeDescription?: string;
    hasServiceSelected?: (serviceId: number) => boolean;
    hasDealerFilterServiceSelected?: (serviceName: string) => boolean;
    otherServiceDescInStore?: string;
    setOtherServiceDescInStore?: (payload: { [key: string]: string }) => void;
    serviceAemContent?: any;
    selectionControl?: string;
    newServiceStyle?: boolean;
    accordionName?: string;
    accordionTitle?: string;
    showDisclaimer?: boolean;
}
export const OsbServiceRenderer = (props: Props) => {
    const { isMobileView } = useViewport();
    const { osbStep } = useOSBStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbServiceStep } = useServiceStep();
    const [accordionPanelShow, setAccordionPanelShow] = useState(true);
    const [fireEvents] = useAnalytics();
    const [otherServiceDescLeft, setOtherServiceDescLeft] = useState(100);
    const handleChange = (event: any) => {
        const charCount = event.target.value.length;
        setOtherServiceDescLeft(
            SERVICE_FLOW.OTHER_SERVICE_DESC_MAX_LENGTH - charCount
        );
        props.setOtherServiceDescInStore?.({
            otherServiceDesc: event.target.value,
        });
    };

    useEffect(() => {
        if (props.otherServiceDescInStore) {
            const commentLength = props.otherServiceDescInStore?.length || 0;
            setOtherServiceDescLeft(
                SERVICE_FLOW.OTHER_SERVICE_DESC_MAX_LENGTH - commentLength
            );
        } else {
            setOtherServiceDescLeft(SERVICE_FLOW.OTHER_SERVICE_DESC_MAX_LENGTH);
        }
    }, [props.otherServiceDescInStore]);

    const getOfferDescription = (service: any) => {
        return service.additionalInfo.offer ? service.additionalInfo.offer : '';
    };

    const getRecallDescription = (service: any) => {
        if (
            service.additionalInfo?.subType &&
            service.additionalInfo.subType ===
                SERVICE_FLOW.SERVICE_SUBTYPE_RECALL
        ) {
            if (props?.fsaDescription && props?.fsaDescription?.length > 0) {
                return props.fsaDescription;
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const getVhaDescription = (service: any) => {
        if (
            service.additionalInfo?.subType &&
            service.additionalInfo.subType === SERVICE_FLOW.SERVICE_SUBTYPE_VHA
        ) {
            if (props?.vhaDescription && props?.vhaDescription?.length > 0) {
                return props.vhaDescription;
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const getOilLifeDescription = (service: any) => {
        if (
            service.additionalInfo?.subType &&
            service.additionalInfo.subType === SERVICE_FLOW.SERVICE_SUBTYPE_OIL
        ) {
            if (
                props?.oilLifeDescription &&
                props?.oilLifeDescription?.length > 0
            ) {
                return props.oilLifeDescription;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    const stripHtmlFromServiceDisclaimer = (serviceDisclaimer: string) => {
        if (serviceDisclaimer) {
            OsbUtilService.stripHtml(
                serviceDisclaimer
                    .substring(0, serviceDisclaimer.lastIndexOf('.'))
                    .replace('{', '<')
                    .replace('}', '>')
            );
            return serviceDisclaimer;
        }
    };

    function displayBasedOnPriceAfterDiscount(service: any): React.ReactNode {
        return (
            <>
                <div>
                    {getFormattedPrice(service.priceAfterDiscount, osbStep)}
                    {service.additionalInfo.priceDisclaimerSymbol && (
                        <sup>
                            {service.additionalInfo.priceDisclaimerSymbol}
                        </sup>
                    )}
                </div>
                <div className="hasDiscount">
                    <span>{getFormattedPrice(service.price, osbStep)}</span>
                    {service.additionalInfo.priceDisclaimerSymbol && (
                        <sup>
                            {service.additionalInfo.priceDisclaimerSymbol}
                        </sup>
                    )}
                </div>
            </>
        );
    }

    function displayBasedOnPrice(service: any): React.ReactNode {
        return (
            <>
                <span>{getFormattedPrice(service.price, osbStep)}</span>
                {service.additionalInfo.priceDisclaimerSymbol && (
                    <sup>{service.additionalInfo.priceDisclaimerSymbol}</sup>
                )}
            </>
        );
    }

    function priceDisplay(service: any): React.ReactNode {
        return OsbUtilService.hasDiscount(
            service.priceAfterDiscount,
            service.price
        )
            ? displayBasedOnPriceAfterDiscount(service)
            : displayBasedOnPrice(service);
    }

    function nonPoRPriceDisplay(service: any) {
        return (
            (Number(service.price) ===
                OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE ||
                Number(service.priceAfterDiscount) <
                    OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
                Number(service.priceAfterDiscount) ===
                    OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE) &&
            service.additionalInfo.serviceType !== OSB_SERVICE_TYPE.DROPOFF && (
                <label>
                    {osbStep.freeLabel}
                    <sup>{service.additionalInfo.priceDisclaimerSymbol}</sup>
                </label>
            )
        );
    }

    function nonPriceDisplay(service: any) {
        return Number(service.price) ===
            OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE
            ? displayBasedOnPrice(service)
            : nonPoRPriceDisplay(service);
    }
    const handleTooltipClick = (serviceName: string, nameplateData: string) => {
        triggerServiceInfoTooltipClickAnalytics(
            serviceName,
            nameplateData,
            fireEvents
        );
    };
    function isDropOff(service: ServiceInfo) {
        return (
            service?.additionalInfo?.serviceType?.toUpperCase() ===
            DELIVERY_STEPS_KEYS.DROP_OFF_SERVICE_TYPE.toUpperCase()
        );
    }
    function serviceAccordion(divName: string) {
        const serviceAccordionClass = document.getElementById(
            divName
        ) as HTMLInputElement;
        if (serviceAccordionClass.classList.contains('accordion-hide')) {
            serviceAccordionClass.classList.remove('accordion-hide');
            setAccordionPanelShow(true);
            serviceAccordionClass.classList.add('accordion-show');
        } else {
            serviceAccordionClass.classList.remove('accordion-show');
            setAccordionPanelShow(false);
            serviceAccordionClass.classList.add('accordion-hide');
        }
    }

    const getServiceDescription = (service: ServiceInfo, key: number) => {
        return (
            <div key={key}>
                {getRecallDescription(service) &&
                    getRecallDescription(service).length > 0 &&
                    getRecallDescription(service).map(desc => (
                        <div key={desc} className="service-disclaimers">
                            {desc}
                        </div>
                    ))}
                {getVhaDescription(service) &&
                    getVhaDescription(service).length > 0 &&
                    getVhaDescription(service).map(desc => (
                        <div key={desc} className="service-disclaimers">
                            {desc}
                        </div>
                    ))}

                {getOilLifeDescription(service) && (
                    <div className="service-disclaimers">
                        {getOilLifeDescription(service)}
                    </div>
                )}
                {stripHtmlFromServiceDisclaimer(
                    service.additionalInfo.serviceDisclaimer
                        ? service.additionalInfo.serviceDisclaimer
                        : ''
                ) && (
                    <div className="service-disclaimers">
                        {stripHtmlFromServiceDisclaimer(
                            service.additionalInfo.serviceDisclaimer
                                ? service.additionalInfo.serviceDisclaimer
                                : ''
                        )}
                    </div>
                )}
                {service.additionalInfo.subType ===
                    SERVICE_FLOW.OTHER_CUSTOMSERVICE &&
                    props.hasServiceSelected?.(service.serviceId) && (
                        <div className="otherServiceDesc-input">
                            <div>
                                {props.otherServiceDescInStore?.length === 0 ? (
                                    <img
                                        className="osb-error-icon"
                                        src={errorWarning}
                                        alt="error icon"
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div>
                                <textarea
                                    className={`otherServiceDec-input-textarea ${
                                        props.hasServiceSelected?.(
                                            service.serviceId
                                        ) &&
                                        props.otherServiceDescInStore
                                            ?.length === 0
                                            ? 'otherServiceDec-empty-error'
                                            : ''
                                    }`}
                                    data-testid="osb-otherServiceDec-field"
                                    aria-label="otherServiceDec"
                                    id={'osb-otherServiceDec-field'}
                                    name={'otherServiceDec'}
                                    placeholder={getObjectFromAEMJson(
                                        'placeHolderLabel',
                                        props.serviceAemContent
                                    )}
                                    onChange={handleChange}
                                    rows={3}
                                    cols={25}
                                    maxLength={100}
                                    value={props.otherServiceDescInStore}
                                />
                            </div>
                            <div className="otherServiceDec-status">
                                {parse(
                                    getObjectFromAEMJson(
                                        SERVICE_FLOW.OTHER_SERVICE_REMAIN_COMMENTS_CHAR,
                                        props.serviceAemContent
                                    )
                                )}{' '}
                                {otherServiceDescLeft}
                            </div>
                        </div>
                    )}
            </div>
        );
    };

    const serviceSelectSection = (service: any) => {
        return (
            <>
                <div className="service-select-section">
                    {osbServiceStep?.selectedServices?.find(
                        serviceItem =>
                            serviceItem?.serviceUniqueId ===
                            service?.serviceUniqueId
                    ) ? (
                        <Button
                            data-test-id={`remove-service`}
                            aria-label="remove-service-cta"
                            aria-labelledby="Remove"
                            button-name="Remove"
                            href=""
                            label={
                                getObjectFromAEMJson(
                                    SERVICE_FLOW.SERVICE_REMOVE_BUTTON_LABEL,
                                    props.serviceAemContent
                                ) || 'Remove'
                            }
                            noShadow
                            chevron="right"
                            outlined={true}
                            type="standard"
                            onClick={() =>
                                props.handleServiceSelection(false, service)
                            }
                        />
                    ) : (
                        <Button
                            data-test-id={`add-service`}
                            aria-label="add-service-cta"
                            aria-labelledby="Add"
                            button-name="Add"
                            href=""
                            label={
                                getObjectFromAEMJson(
                                    SERVICE_FLOW.SERVICE_ADD_BUTTON_LABEL,
                                    props.serviceAemContent
                                ) || 'Add'
                            }
                            noShadow
                            chevron="right"
                            type="standard"
                            onClick={() =>
                                props.handleServiceSelection(true, service)
                            }
                        />
                    )}
                </div>
            </>
        );
    };

    const constructingOsbServiceList = (
        servicesList: ServiceInfo[],
        accordionName: string
    ) => {
        const selectedServicesCount = osbServiceStep.selectedServices.filter(
            (service: ServiceData) => service.serviceType === accordionName
        ).length;
        // Get the disclaimer text from the AEM JSON
        const disclaimerText = getObjectFromAEMJson(
            SERVICE_FLOW.BREAK_FLUID_DISCLAIMER_TEXT,
            props.serviceAemContent
        );
        return (
            <div className="">
                <div
                    onClick={() => serviceAccordion(accordionName)}
                    className="accordion-button"
                >
                    <div
                        className={`services-title ${
                            !accordionPanelShow ? 'open' : ' close'
                        }`}
                    >
                        {props.accordionTitle}
                    </div>
                    {selectedServicesCount > 0 && (
                        <div
                            className="selected-services"
                            id="selectedServiceCount"
                        >
                            {selectedServicesCount}
                        </div>
                    )}
                </div>
                <div
                    id={accordionName}
                    className="new-service-section accordion-show accordion-border"
                >
                    {servicesList?.map((service: any) => (
                        <div
                            key={service.serviceId}
                            className={`service-detail-container service-${service.serviceId}`}
                        >
                            <div className="service-details">
                                <div className="service-name">
                                    {service.name}
                                    {service.description &&
                                    service.description !== '' &&
                                    !isDropOff(service) ? (
                                        <span
                                            id={
                                                service.serviceUniqueId +
                                                '-tooltip'
                                            }
                                        >
                                            <InfoIconToolTip
                                                dataTestId={`Service Info for ${service.name}`}
                                                osbServiceTooltip={true}
                                                tooltipInfoIconClass={'dark'}
                                                tooltipContent={
                                                    service.description
                                                }
                                                customDismissTooltipAriaLabel={
                                                    osbStep.osbDismissTooltipAriaLabel
                                                }
                                                customShowTooltipAriaLabel={
                                                    osbStep.osbShowTooltipAriaLabel
                                                }
                                                onClick={() =>
                                                    handleTooltipClick(
                                                        service.name,
                                                        osbVehicleStep
                                                            .vehicleDetails
                                                            .modelName
                                                    )
                                                }
                                            />
                                        </span>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {getServiceDescription(
                                    service,
                                    service.serviceId
                                )}
                                {getOfferDescription(service) &&
                                    getOfferDescription(service) !== 'null' &&
                                    getOfferDescription(service).trim() !==
                                        '' && (
                                        <div className="service-offer">
                                            {getOfferDescription(service)}
                                        </div>
                                    )}
                            </div>
                            {isMobileView && (
                                <div className="hr-line-style"></div>
                            )}
                            <div className="service-other-section">
                                {!props.hideServicePriceSection && (
                                    <div className="service-price-section">
                                        <div className="service-price">
                                            {Number(service.price) > 0 &&
                                            Number(service.priceAfterDiscount) >
                                                0 ? (
                                                priceDisplay(service)
                                            ) : (
                                                <>
                                                    {Number(service.price) >
                                                        0 &&
                                                    Number(
                                                        service.priceAfterDiscount
                                                    ) <= 0 ? (
                                                        <div>
                                                            <label>
                                                                {
                                                                    osbStep.freeLabel
                                                                }
                                                                <sup>
                                                                    {
                                                                        service
                                                                            .additionalInfo
                                                                            .priceDisclaimerSymbol
                                                                    }
                                                                </sup>
                                                            </label>
                                                            <div className="hasDiscount">
                                                                <span>
                                                                    {getFormattedPrice(
                                                                        service.price,
                                                                        osbStep
                                                                    )}
                                                                </span>
                                                                {service
                                                                    .additionalInfo
                                                                    .priceDisclaimerSymbol && (
                                                                    <sup>
                                                                        {
                                                                            service
                                                                                .additionalInfo
                                                                                .priceDisclaimerSymbol
                                                                        }
                                                                    </sup>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {Number(
                                                                service.price
                                                            ) ===
                                                            OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE
                                                                ? getFormattedPrice(
                                                                      service.price,
                                                                      osbStep
                                                                  )
                                                                : nonPriceDisplay(
                                                                      service
                                                                  )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {serviceSelectSection(service)}
                            </div>
                        </div>
                    ))}
                </div>
                {props?.showDisclaimer && disclaimerText && (
                    <div className="brake-fluid-disclaimer">
                        <p>{disclaimerText}</p>
                    </div>
                )}
            </div>
        );
    };

    const constructServiceCheckboxList = (service: any, key: number) => {
        const isServiceChecked: boolean =
            props.hasServiceSelected?.(service.serviceId) ||
            props.hasDealerFilterServiceSelected?.(service.name) ||
            service.isChecked;

        const isDropOffService: boolean = isDropOff(service);

        return (
            <div
                key={key}
                className={`osb-check-box-spacing ${
                    props.defaultChecked || isServiceChecked
                        ? ' selected-service'
                        : ''
                }`}
            >
                <div className="checkbox-content">
                    {!props.selectionControl && (
                        <input
                            id={service.serviceUniqueId + '-field'}
                            data-testid={`Checkbox Selection For ${service.name}`}
                            type="checkbox"
                            className={`checkbox ${props.className ?? ''}`}
                            placeholder={props.placeHolder}
                            name={service.serviceUniqueId}
                            value={service.serviceUniqueId}
                            onChange={(e: any) =>
                                props.handleServiceSelection(
                                    e.target.checked,
                                    service
                                )
                            }
                            aria-label={service.serviceUniqueId}
                            checked={isServiceChecked || false}
                        />
                    )}
                    {props.selectionControl === 'radio' && (
                        <div className="radio-style-wrapper">
                            <input
                                id={service.serviceUniqueId + '-field'}
                                data-testid={`Radio Selection For ${service.name}`}
                                type="radio"
                                name="delivery"
                                className="radio-style"
                                value={service.serviceUniqueId}
                                onChange={(e: any) =>
                                    props.handleServiceSelection(
                                        e.target.checked,
                                        service
                                    )
                                }
                                aria-label={service.serviceUniqueId}
                                checked={isServiceChecked || false}
                            />
                        </div>
                    )}
                    <div className="service-description">
                        <label
                            htmlFor={service.serviceUniqueId + '-field'}
                            className="checkbox-label"
                        >
                            {service.name}
                        </label>
                        {service.description &&
                        service.description !== '' &&
                        !isDropOffService ? (
                            <span id={service.serviceUniqueId + '-tooltip'}>
                                <InfoIconToolTip
                                    dataTestId={`Service Info for ${service.name}`}
                                    osbServiceTooltip={true}
                                    tooltipInfoIconClass={'dark'}
                                    tooltipContent={service.description}
                                    customDismissTooltipAriaLabel={
                                        osbStep.osbDismissTooltipAriaLabel
                                    }
                                    customShowTooltipAriaLabel={
                                        osbStep.osbShowTooltipAriaLabel
                                    }
                                    onClick={() =>
                                        handleTooltipClick(
                                            service.name,
                                            osbVehicleStep.vehicleDetails
                                                .modelName
                                        )
                                    }
                                />
                            </span>
                        ) : (
                            <div></div>
                        )}
                        {getOfferDescription(service) &&
                            getOfferDescription(service) !== 'null' &&
                            getOfferDescription(service).trim() !== '' && (
                                <div className="offer-label">
                                    {getOfferDescription(service)}
                                </div>
                            )}
                        {getServiceDescription(service, key)}
                    </div>
                </div>
                {!props.hideServicePriceSection && (
                    <div className="service-price-section">
                        {Number(service.price) > 0 &&
                        Number(service.priceAfterDiscount) > 0 ? (
                            priceDisplay(service)
                        ) : (
                            <>
                                {Number(service.price) > 0 &&
                                Number(service.priceAfterDiscount) <= 0 ? (
                                    <div>
                                        <label>
                                            {osbStep.freeLabel}
                                            <sup>
                                                {
                                                    service.additionalInfo
                                                        .priceDisclaimerSymbol
                                                }
                                            </sup>
                                        </label>
                                        <div className="hasDiscount">
                                            <span>
                                                {getFormattedPrice(
                                                    service.price,
                                                    osbStep
                                                )}
                                            </span>
                                            {service.additionalInfo
                                                .priceDisclaimerSymbol && (
                                                <sup>
                                                    {
                                                        service.additionalInfo
                                                            .priceDisclaimerSymbol
                                                    }
                                                </sup>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {Number(service.price) ===
                                        OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE
                                            ? getFormattedPrice(
                                                  service.price,
                                                  osbStep
                                              )
                                            : nonPriceDisplay(service)}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            {!props.newServiceStyle ? (
                <div className="osb-service-checkbox-wrapper">
                    {props.servicesList?.map((service: any, key: number) =>
                        constructServiceCheckboxList(service, key)
                    )}
                </div>
            ) : (
                <div
                    className="osb-new-service-listing"
                    data-testid="service-item"
                >
                    {constructingOsbServiceList(
                        props.servicesList ? props.servicesList : [],
                        props.accordionName ? props.accordionName : ''
                    )}
                </div>
            )}
        </>
    );
};
