import { useEffect, useContext, useState } from 'react';
import ServerContext from '../../../contexts/serverContext';
import useUserProfile from '../../../hooks/use-user-profile';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import ProfileService from '../../../services/profile-service/profile-service';
import serverSideService from '../../../services/server-side-service/server-side-service';

export const Ecommerce = () => {
    const { currentRoot } = new AppConfigurationService();
    const { currentRegionCode, currentLanguageRegionCode } = useContext(
        ServerContext
    );
    const profile = useUserProfile();
    const profileService = new ProfileService();
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const resultUrl =
        currentRoot + 'service-maintenance/tire-finder/search-results.html';
    const [initData, setInitData] = useState({
        language: currentLanguageRegionCode.substring(0, 2),
        country: currentRegionCode,
        dealerCode: '',
        brand: 'ford',
        site: 'owner',
        resultsPage: resultUrl,
        garagevins: [''],
        preselected: { year: '', make: '', model: '', doSearch: false },
    });
    if (serverSideService.isClientSide()) {
        const baseUrl =
            currentRoot + 'service-maintenance/tire-finder/ecommerce.html';
        const base = document.querySelector('base');
        base?.setAttribute('href', baseUrl);
    }

    const { brand } = new AppConfigurationService();

    useEffect(() => {
        if (profile && profile.vehicles.length > 0) {
            const vehicles = [...profile.vehicles]
                .sort(
                    (vehicle1, vehicle2) =>
                        parseInt(vehicle2.modelYear, 10) -
                        parseInt(vehicle1.modelYear, 10)
                )
                .map(vehicle => {
                    return {
                        year: parseInt(vehicle.modelYear, 10),
                        make: vehicle.make,
                        model: vehicle.modelName,
                        vin: vehicle.vin,
                        preferredDealer: vehicle.preferredDealer,
                    };
                });
            setVehiclesData(vehicles);
        }
    }, [profile]);

    useEffect(() => {
        if (vehiclesData.length > 0) {
            let matchedVehicle = vehiclesData[0];

            const selectedVin = profileService.getUserPreferences(
                profile?.profile.email
            )?.lastSelectedVin;

            if (selectedVin) {
                matchedVehicle =
                    vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                    vehiclesData[0];
                !matchedVehicle && (matchedVehicle = vehiclesData[0]);
            }

            const garagevins: string[] = [];
            vehiclesData.forEach(vehicle => {
                vehicle.vin && garagevins.push(vehicle.vin);
            });

            setInitData({
                ...initData,
                dealerCode: matchedVehicle.preferredDealer || '',
                garagevins: garagevins,
                preselected: {
                    year: matchedVehicle.year.toString(),
                    make: matchedVehicle.make,
                    model: matchedVehicle.model,
                    doSearch: true,
                },
            });
        }
    }, [vehiclesData]);

    useEffect(() => {
        if (
            vehiclesData?.length > 0 &&
            initData &&
            initData?.garagevins.length > 1
        ) {
            if (brand === 'lincoln') {
                setInitData({ ...initData, brand: 'lincoln' });
            }
            const script = document.createElement('script');

            let env = 'wwwqa';
            if (
                process.env.REACT_APP_ACTIVE_PROFILE &&
                process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
            ) {
                env = 'www';
            }

            script.setAttribute(
                'src',
                `https://${env}.amitirefinder.com/js/dist/tirefinder2.js`
            );

            script.setAttribute('data-ami-tirefinder', 'search');

            const existingAmiTag = document.querySelector('ami-tirefinder');
            if (existingAmiTag) {
                existingAmiTag.setAttribute(
                    'data-init',
                    JSON.stringify(initData)
                );
            } else {
                const tirefinderContent = document.querySelector(
                    '.fls-content'
                ) as HTMLDivElement;
                const amiTag = document.createElement('ami-tirefinder');
                amiTag.setAttribute('data-init', JSON.stringify(initData));
                tirefinderContent.appendChild(amiTag);
            }

            document.body.appendChild(script);
        }
    }, [vehiclesData, initData]);

    return (
        <div className="js-tire-finder">
            <div className="fls-content clearer"></div>
        </div>
    );
};
